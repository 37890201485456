import Network from 'infrastructure/js/modules/network';

export function getGroupsDataByStationId(stationID, query = {}, abortController = null, isAll = false){
  if(isAll)  return Network.post(`stations/${stationID}/groups/items`, query, { signal: abortController?.signal });
  return Network.post(`stations/${stationID}/groups/items?active=true`, query, { signal: abortController?.signal });
}

export function generateGroupId() {
  return Network.get('groups/generate-asset-id');
}

export function createGroup(data) {
  return Network.post('groups', data);
}

export function addToGroup(data) {
  return Network.post('groups/assets-assignment', data);
}

export function validateAssignment(data) {
  return Network.post('groups/validate-assignment', data);
}

export function getGroupDetails(groupId){
  return Network.get(`groups/${groupId}`);
}

export function getGroupActivityLog(groupId, query) {
  return Network.post(`groups/${groupId}/activities`, query);
}

export function fetchArchivedGroups(query = {}) {
  return Network.post('groups/items?active=false', query);
}

export function editGroupAttributes(data, callback){
  return Network.polling.put('groups/update', data, callback);
}

export function removeAssetFromGroup(data) {
  return Network.post(`groups/${data.parentAssetId}/assets-unassignment`,{assetIds: [data.relatedAssetId]});
}

export function updateGroup(groupId, data) {
  return Network.put(`groups/${groupId}`, data);
}

export function fetchActiveGroups(query = {}) {
  return Network.post('groups/items?active=true', query);
}

export function ungroup(groupId) {
  return Network.post(`groups/${groupId}/ungroupAll`);
}

export function createGroupType(data) {
  return Network.post('groupTypes', data);
}

export function editGroupType(data) {
  return Network.post('groupTypes/update', {groupTypes: [data]});
}

export function getGroupTypes() {
  return Network.get('groupTypes', );
}

export function fetchGroupTypes(query = {}) {
  return Network.post('groupType/items', query);
}

export function removeGroupTypes(ids) {
  return Network.delete('groupTypes', {groupTypeIds: ids.ids});
}


