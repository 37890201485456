import React from 'react';
import PropTypes from 'prop-types';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import CheckboxCell from './CheckboxCell/checkboxCell';
import CheckboxHeader from './CheckboxHeader/checkboxHeader';
import DefaultHeader from './DefaultHeader/defaultHeader.js';
import { List, Map } from 'immutable';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';

ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, SetFilterModule, MenuModule, RowGroupingModule, ColumnsToolPanelModule]);

import ErrorBoundary from '../../../../mat/js/components/ErrorBoundary/errorBoundary';
import 'node_modules/@ag-grid-community/styles/ag-grid.css';
import 'node_modules/@ag-grid-community/styles/ag-theme-alpine.css';

import EditableCell from './EditableCell/editableCell';
import EditableMultiCell from './EditableMultiCell/editableMultiCell';

//Set ag-grid enterprise license.
import { LicenseManager } from '@ag-grid-enterprise/core';
import NumericEditableMultiCell from './NumericEditableMultiCell/numericEditableMultiCell';
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-051749}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{PLATAINE_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TPO}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TPO}_need_to_be_licensed___{TPO}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_January_2025}____[v3]_[01]_MTczODI4MTYwMDAwMA==57c81283a417fb4246b4a32adf1d0e7c'
);

require('./grid.scss');

export default class PL_Grid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.areAllRowsSelected = false;
    this.labels = createLabelHelper('grid.');
    this.storageKey = props.gridName + (props.gridNameSuffix ? '_' + props.gridNameSuffix : '');

    this.sideBar = {
      toolPanels: [
          {
            id: 'columns',
            labelDefault: this.labels.get('sideBar.columns.button'),
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
      };

    this.gridOptions = {
      headerHeight: props.headerHeight ?? 46,
      getRowHeight: props.getRowHeight,
      getRowClass: props.getRowClass,
      rowSelection: 'multiple',
      domLayout: props.domLayout || 'normal',
      suppressRowClickSelection: true,
      singleClickEdit: true,
      onSelectionChanged: this.onSelectionChanged,
      suppressMenuHide: false,
      suppressContextMenu: true,
      suppressDragLeaveHidesColumns: true,
      isRowMaster: (rowData) => {
        return rowData.isRowMaster;
      },
      components: {
        masterDetailRowComponent: props.masterDetailRowComponent,
        editableCell: EditableCell,
        editableMultiCell: EditableMultiCell,
        numericEditableMultiCell: NumericEditableMultiCell,
      },
      detailCellRenderer: 'masterDetailRowComponent',
      // rowModelType: 'normal',

      isRowSelectable: (rowData) => {
        return !rowData?.data?.isRowDisabled;
      },
      defaultColDef: {
        headerComponent: DefaultHeader,
        headerComponentParams: {},
      },
      suppressMultiSort: true,
      onRowDataUpdated: this.onRowDataChanged,
      treeData: props.treeData,
      getDataPath: props.getDataPath,
      animateRows: props.animateRows,
      suppressClickEdit: props.suppressClickEdit,
      detailCellRendererParams: props.detailCellRendererParams,
      keepDetailRows: props.keepDetailRows,
      getRowId: props.getRowId,
      groupDisplayType: props.groupDisplayType,
      groupSelectsChildren: props.groupSelectsChildren,
      suppressAggFuncInHeader: true,
      resetRowDataOnUpdate: props.resetRowDataOnUpdate,
    }

    this.upgradeColumnsStateInStorage();
  }

  componentWillUnmount() {
    if (this.props.unmount) {
      this.props.unmount(this.props.gridName);
    }
  }

  // in onGridReady, store the api for later use
  onGridReady = (params) => {
    this.api = params.api;
    this.columnApi = params.columnApi;

    if (this.props.shouldPreSelectRows) {
      this.api.forEachNode(function (node) {
        if (node?.data?.selected) {
          node.setSelected(true);
        }
      });
    }

    this.props.setRef?.(this);
    this.props.onGridReadyCallback?.(params);
  }

  //NOTE: if some new columns were added to the grid (new version) - remove the grid's column settings from the storage.
  upgradeColumnsStateInStorage = () => {
    let columnsState = this.loadColumnsStateFromStorage();
    if (columnsState) {
      let columnsMetadata = [];

      if (this.props.draggableColumn) {
        columnsMetadata.push(this.createColumnMetadata(this.props.draggableColumn(), []));
      }
      if (this.props.checkboxSelection) {
        this.addCheckboxColumn(columnsMetadata);
      }

      // add metadata for predeclared columns
      this.props.columnsConfig.map((columnData) => {
        columnsMetadata.push(this.createColumnMetadata(columnData, []));
      });

      let storageColumns = columnsState.map(item => item.colId);
      let configColumns = columnsMetadata.map(item => item.field);

      if (JSON.stringify(storageColumns.sort()) !== JSON.stringify(configColumns.sort())) {
        GridHelper.removeColumnsStateFromStorage(this.storageKey);
      }
    }
  }

  loadColumnsStateFromStorage = () => {
    return GridHelper.getColumnsStateFromStorage(this.storageKey);
  }

  storeColumnsStateToStorage = () => {
    const columnsState = this.api.getColumnState();
    const partialState = columnsState.map((state) => ({
      colId: state.colId,
      flex: state.flex,
      width: state.flex ? null : state.width,
      sort: state.hide ? null : state.sort,
      pinned: state.pinned,
      hide: state.hide,
    }));
    GridHelper.setColumnsStateToStorage(this.storageKey, partialState);
  }

  componentDidUpdate(previousProps) {
    // Clear all rows selection after selectedRowsData returns with zero value. fixed the bug that grid didn't update the selection if grid's data wasn't changed.
    if (previousProps.selectedRowsData.size > 0 && this.props.selectedRowsData.size === 0 && this.api) {
      this.api.deselectAll();
    }

    if (previousProps.loading !== this.props.loading && this.api) {
      this.props.loading ? this.api?.showLoadingOverlay?.() : this.api?.hideOverlay?.();

      //Note: set NoRowsOverlay on the first displaying (when no data was fetched)
      if (!this.props.loading && !this.props.rows?.size  && !this.props.error) { //Note:
        setTimeout(() => {
          this.api?.showNoRowsOverlay?.()
        });
      }
    }
  }

  refresh = () => {
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState });
  };

  //check filterPersistence per column - currently not in use
  isFilterPersistent = (data) => {
    let filter =  this.props.columnsConfig?.find(item => item.filterName === data?.[0]?.filterName );
    return filter?.filterPersistence;
  }

  onFilterChanged = (data) => {
    let updatedFilters = this.getUpdatedFilters(this.props.filterState, data);

    // if (this.isFilterPersistent(data)) {
    if (this.props.filterPersistence) {
      GridHelper.setFiltersStateToStorage(this.storageKey, updatedFilters?.toJS());
    }

    this.getRowsData({ filterModel: updatedFilters, sortModel: this.props.sortState }).then(
      (response) => {
        this.api.onFilterChanged?.();  //Note: update the grid's filters state (handle the case when no data was fetched)
      }
    );
  };

  onSortChanged = (event) => {
    let sortModel = this.api.getColumnState().find((e) => e.sortIndex !== null);
    let colId = sortModel.colId;
    let filterName = this.props.filterConfig.find((type) => {
      return type.fieldName === colId;
    }).filterName;
    this.getRowsData({
      filterModel: this.props.filterState,
      sortModel: { filterName, direction: sortModel.sort },
    });

    this.columnsMetaData?.forEach((item) => {
        item.sort = (item.field === colId) ? sortModel.sort : null;
    })

    this.storeColumnsStateToStorage();

    this.columnsMetaData = this.updateColumnsMetadata(this.columnsMetaData)
  };

  onColumnResized = (event) => {
    if (event.source === 'uiColumnResized' && event.finished) {
      this.storeColumnsStateToStorage();
    }
  }

  onColumnMoved = (params) => {
    if ( (params.source === 'uiColumnMoved' || params.source === 'toolPanelUi') && params.finished) {
      this.storeColumnsStateToStorage();
    }
  }

  onColumnVisible = (event) => {
    if ( event.source === 'toolPanelUi') {
      this.storeColumnsStateToStorage();
    }
  }

  getRowsData = (params, pageNumber = 0) => {
    let query = GridHelper.createGetDataQuery(params, this.props.filterConfig, this.props.pageSize, pageNumber);
    return this.props.fetchData(this.props.gridName, query, true);
  };

  getUpdatedFilters = (filters, changedFilters) => {
    if (!filters) {
      return List();
    }
    if (!changedFilters || changedFilters.length === 0) {
      return filters;
    }

    changedFilters.map((changedFilter) => {
      if (changedFilter.filterName) {
        let changedFilterIndex = filters.findIndex((filter) => {
          return filter.filterName === changedFilter.filterName;
        });
        if (changedFilterIndex !== -1) {
          filters = filters.update(changedFilterIndex, (filter) => {
            filter.values = changedFilter.values;
            if (changedFilter.extraData) {
              filter.extraData = changedFilter.extraData;
            }
            return filter;
          });
        }
      }
    });

    return filters;
  };

  initColumnsMetaData() {
    if (this.props.columnsConfig.length === 0 || !this.props.filtersData) {
      return;
    }

    let newFiltersString = JSON.stringify(this.props.filtersData);
    if (newFiltersString === this.filtersString) {
      return;
    }
    this.filtersString = newFiltersString;

    let columnsMetadata = [];

    if (this.props.draggableColumn) {
      columnsMetadata.push(this.createColumnMetadata(this.props.draggableColumn(), this.props.filtersData));
    }
    if (this.props.checkboxSelection) {
      this.addCheckboxColumn(columnsMetadata);
    }

    // add metadata for predeclared columns
    this.props.columnsConfig.map((columnData) => {
      columnsMetadata.push(this.createColumnMetadata(columnData, this.props.filtersData));
    });

    columnsMetadata = this.updateColumnsMetadata(columnsMetadata);

    this.columnsMetaData = columnsMetadata;
    this.autoGroupColumnMetaData = this.props.autoGroupColumnDef && this.createColumnMetadata(this.props.autoGroupColumnDef, this.props.filtersData);
  }

  updateColumnsMetadata = (columnsMetadata) => {

    let columnsState = this.loadColumnsStateFromStorage();
    if (!columnsState) {
      return columnsMetadata;
    }

    let hasStoredSorting = columnsState.find((column) => {return column.sort});

    let res = [];
    columnsState.forEach((col) => {
      let curColumn = columnsMetadata.find((column) => {
        return col.colId === column.field
      });
      if (curColumn) {
        curColumn.hide = col.hide;
        curColumn.flex = col.flex;
        curColumn.width = col.width;

        if (curColumn.sortable && hasStoredSorting) {
          curColumn.sort = col.sort;
        }
        curColumn.pinned = col.pinned;
        if (curColumn.pinned) {
          curColumn.lockPosition = col.pinned;
        }
        res.push(curColumn);
      }
    })
    return res;
  }

  addCheckboxColumn = (columnsMetadata) => {
    let checkboxColumnData = {
      fieldName: '-1',
      title: '',
      width: this.props?.checkboxColumnWidth ?? 60,

      columnOptions: {
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        resizable: false,
        sortable: false,
        pinned: 'left',
        lockPosition: 'left',
        filter: false,
        checkboxSelection: true,
        cellRenderer: CheckboxCell,
        headerComponent: CheckboxHeader,
        headerComponentParams: {
          gridName: this.props.gridName,
          getValue: () => {
            return this.areAllRowsSelected;
          },
          onChecked: () => this.api.selectAll(),
          onUnchecked: () => this.api.deselectAll(),
        },
        cellClass: 'checkbox-style-wrapper',
      },
    };
    columnsMetadata.push(this.createColumnMetadata(checkboxColumnData));
  };

  cancelAgGridClientSideSorting() {
    return 0;
  }

  //TODO - move all logic to a single method
  // create metadata for single column
  createColumnMetadata(columnData, filtersData) {
    let { width, minWidth, filterOptions, filterAlignment, /*filterPersistence,*/ filterWidth, cellComponent,  ...options } = GridHelper.createColumnMetaData(columnData, filtersData);
    if (!filterOptions) {
      // filterOptions = {suppressFilter: true};
      filterOptions = {};
    }

    if (filterOptions.filterParams) {
      filterOptions.filterParams.onFilterChanged = this.onFilterChanged;
    }

    let columnMetadata = {
      suppressSizeToFit: !!width,
      resizable: true,
      sortable: true,
      comparator: this.cancelAgGridClientSideSorting, //we have Server side sorting
      ...(width == null ? {flex: 1} : { width }),
      ...(minWidth == null ? { minWidth: width && width < 100 ? width : 100 } : { minWidth, flex: 1 }),
      ...filterOptions,
      ...options,
      toolPanelClass: params => {
        return 'ag-theme-alpine';
      },
    };

    if (columnData.columnOptions && columnData.columnOptions.cellComponent) {
      columnMetadata.cellRenderer = columnData.columnOptions.cellComponent;
    }
    return columnMetadata;
  }

  onRowDataChanged = (event) => {
    this.areAllRowsSelected = false;
    if (this.api) {
      this.api.refreshHeader();
      this.props?.onRowDataChanged?.(this.api);
    }
  }

  onSelectionChanged = (event) => {
    if (!this.props.onSelectedRowsChanged) {
      return;
    }

    event.api.refreshCells({
      force: true
    });

    let selectedRows = this.api.getSelectedRows();
    this.updateCheckboxHeader(selectedRows);
    this.props.onSelectedRowsChanged(this.props.gridName, selectedRows);
  }

  updateCheckboxHeader = (selectedRows) => {
    if (selectedRows.length === 0) {
      this.areAllRowsSelected = false;
    } else {
      // update 'areAllRowsSelected' for Selection column header re-render
      this.areAllRowsSelected = true;
      this.api.forEachNode((node) => {
        if (!node.isSelected() && !node?.data?.isRowDisabled) {
          this.areAllRowsSelected = false;
        }
      });
    }

    this.api.refreshHeader();
  };

  getGrid() {
    if (this.props.columnsConfig.length === 0) {
      return null;
    }

    this.initColumnsMetaData();

    return (
      <AgGridReact
        onFirstDataRendered={this.props.onFirstDataRendered}
        gridOptions={this.gridOptions}
        masterDetail={this.props.isMasterDetail}
        isRowMaster={this.gridOptions.isRowMaster}
        animateRows={this.props.isMasterDetail}
        rowData={this.props.rows}
        columnDefs={this.columnsMetaData}
        context={{ componentParent: this }}
        onGridReady={this.onGridReady}
        onColumnResized={this.onColumnResized}
        onColumnMoved={this.onColumnMoved}
        onSortChanged={this.onSortChanged}
        components={this.props.components}
        autoGroupColumnDef={this.autoGroupColumnMetaData}
        sideBar={this.props.sideBar ? this.sideBar : null}
        onColumnVisible={this.onColumnVisible}
        loadingOverlayComponent={this.getLoadingOverlay}
        noRowsOverlayComponent={this.getNoRowsOverlay}
        onRowGroupOpened={this.props.onRowGroupOpened}
        detailRowAutoHeight={this.props.detailRowAutoHeight}

      />
    );
  }

  getLoadingOverlay() {
    return <Overlay.Loading />;
  }

  getNoRowsOverlay = () => {
    return <Overlay.Label text={this.labels.get('norows')} />
  }

  getErrorOverlay() {
    if (!this.props.error) {
      return null;
    }
    return <Overlay.Error text={this.labels.get('error')} buttonLabel={this.labels.get('error.button')} onClickCallback={this.refresh} />;
  }

  onPreviousPageClick = () => {
    let info = this.props.queryResultInfo;
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState }, info.number - 1);
  };

  onNextPageClick = () => {
    let info = this.props.queryResultInfo;
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState }, info.number + 1);
  };

  getPaginationPanel = () => {
    if (!this.props.queryResultInfo) {
      return null;
    }

    let info = this.props.queryResultInfo;
    let firstRow = 0;
    let lastRow = 0;
    if (info.numberOfElements > 0) {
      firstRow = info.size * info.number + 1;
      lastRow = firstRow + info.numberOfElements - 1;
    }
    return (
      <div className="pagination-panel">
        <Button id="gridPreviousPageBtn" className="border-btn" icon="pl pl-arrow-left" onClick={this.onPreviousPageClick} disabled={info.first} />
        <span>
          {this.labels.get('pageinfo', undefined, {
            first: firstRow,
            last: lastRow,
            total: info.totalElements,
          })}
        </span>
        <Button id="gridNextPageBtn" className="border-btn" icon="pl pl-arrow-right" onClick={this.onNextPageClick} disabled={info.last} />
      </div>
    );
  };

  render() {
    return (
      <ErrorBoundary>
        <div className="pl-grid">
          {this.getErrorOverlay()}
          {this.getGrid()}
          {this.getPaginationPanel()}
        </div>
      </ErrorBoundary>
    );
  }
}

PL_Grid.propTypes = {
  gridName: PropTypes.string.isRequired,
  gridNameSuffix: PropTypes.string,
  fetchData: PropTypes.func,
  columnsConfig: PropTypes.array.isRequired,
  columns: PropTypes.array,
  getRowHeight: PropTypes.func,
  pageSize: PropTypes.number,
  onSelectedRowsChanged: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  draggableColumn: PropTypes.func,
  rows: PropTypes.object,
  filterConfig: PropTypes.array,
  filterState: PropTypes.object,
  sortState: PropTypes.object,
  loading: PropTypes.bool,
  enableColResize: PropTypes.bool,
  shouldPreSelectRows: PropTypes.bool,
  onRowDataChanged: PropTypes.func,
  setRef: PropTypes.func, //(grid) => void; sets a reference to the grid object so you can call grid.api
  sideBar: PropTypes.bool,
  onGridReadyCallback: PropTypes.func,
  filterPersistence:  PropTypes.bool,
};

PL_Grid.defaultProps = {
  getRowHeight: () => {
    return 64;
  },
  pageSize: 100,
  checkboxSelection: true,
  draggableColumn: null,
  rows: null,
  filterConfig: [],
  filterState: List(),
  sortState: {},
  enableColResize: false,
  sideBar: true,
  gridNameSuffix: '',
  filterPersistence: false,
};
