import { Map } from 'immutable';

//Default form reducer - for forms with Server side error/warning validation logic
//prefix - is a base form name (for example 'CREATE_GROUP_FORM')
export default function (state, action, prefix, defaultState) {
  switch (action.type) {
    case prefix + '_CLEAR_FORM_DATA':
        return Map(defaultState);

    case prefix + '_SET_BUSY':
      return state.set('loading', action.payload);

    case prefix + '_FETCH_FORM_DATA_READY':
      return state.set('formData', action.payload);

    case prefix + '_SET_VALIDATION_WARNINGS_DATA':
      return state
        .set('validationMessage', action.payload)
        .set('showIgnoreValidationCheckbox', action.payload.canIgnoreValidation)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('hasError', !action.payload.canIgnoreValidation);

    case prefix + '_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX':
      return state
        .set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case prefix + '_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX':
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }
}
