import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import CreateBagDialog from './CreateBagDialog/createBagDialog';
import BagsViewGrid from './BagsViewGrid/bagsViewGrid';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import { MatSettingsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import { gridsNames } from '../../../../enums/gridsNames.js';
import { ImportAcceptFileTypes, ImportTypes } from '../../../../enums/importTypes';

export default class BagsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.bags.view.');
  }

  componentDidMount() {
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_BAGS);
  }

  getHeaderItems() {
    let { actions } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateBagClick,
        },
        {
          id: 'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditBagClick,
          actionData: selectedRowsData.get(0),
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'importBags',
            label: headerItemsLabels.IMPORT_BAGS,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_BAGS],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id: 'exportBags',
            label: headerItemsLabels.EXPORT_BAGS,
            action: actions.header.exportItems,
            disabled: !hasRows,
            actionData: { gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_BAGS }
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveBagsClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData:  selectedRowsData,
          },
        ]
      }
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems} />

        <BagsViewGrid actions={{ ...this.props.actions.bagsViewGridActions, ...this.props.actions.fetchEntitiesActions }} />

        <PL_DialogWrapper
          dialogComponent={CreateBagDialog}
          show={this.props.sCreateBagDialogData.get('show')}
          actions={this.props.actions.createBagDialogActions}
          sData={this.props.sCreateBagDialogData}
          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}
