import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions.js';
import { api as overviewTabApi } from './overviewTabActions';
import { api as attachmentGridApi} from '../AssetPage/attachmentTabGridActions';
import * as WorkOrderHelper from '../../components/WorkOrderPage/utils/workOrderHelper';
import {navigationStates} from '../../enums/navigationStates';
import { api as gridApi} from './activityLogTabGridActions';
import {DefaultWorkOrderTypes} from '../../utils/workOrderHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {enumTypes, getEnumLabel} from '../../utils/enumHelper';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  WORKORDERPAGE_FETCHDATA_FINISHED: 'WORKORDERPAGE_FETCHDATA_FINISHED',
  WORKORDERPAGE_CLEAR_DATA: 'WORKORDERPAGE_CLEAR_DATA',
  WORKORDERPAGE_UNMOUNT: 'WORKORDERPAGE_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataFinished: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_FETCHDATA_FINISHED, payload: payload};
  },
  clearData: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_CLEAR_DATA, payload: payload};
  },
  unmount: function(items) {
    return {type: actionTypes.WORKORDERPAGE_UNMOUNT};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid, workOrderId) {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.WORKORDER);
    api.reload(dispatch, getState)(shouldRefreshGrid, workOrderId);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.reload = function(dispatch, getState) {
  return function(shouldRefreshGrid, workOrderId) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
    else {
      dispatch(actions.clearData());
    }
    let isNewAsset = !shouldRefreshGrid;
    api.fetchData(dispatch, getState)(workOrderId, isNewAsset);
  }

};

api.fetchData = function(dispatch, getState) {
  return function(workOrderId, isNewAsset=false) {
    navigatorApi.setLoading(isNewAsset)(dispatch, getState);

    Promise.all([
      overviewTabApi.init(dispatch, getState)(workOrderId),
      attachmentGridApi.reload(dispatch, getState)()
    ]).then((allResults) => {
      let workOrder = allResults[0];
      let topNavigatorData = _createNavigatorData(workOrder);
      navigatorApi.setData(dispatch, getState)(topNavigatorData);

      dispatch(actions.fetchDataFinished(workOrder));
      navigatorApi.setLoading(false)(dispatch, getState);
    });
  }
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _createNavigatorData(wo) {
  let subname = _getSubName(wo);

  let topNavigatorData = {
    name: createLabelHelper('mat.entity.type.').get('workorder') + ' ' + wo.businessId,
    subname: subname,
    status: _getStatus(wo),
    type: navigationStates.WORKORDER,
    id: wo.id,
    components: []
  };

  return topNavigatorData;
}

function _getStatus(wo) {
  let status = WorkOrderHelper.isWorkOrderArchived(wo.workOrderStatus) ? wo.workOrderStatus : '';
  return status ? getEnumLabel(enumTypes.WORK_ORDER_STATUS)(status) : '';
}

function _getSubName(wo) {
  let labels = createLabelHelper('mat.workorderpage.topbar.');
  switch(wo.workOrderType) {
    case DefaultWorkOrderTypes.PARTTYPE_ORIENTED:
      return `${labels.get('parttype.title')} ${wo.partType ?wo.partType.businessId : ''}`;
    case DefaultWorkOrderTypes.KITTYPE_ORIENTED:
      return `${labels.get('kittype.title')} ${wo.kitType ? wo.kitType.businessId : ''}`;
    default:
      console.error('error in getCreationForTypeLabel: invalid type: ' + wo.workOrderType);
      return '';
  }
}


