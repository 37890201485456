import React from 'react';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Button from 'infrastructure/js/components/controls/Button/button';
import { FieldArray } from 'redux-form';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as messageDialogActions } from '../../../../actions/MessageDialog/messageDialogActions';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ArrowDown from 'infrastructure/assets/svg/arrow-down_2.svg';
import ArrowUp from 'infrastructure/assets/svg/arrow-up_2.svg';
import DragHandleIcon from 'infrastructure/assets/svg/drag-handle.svg';

require('./addRemoveListExt.scss');

const SortableContainer = sortableContainer(({ children }) => children);

const SortableItem = sortableElement(({ children }) => children);

const DragHandle = sortableHandle(() => (
  <div className="drag-handle">
    <DragHandleIcon className="drag-handle-icon" />
  </div>
));

export class AddRemoveListExt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.isViewOnly = this.props.isViewOnly || false;
    this.state = { optionsInUse: this.props.preSelectedItemsIds, isOverflow: false };

    this.listRef = React.createRef();
    this.listItemsContainerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.onListLengthChangedCallback) {
      this.props.onListLengthChangedCallback(this.state.optionsInUse.length);
    }
  }

  componentWillUnmount() {
    if (this.fields && !this.props.keepDataOnUnmount) {
      this.fields.removeAll();
    }
  }

  ignoreAll = (event) => {
    event.preventDefault();
  };

  getOptionsInUse = (itemIndex) => {
    let idsInUse = [...this.state.optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  getAvailableOptions = (itemIndex) => {
    let allOptions = this.props.itemRendererOptions;
    let optionsInUse = this.getOptionsInUse(itemIndex);

    return allOptions.filter((option) => {
      return optionsInUse.indexOf(option.value) < 0;
    });
  };

  updateState = (idsInUse) => {
    this.setState({ optionsInUse: idsInUse }, () => {
      if (this.props.onListLengthChangedCallback) {
        this.props.onListLengthChangedCallback(this.state.optionsInUse.length);
      }
    });
  };

  getMessageForChangeItem = (value, oldValue, index) => {
    let title = this.dialogLabels.get('warning');
    let type = 'warning';
    let children = [<span>{this.props.warningMessageOnChange}</span>];
    let buttons = [
      {
        id: 'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.props.actions.messageDialogActions.onHide,
        bsStyle: 'default',
      },
      {
        id: 'change',
        text: this.dialogLabels.get('remove'),
        action: () => {
          this.props.actions.messageDialogActions.onHide();
          this.handleOnChange(value, oldValue, index);
        },
        bsStyle: 'primary',
      },
    ];
    let messageDialogDescriptor = { title, buttons, children, className: '', type };
    return messageDialogDescriptor;
  };

  onChangeCallback = (value, oldValue, index) => {
    if (this.props.showWarningOnRemoveOrChange && oldValue !== null) {
      this.props.actions.messageDialogActions.open(this.getMessageForChangeItem(value, oldValue, index));
      return;
    }

    this.handleOnChange(oldValue, index, value);
  };

  handleOnChange(oldValue, index, value) {
    let idsInUse = [...this.state.optionsInUse];
    if (oldValue) {
      //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) {
      //no value when clear the selection
      idsInUse[index] = value.value;
    }

    this.updateState(idsInUse);
  }

  removeButtonClickHandler = (index, fields) => {
    if (this.props.showWarningOnRemoveOrChange) {
      this.props.actions.messageDialogActions.open(this.getMessageForRemoveItem(index, fields));
      return;
    }

    this.handleRemoveItem(fields, index);
  };

  removeAllButtonClickHandler = (index, fields) => {
    if (this.fields) {
      this.fields.removeAll();
    }
  };

  getMessageForRemoveItem = (index, fields) => {
    let title = this.dialogLabels.get('warning');
    let type = 'warning';
    let children = [<span>{this.props.warningMessageOnRemove}</span>];
    let buttons = [
      {
        id: 'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.props.actions.messageDialogActions.onHide,
        bsStyle: 'default',
      },
      {
        id: 'remove',
        text: this.dialogLabels.get('remove'),
        action: () => {
          this.props.actions.messageDialogActions.onHide();
          this.handleRemoveItem(fields, index);
        },
        bsStyle: 'primary',
      },
    ];
    let messageDialogDescriptor = { title, buttons, children, className: '', type };
    return messageDialogDescriptor;
  };

  handleRemoveItem(fields, index) {
    fields.remove(index);
    let idsInUse = [...this.state.optionsInUse];
    if (index > -1) {
      idsInUse.splice(index, 1);
    }
    this.updateState(idsInUse);

    if (this.props.onRemoveCallback) {
      this.props.onRemoveCallback();
    }
  }

  scrollToLastItem = () => {
    let lastItem = this.listRef?.current?.querySelector?.('.list-item:last-child');
    lastItem?.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
  };

  addButtonClickHandler = (fields) => {
    const { parseNewAddedItem } = this.props;
    let defaultItem = this.props.defaultItem !== undefined ? this.props.defaultItem : {};

    const parsedDefaultItem = parseNewAddedItem(defaultItem);

    fields.push(parsedDefaultItem);

    let idsInUse = [...this.state.optionsInUse];
    idsInUse.push(null); //hold index in optionsInUse for the new added item
    this.updateState(idsInUse);

    if (this.props.onAddCallback) {
      this.props.onAddCallback();
    }

    setTimeout(() => {
      this.scrollToLastItem();
    }, 0);
  };

  addAtButtonClickHandler = (index, fields) => {
    const { parseNewAddedItem } = this.props;
    let defaultItem = this.props.defaultItem !== undefined ? this.props.defaultItem : {};

    const parsedDefaultItem = parseNewAddedItem(defaultItem);

    fields.splice(index, 0, parsedDefaultItem);

    let idsInUse = [...this.state.optionsInUse];
    idsInUse.splice(index, 0, null); //hold index in optionsInUse for the new added item
    this.updateState(idsInUse);

    this.props.onAddAtCallback?.(index);
  };

  //-------- Renderers -------------------
  renderAddButton = (fields) => {
    if (this.isViewOnly || this.props.hideAddButton) {
      return null;
    }

    let disabled = this.props.addButtonDisabled || (this.props.maxItemsToRender && fields.length >= this.props.maxItemsToRender);
    return (
      <Button
        id="add-row-btn"
        disabled={disabled}
        className="add-row"
        onClick={() => {
          this.addButtonClickHandler(fields);
        }}
        onKeyPress={this.ignoreAll}
      >
        <span className="pl pl-icon-add" /> {this.props.addButtonLabel}
      </Button>
    );
  };

  renderAddAtButton = (index, fields) => {
    if (this.isViewOnly || this.props.hideAddAtButton) {
      return null;
    }
    let disabled = this.props.maxItemsToRender && fields.length >= this.props.maxItemsToRender;
    if (fields.get(index) && fields.get(index).disableRemove) {
      return null;
    } else {
      return (
        <Button
          id="add-below-bt"
          className="pl pl-icon-add-below item-btn add-below-btn"
          onClick={() => {
            this.addAtButtonClickHandler(index, fields);
          }}
          onKeyPress={this.ignoreAll}
          disabled={disabled}
        />
      );
    }
  };

  renderMoveButtons = (index, fields) => {
    if (this.isViewOnly || !this.props.showMoveButtons) {
      return null;
    }

    return (
      <div className="move-buttons-wrapper">
        <Button
          id="move-up-btn"
          className="pl item-btn move-btn"
          onClick={() => {
            fields.move(index, index - 1);
          }}
          onKeyPress={this.ignoreAll}
          disabled={index === 0}
        >
          <ArrowUp className="arrow-up" />
        </Button>
        <Button
          id="move-down-btn"
          className="pl item-btn move-btn"
          onClick={() => {
            fields.move(index, index + 1);
          }}
          onKeyPress={this.ignoreAll}
          disabled={index === fields.length - 1}
        >
          <ArrowDown className="arrow-down" />
        </Button>
      </div>
    );
  };

  renderRemoveButton = (index, fields) => {
    if (this.isViewOnly) {
      return null;
    }

    let { hideRemoveOnPreselected, preSelectedItems, preSelectedItemsIds, minFieldsCount } = this.props;
    if (hideRemoveOnPreselected) {
      let length = (preSelectedItems || preSelectedItemsIds)?.length || 0;
      if (index < length) return null;
    }
    if (fields.get(index) && fields.get(index).disableRemove) {
      return null;
    }
    if (fields.length <= minFieldsCount) {
      return null;
    }
    return (
      <Button
        id="add-row-remove-bt"
        className="pl pl-x-input-close item-btn remove-btn"
        onClick={() => {
          this.removeButtonClickHandler(index, fields);
        }}
        onKeyPress={this.ignoreAll}
      />
    );
  };

  renderRemoveAllButton = (fields) => {
    if (this.isViewOnly || !this.props.showRemoveAllButton || !fields || fields.length < 1 || this.props.minFieldsCount) {
      return null;
    }

    return (
      <Button
        id="remove-all-bt"
        className="remove-all"
        onClick={() => {
          this.removeAllButtonClickHandler(fields);
        }}
        onKeyPress={this.ignoreAll}
      >
        <span className="pl pl-delete-icon" /> {this.props.removeAllButtonLabel}
      </Button>
    );
  };

  renderItemRow = (index, fieldName, itemRendererData) => {
    let options = this.props.itemRendererOptions ? this.getAvailableOptions(index) : undefined;
    const ItemRendererComponent = this.props.itemRendererComponent;

    return (
      <ItemRendererComponent
        id={fieldName}
        name={fieldName}
        index={index}
        onChangeCallback={this.onChangeCallback}
        options={options}
        preSelectedItem={this.props.preSelectedItems && this.props.preSelectedItems.length > index ? this.props.preSelectedItems[index] : null}
        change={this.props.change}
        itemRendererData={itemRendererData}
      />
    );
  };

  isOverflowCallback = () => {
    let listRect = this.listRef?.current?.getBoundingClientRect();
    if (listRect?.height && listRect?.width) {   //if the list is visible
      this.setState({ isOverflow: Math.ceil(listRect?.height) >= this.props.maxHeightToOverflow });
    }
  };

  isOverflow = (fieldsLength) => {
    if (this.props.maxHeightToOverflow) {
      setTimeout(() => {
        this.isOverflowCallback();
      }, 0);
    }
    return fieldsLength > this.props.maxItemsToOverflow;
  };

  renderRows = ({ fields, meta: { error } }) => {
    this.fields = fields;
    let isOverflow = this.isOverflow(fields.length) || this.state.isOverflow;

    return (
      <div
        ref={this.listRef}
        style={isOverflow && this.props.maxHeightToOverflow ? { maxHeight: this.props.maxHeightToOverflow } : {}}
        className={cn('add-remove-list-container-ext', { 'list-ext-overflow': isOverflow }, this.props.className)}
      >
        <div className={cn('add-remove-list')}>
          {fields.map((fieldName, index) => {
            return (
              <div className="list-item" key={'list-item' + index}>
                {this.renderItemRow(index, fieldName, this.props.itemRendererData)}
                {this.renderAddAtButton(index, fields)}
                {this.renderRemoveButton(index, fields)}
                {this.renderMoveButtons(index, fields)}
              </div>
            );
          })}
        </div>
        {this.renderAddButton(fields)}
        {this.renderRemoveAllButton(fields)}
      </div>
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.fields.move(oldIndex, newIndex);
  };

  renderDraggableRows = ({ fields, meta: { error } }) => {
    this.fields = fields;
    let isOverflow = this.isOverflow(fields.length) || this.state.isOverflow;

    return (
      <SortableContainer
        onSortEnd={this.onSortEnd}
        helperClass="SortableHelper"
        lockAxis="y"
        lockToContainerEdges={true}
        useDragHandle={true}
        helperContainer={this.listItemsContainerRef.current}
      >
        <div
          ref={this.listRef}
          style={isOverflow && this.props.maxHeightToOverflow ? { maxHeight: this.props.maxHeightToOverflow } : {}}
          className={cn('add-remove-list-container-ext', { 'list-ext-overflow': isOverflow }, this.props.className)}
        >
          <div className={cn('add-remove-list')} ref={this.listItemsContainerRef}>
            {fields.map((fieldName, index) => {
              return (
                <SortableItem key={'list-item' + index} index={index}>
                  <div className="list-item">
                    {this.renderItemRow(index, fieldName, this.props.itemRendererData)}
                    {this.renderAddAtButton(index, fields)}
                    {this.renderRemoveButton(index, fields)}
                    {this.renderMoveButtons(index, fields)}
                    <DragHandle />
                  </div>
                </SortableItem>
              );
            })}
          </div>
          {this.renderAddButton(fields)}
          {this.renderRemoveAllButton(fields)}
        </div>
      </SortableContainer>
    );
  };

  render() {
    const { name, updateKey, validate, allowDragSort } = this.props;

    return (
      <FieldArray
        name={name}
        component={allowDragSort ? this.renderDraggableRows : this.renderRows}
        updateKey={updateKey}
        updateOverflow={this.state.isOverflow}
        validate={validate}
      />
    );
  }
}

AddRemoveListExt.defaultProps = {
  addButtonDisabled: false,
  isViewOnly: false,
  maxItemsToRender: null,
  preSelectedItemsIds: [],
  hideAddAtButton: false,
  showRemoveAllButton: false,
  removeAllButtonLabel: '',
  showWarningOnRemoveOrChange: false,
  maxItemsToOverflow: 8,
  maxHeightToOverflow: 0,
  preSelectedItems: [],
  itemRendererData: null,
  parseNewAddedItem: (item) => item,
};

AddRemoveListExt.propTypes = {
  name: PropTypes.string,
  allowDragSort: PropTypes.bool,
  preSelectedItemsIds: PropTypes.array,
  itemRendererComponent: PropTypes.func.isRequired,
  itemRendererData: PropTypes.object,
  defaultItem: PropTypes.any, //the default value of newly added items
  isViewOnly: PropTypes.bool,
  addButtonDisabled: PropTypes.bool,
  showRemoveAllButton: PropTypes.bool,
  maxItemsToRender: PropTypes.number,
  hideAddAtButton: PropTypes.bool,
  showWarningOnRemoveOrChange: PropTypes.bool,
  warningMessageOnRemove: PropTypes.string,
  warningMessageOnChange: PropTypes.string,
  maxItemsToOverflow: PropTypes.number,
  maxHeightToOverflow: PropTypes.number,
  preSelectedItems: PropTypes.array,
  keepDataOnUnmount: PropTypes.bool,
  onListLengthChangedCallback: PropTypes.func,
  hideRemoveOnPreselected: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  minFieldsCount: PropTypes.number, //hides remove button when fields count <= this props value
  updateKey: PropTypes.any, //change this value to force inner field update
  parseNewAddedItem: PropTypes.func,
  validate: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      messageDialogActions: bindActionCreators(messageDialogActions, dispatch),
    },
  };
}

let AddRemoveListExtContainer = connect((state) => {
  return {};
}, mapDispatchToProps)(AddRemoveListExt);

export default AddRemoveListExtContainer;