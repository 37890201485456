import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {FetchEntitiesFilters} from '../../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../../enums/entityPropertyTypes';
import {enumTypes, getEnumLabel} from '../../../../../../utils/enumHelper';

require('./toolGroupsViewGrid.scss');

class ToolGroupsViewGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'toolGroupBusinessId', getOptions: false},
    {fieldName: 'toolCategory.businessId', filterName: 'toolTypeCategory', getOptions: true},
    {fieldName: 'kitTypes', filterName: 'toolGroupKitType', getOptions: true},
    {fieldName: 'toolTypes', filterName: 'toolGroupToolType', getOptions: true},
    {fieldName: 'name', filterName: 'toolGroupName', getOptions: true},
    {fieldName: 'configurationType', filterName: 'configurationType', getOptions: true},
  ];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.toolGroups.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.tooltype.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'toolGroupBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_GROUP_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId;
          }
        }
      },
      {
        fieldName: 'toolCategory.businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolTypeCategory',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.toolCategory?.businessId || '';
          },
        }
      },
      {
        fieldName: 'toolTypes',
        title: this.labels.get('columns.toolType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolGroupToolType',
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.toolTypes?.map(item => item.toolTypeBusinessId)?.join(', ') || [];
          },
        }
      },
      {
        fieldName: 'kitTypes',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolGroupKitType',
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.kitTypes?.map(item => item.kitTypeBusinessId)?.join(', ') || [];
          },
        }
      },
      {
        fieldName: 'name',
        title: this.labels.get('columns.toolGroupName.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolGroupName',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'configurationType',
        title: this.labels.get('columns.kitTypesConfiguration.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'configurationType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.TOOL_CONFIGURATION_TYPE)(params.data.configurationType);
          },
        }
      },
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'toolGroupsViewGrid'
  }
)(ToolGroupsViewGrid);
