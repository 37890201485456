export const gridsNames = {
  LOCATION_CUTTING: 'LOCATION_CUTTING',
  LOCATION_KITTING: 'LOCATION_KITTING',

  LOCATION_ASSET_ROLLS: 'LOCATION_ASSET_ROLLS',
  LOCATION_ASSET_SPOOLS: 'LOCATION_ASSET_SPOOLS',
  LOCATION_ASSET_RESINS: 'LOCATION_ASSET_RESINS',
  LOCATION_ASSET_KITS: 'LOCATION_ASSET_KITS',
  LOCATION_ASSET_PARTS: 'LOCATION_ASSET_PARTS',
  LOCATION_ASSET_GROUPS: 'LOCATION_ASSET_GROUPS',
  LOCATION_ASSET_TOOLS: 'LOCATION_ASSET_TOOLS',
  LOCATION_ACTIVITIES: 'LOCATION_ACTIVITIES',
  LOCATION_SHIPMENTS: 'LOCATION_SHIPMENTS',
  LOCATION_WORK_ORDER: 'LOCATION_WORK_ORDER',
  LOCATION_TASKS: 'LOCATION_TASKS',

  PREDEFINED_LIST_ALERTED_ROLLS: 'PREDEFINED_LIST_ALERTED_ROLLS',
  PREDEFINED_LIST_ALERTED_SPOOLS: 'PREDEFINED_LIST_ALERTED_SPOOLS',
  PREDEFINED_LIST_ALERTED_RESINS: 'PREDEFINED_LIST_ALERTED_RESINS',
  PREDEFINED_LIST_ALERTED_KITS: 'PREDEFINED_LIST_ALERTED_KITS',
  PREDEFINED_LIST_ALERTED_GROUPS: 'PREDEFINED_LIST_ALERTED_GROUPS',

  PREDEFINED_LIST_ARCHIVE_ROLLS: 'PREDEFINED_LIST_ARCHIVE_ROLLS',
  PREDEFINED_LIST_ARCHIVE_SPOOLS: 'PREDEFINED_LIST_ARCHIVE_SPOOLS',
  PREDEFINED_LIST_ARCHIVE_RESINS: 'PREDEFINED_LIST_ARCHIVE_RESINS',
  PREDEFINED_LIST_ARCHIVE_KITS : 'PREDEFINED_LIST_ARCHIVE_KITS',
  PREDEFINED_LIST_ARCHIVE_PARTS : 'PREDEFINED_LIST_ARCHIVE_PARTS',
  PREDEFINED_LIST_ARCHIVE_GROUPS : 'PREDEFINED_LIST_ARCHIVE_GROUPS',

  PREDEFINED_LIST_OPEN_WORK_ORDER: 'PREDEFINED_LIST_OPEN_WORK_ORDER',
  PREDEFINED_LIST_COMPLETED_WORK_ORDER: 'PREDEFINED_LIST_COMPLETED_WORK_ORDER',

  PREDEFINED_LIST_ACTIVE_TOOLS: 'PREDEFINED_LIST_ACTIVE_TOOLS',
  PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE: 'PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE',
  PREDEFINED_LIST_ARCHIVED_TOOLS: 'PREDEFINED_LIST_ARCHIVED_TOOLS',

  PREDEFINED_LIST_ACTIVE_ROLLS: 'PREDEFINED_LIST_ACTIVE_ROLLS',
  PREDEFINED_LIST_ACTIVE_SPOOLS: 'PREDEFINED_LIST_ACTIVE_SPOOLS',
  PREDEFINED_LIST_ACTIVE_RESINS: 'PREDEFINED_LIST_ACTIVE_RESINS',
  PREDEFINED_LIST_ACTIVE_KITS: 'PREDEFINED_LIST_ACTIVE_KITS',
  PREDEFINED_LIST_ACTIVE_PARTS: 'PREDEFINED_LIST_ACTIVE_PARTS',
  PREDEFINED_LIST_ACTIVE_GROUPS: 'PREDEFINED_LIST_ACTIVE_GROUPS',
  PREDEFINED_LIST_SMART_SELECTION: 'PREDEFINED_LIST_SMART_SELECTION',
  // PREDEFINED_LIST_PICK_LIST: 'PREDEFINED_LIST_PICK_LIST',
  PREDEFINED_LIST_PICK_LISTS: 'PREDEFINED_LIST_PICK_LISTS',
  PREDEFINED_LIST_LOTS: 'PREDEFINED_LIST_LOTS',

  PREDEFINED_LIST_ON_HAND_INVENTORY: 'PREDEFINED_LIST_ON_HAND_INVENTORY',
  PREDEFINED_LIST_SCHEDULER_OPERATIONS: 'PREDEFINED_LIST_SCHEDULER_OPERATIONS',

  PREDEFINED_MATERIAL_SCRAP: 'PREDEFINED_MATERIAL_SCRAP',

  ADMINISTRATION_USER_MANAGEMENT: 'ADMINISTRATION_USER_MANAGEMENT',
  ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION: 'ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION',

  ADMINISTRATION_SCHEDULING_LOGS: 'ADMINISTRATION_SCHEDULING_LOGS',
  ADMINISTRATION_NOTIFICATION_LOGS: 'ADMINISTRATION_NOTIFICATION_LOGS',
  ADMINISTRATION_ORGANIZATIONS_TAB: 'ADMINISTRATION_ORGANIZATIONS_TAB',
  ADMINISTRATION_DEMO_ORGANIZATIONS: 'ADMINISTRATION_DEMO_ORGANIZATIONS',
  ADMINISTRATION_PROMOTIONS: 'ADMINISTRATION_PROMOTIONS',
  ADMINISTRATION_ADMIN_TAB: 'ADMINISTRATION_ADMIN_TAB',
  ADMINISTRATION_ORGS_DASHBOARD: 'ADMINISTRATION_ORGS_DASHBOARD',
  ADMINISTRATION_MAT_SETTINGS_ASSET_TYPES: 'ADMINISTRATION_MAT_SETTINGS_ASSET_TYPES',
  ADMINISTRATION_MAT_SETTINGS_BAGS: 'ADMINISTRATION_MAT_SETTINGS_BAGS',
  ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES: 'ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES',
  ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES: 'ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES',
  ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES: 'ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES',
  ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS: 'ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS',
  ADMINISTRATION_MAT_SETTINGS_TOOL_CATEGORIES: 'ADMINISTRATION_MAT_SETTINGS_TOOL_CATEGORIES',
  ADMINISTRATION_MAT_SETTINGS_KIT_TYPES: 'ADMINISTRATION_MAT_SETTINGS_KIT_TYPES',
  ADMINISTRATION_MAT_SETTINGS_PART_TYPES: 'ADMINISTRATION_MAT_SETTINGS_PART_TYPES',
  ADMINISTRATION_MAT_SETTINGS_PLY_TYPES: 'ADMINISTRATION_MAT_SETTINGS_PLY_TYPES',
  ADMINISTRATION_MAT_SETTINGS_PROJECTS: 'ADMINISTRATION_MAT_SETTINGS_PROJECTS',
  ADMINISTRATION_MAT_SETTINGS_SHELF_LIFE_UPDATE: 'ADMINISTRATION_MAT_SETTINGS_SHELF_LIFE_UPDATE',
  ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES: 'ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES',
  ADMINISTRATION_MAT_SETTINGS_LOCATIONS: 'ADMINISTRATION_MAT_SETTINGS_LOCATIONS',
  ADMINISTRATION_MAT_SETTINGS_STATIONS: 'ADMINISTRATION_MAT_SETTINGS_STATIONS',
  ADMINISTRATION_MAT_SETTINGS_OPERATIONS: 'ADMINISTRATION_MAT_SETTINGS_OPERATIONS',

  ADMINISTRATION_PS_MANAGEMENT_WORK_ORDERS: 'ADMINISTRATION_PS_MANAGEMENT_WORK_ORDERS',
  ADMINISTRATION_PS_MANAGEMENT_ASSETS: 'ADMINISTRATION_PS_MANAGEMENT_ASSETS',
  ADMINISTRATION_SANDBOX: 'ADMINISTRATION_SANDBOX',

  ADMINISTRATION_RFID_SETTINGS_DEVICE_APPS: 'ADMINISTRATION_RFID_SETTINGS_DEVICE_APPS',
  ADMINISTRATION_RFID_SETTINGS_READERS_AND_ANTENNAS: 'ADMINISTRATION_RFID_SETTINGS_READERS_AND_ANTENNAS',
  ADMINISTRATION_RFID_SETTINGS_PRINTERS: 'ADMINISTRATION_RFID_SETTINGS_PRINTERS',
  ADMINISTRATION_RFID_SETTINGS_ALERT_DEVICES: 'ADMINISTRATION_RFID_SETTINGS_ALERT_DEVICES',
  ADMINISTRATION_RFID_SETTINGS_VISIBILITY_DEVICES: 'ADMINISTRATION_RFID_SETTINGS_VISIBILITY_DEVICES',
  ADMINISTRATION_RFID_SETTINGS_MQTT_BROKERS: 'ADMINISTRATION_RFID_SETTINGS_MQTT_BROKERS',
  ADMINISTRATION_RFID_SETTINGS_MQTT_SENSORS: 'ADMINISTRATION_RFID_SETTINGS_MQTT_SENSORS',
  ADMINISTRATION_RFID_SETTINGS_AUTOMATION_SCENARIOS: 'ADMINISTRATION_RFID_SETTINGS_AUTOMATION_SCENARIOS',
  ADMINISTRATION_RFID_SETTINGS_QUALIFIED_EVENTS: 'ADMINISTRATION_RFID_SETTINGS_QUALIFIED_EVENTS',
  ADMINISTRATION_RFID_SETTINGS_ACTION_SEQUENCE: 'ADMINISTRATION_RFID_SETTINGS_ACTION_SEQUENCE',

  ADMINISTRATION_SETTINGS_PREFERENCES:'ADMINISTRATION_SETTINGS_PREFERENCES',
  ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION:'ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION',
  ADMINISTRATION_SETTINGS_TERMINOLOGY:'ADMINISTRATION_SETTINGS_TERMINOLOGY',
  ADMINISTRATION_ALERTS_MISPLACED_MATERIALS: 'ADMINISTRATION_ALERTS_MISPLACED_MATERIALS',
  ADMINISTRATION_SETTINGS_SENSORS:'ADMINISTRATION_SETTINGS_SENSORS',

  ASSET_PAGE_ACTIVITY_LOG : 'ASSET_PAGE_ACTIVITY_LOG',
  ASSET_PAGE_ETL_LOG : 'ASSET_PAGE_ETL_LOG',
  ASSET_PAGE_ATTACHMENT : 'ASSET_PAGE_ATTACHMENT',
  LOT_PAGE_ATTACHMENTS : 'LOT_PAGE_ATTACHMENTS',
  WO_PAGE_ACTIVITY_LOG : 'WO_PAGE_ACTIVITY_LOG',
  PICK_LIST_PAGE: 'PICK_LIST_PAGE',

  IMPORT_ROLLS : 'IMPORT_ROLLS',
  IMPORT_SPOOLS : 'IMPORT_SPOOLS',
  IMPORT_RESINS : 'IMPORT_RESINS',
  IMPORT_SHIPMENT_DOCUMENT : 'IMPORT_SHIPMENT_DOCUMENT',
  IMPORT_ASN_SHIPMENTS : 'IMPORT_ASN_SHIPMENTS',
  IMPORT_WOS : 'IMPORT_WOS',
  IMPORT_KITS : 'IMPORT_KITS',
  IMPORT_GROUPS : 'IMPORT_GROUPS',
  IMPORT_TOOLS : 'IMPORT_TOOLS',
  IMPORT_TAGS : 'IMPORT_TAGS',
  IMPORT_KIT_TYPES : 'IMPORT_KIT_TYPES',
  IMPORT_MATERIAL_TYPES : 'IMPORT_MATERIAL_TYPES',
  IMPORT_TOOL_TYPES : 'IMPORT_TOOL_TYPES',
  IMPORT_TOOL_GROUPS : 'IMPORT_TOOL_GROUPS',
  IMPORT_GROUP_TYPES : 'IMPORT_GROUP_TYPES',
  IMPORT_PART_TYPES : 'IMPORT_PART_TYPES',
  IMPORT_PLY_TYPES : 'IMPORT_PLY_TYPES',
  IMPORT_KIT_TYPE_PLY_TYPES: 'IMPORT_KIT_TYPE_PLY_TYPES',
  IMPORT_BAGS : 'IMPORT_BAGS',
  IMPORT_PROJECTS : 'IMPORT_PROJECTS',
  IMPORT_OPERATION_SEQUENCE : 'IMPORT_OPERATION_SEQUENCE',
  IMPORT_NESTS_SELECT_NESTS : 'IMPORT_NESTS_SELECT_NESTS',
  IMPORT_NESTS : 'IMPORT_NESTS',
  IMPORT_NESTS_MULTI_ROLLS : 'IMPORT_NESTS_MULTI_ROLLS',
  IMPORT_NESTS_ANY_ROLLS_SELECT_NESTS : 'IMPORT_NESTS_ANY_ROLLS_SELECT_NESTS',
  IMPORT_NESTS_MULTI_ROLLS_SELECT_NESTS : 'IMPORT_NESTS_MULTI_ROLLS_SELECT_NESTS',
  IMPORT_NESTS_WITH_ROLL_SELECT_NESTS: 'IMPORT_NESTS_WITH_ROLL_SELECT_NESTS',
  IMPORT_NESTS_WITH_ROLL_SELECT_NESTS_STEP_TWO: 'IMPORT_NESTS_WITH_ROLL_SELECT_NESTS_STEP_TWO',
  IMPORT_NESTS_WITH_ROLL: 'IMPORT_NESTS_WITH_ROLL',
  IMPORT_ATTRIBUTES_UPDATE: 'IMPORT_ATTRIBUTES_UPDATE',

  BO_ACTIVITY_LOG: 'BO_ACTIVITY_LOG',
  TASKS_ASSIGNMENTS: 'TASKS_ASSIGNMENTS',
  DASHBOARD_CORRELATIONS_TOOLS: 'DASHBOARD_CORRELATIONS_TOOLS',
  DASHBOARD_CORRELATIONS_OPERATIONS: 'DASHBOARD_CORRELATIONS_OPERATIONS',
  DASHBOARD_CORRELATIONS_LOCATIONS: 'DASHBOARD_CORRELATIONS_LOCATIONS',

  BARCODE_IMPORT_WIZARD_SCAN_ASSETS_GRID: 'BARCODE_IMPORT_WIZARD_SCAN_ASSETS_GRID',
  BARCODE_IMPORT_WIZARD_VALIDATE_ASSETS_GRID: 'BARCODE_IMPORT_WIZARD_VALIDATE_ASSETS_GRID',
  BARCODE_IMPORT_WIZARD_CONFIRM_IMPORT_GRID: 'BARCODE_IMPORT_WIZARD_CONFIRM_IMPORT_GRID',
};
