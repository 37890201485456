import { change } from 'redux-form';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

export function getValidationMessages(validations) {
  if (validations) {
    let errors = _getLocalizedValidations(validations.errors);
    let highSeverityWarnings = validations?.warnings?.filter((w) => w.severity === 'HIGH') || [];
    let mediumSeverityWarnings = validations?.warnings?.filter((w) => w.severity !== 'HIGH') || [];
    let warnings = [];

    if (highSeverityWarnings && highSeverityWarnings.length) {
      warnings = warnings.concat(_getLocalizedValidations(highSeverityWarnings, 'severityHigh'));
    }
    if (mediumSeverityWarnings && mediumSeverityWarnings.length) {
      warnings = warnings.concat(_getLocalizedValidations(mediumSeverityWarnings, 'severityMedium'));
    }

    return { errors, warnings };
  }
  return null;
}

export function getResponseValidationDetails(response) {
  let validations = Network.hasValidationWarnings(response);
  if (!validations) {
    return null;
  }

  let hasHighSeverityWarning = validations.warnings ? !!validations.warnings.find((w) => w.severity === 'HIGH') : false;
  let hasMediumSeverityWarning = validations.warnings ? !!validations.warnings.find((w) => w.severity !== 'HIGH') : false;
  let canIgnoreValidation =
    !validations.errors && PermissionManager.hasIgnoreValidationsPermissions(hasMediumSeverityWarning, hasHighSeverityWarning);
  return { errors: validations.errors, warnings: validations.warnings, canIgnoreValidation, hasHighSeverityWarning, hasMediumSeverityWarning };
}

export function onFormChangeHandler(values, props, previousValues) {
  if (props && props.sData && (props.sData.get('showIgnoreValidationCheckbox') || props.sData.get('hasError'))) {
    if (values.footerValidationCheckbox === previousValues.footerValidationCheckbox) {
      props.dispatch(change(props.form, 'footerValidationCheckbox', false));
      props.actions.hideIgnoreValidationWarningsCheckbox();
      props.actions.toggleIgnoreValidationWarningsCheckbox(false);
    } else {
      props.actions.toggleIgnoreValidationWarningsCheckbox(values.footerValidationCheckbox);
    }
  }
}

function _getLocalizedValidations(validations) {
  if (validations?.length > 0) {
    let validationsLabels = createLabelHelper('mat.validations.');
    return validations.map((m) => {
      //Note: params should be 'undefined' (or {} but not null) when there is no params (for react-intl)
      return validationsLabels.get(m.errorKey, undefined, m.params ? m.params : undefined);
    });
  }
  return null;
}
