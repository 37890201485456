import { useCallback } from 'react';
import PropTypes from 'prop-types';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../../../../utils/materialHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import useLabels from 'infrastructure/js/hooks/useLabels';

import './kitTypeMaterialListItem.scss';

const ComboboxPortal = withPortal(Combobox);
const MultiSelectFieldPortal = withPortal(PL_MultiSelectField);

const KitTypeMaterialListItem = ({ options, name, index, substituteOptions, onChangeCallback, change }) => {
  const labels = useLabels('mat.administration.matsettings.kittypes.kitTypeForm.');
  const onMaterialChangeCallback = (value, oldValue) => {
    if (value && oldValue && value.value === oldValue.value) {
      return;
    }

    change(name + '.[substituteMaterials]', null);

    onChangeCallback?.(value, oldValue, index);
  };

  const onSubstituteChangeCallback = (value, oldValue) => {
    if (value && value.length > 3) {
      change(name + '.[substituteMaterials]', oldValue);
    }
  };

  const getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    let materialLabel = MaterialHelper.getMaterialFullLabel(option.data.materialName, option.data.businessId);

    return <Label text={materialLabel} />;
  };

  const renderWeightOrLength = (name) => {
    return PermissionManager.isWeightSupported() ? (
      <InputSection label={labels.get('weight')} htmlFor={name + '.[weight]'}>
        <TextField
          id={name + '.[weight]'}
          name={name + '.[weight]'}
          className="short-textfield"
          normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT))}
        />
      </InputSection>
    ) : (
      <InputSection label={labels.get('length')} htmlFor={name + '.[length]'}>
        <TextField
          id={name + '.[length]'}
          name={name + '.[length]'}
          className="short-textfield"
          normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH))}
        />
      </InputSection>
    );
  };

  const validatePliesUsed = useCallback((value, allValues, props) => {
    const materialId = allValues?.materials[index]?.material;
    let plyTypesCount = 0;
    
    Object.entries(allValues?.bags || {}).forEach(([_, plyTypes]) => {
      plyTypes.forEach(plyTypeEntry => {
        if (plyTypeEntry.material?.value === materialId) {
          plyTypesCount++;
        }
      });
    });

    allValues.plyTypes?.forEach(plyTypeEntry => {
      if (plyTypeEntry.material?.value === materialId) {
        plyTypesCount++;
      }
    });

    if(plyTypesCount > 0 && plyTypesCount !== value) {
      return labels.get('validation.numberOfExpectedPliesNotEqualAddedPlies');
    }

  }, [index, labels]);

  return (
    <div className="material-list-item">
      <div>
        <InputSection label={labels.get('requiredMaterial')} htmlFor={name + '.[material]'}>
          <ComboboxPortal
            id={name + '.[material]'}
            name={name + '.[material]'}
            singleValueRenderer={getComponentToRender}
            optionRenderer={getComponentToRender}
            validate={Validation.dropdown.required}
            options={options}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(options)}
            onChangeCallback={onMaterialChangeCallback}
            className=""
          />
        </InputSection>

        <InputSection label={labels.get('pliesExpected')} htmlFor={name + '.[quantity]'}>
          <TextField
            id={name + '.[quantity]'}
            name={name + '.[quantity]'}
            className="short-textfield"
            normalize={Normalize.number(true, 0, 99999)}
            validate={[Validation.required, validatePliesUsed]}
          />
        </InputSection>

        {renderWeightOrLength(name)}
      </div>
      <div>
        <InputSection label={labels.get('subMaterials')} htmlFor={name + '.[substituteMaterials]'}>
          <MultiSelectFieldPortal
            id={name + '.[substituteMaterials]'}
            name={name + '.[substituteMaterials]'}
            className="substitute-materials"
            options={substituteOptions}
            optionRenderer={getComponentToRender}
            closeMenuOnSelect={false}
            onChangeCallback={onSubstituteChangeCallback}
          />
        </InputSection>
      </div>
    </div>
  );
};

KitTypeMaterialListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

KitTypeMaterialListItem.getInitialValues = (data) => {
  return (
    data?.map((item) => {
      return item
        ? {
            material: item.material?.id,
            quantity: item.quantityExpectedPlies,
            length: item.length || item.length === 0 ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, item.length, 2) : null,
            weight: item.weight || item.weight === 0 ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, item.weight, 2) : null,
            substituteMaterials: item.substituteMaterials?.map((item) => {
              return {
                value: item.id,
                label: MaterialHelper.getMaterialFullLabel(item.materialName, item.businessId),
                data: item,
              };
            }),
          }
        : {};
    }) || []
  );
};

export default KitTypeMaterialListItem;
