import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./createOperationDialog.scss');

const durationTypesEnum = {
  FIXED_DURATION: 'Fixed Duration',
  MAN_HOURS: 'Man Hours'
}

const {FIXED_DURATION, MAN_HOURS} = durationTypesEnum;

const durationTypeOptions = [
  {label: FIXED_DURATION, value: FIXED_DURATION},
  {label: MAN_HOURS, value: MAN_HOURS}
]


class CreateOperationDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createoperation.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
  }

  maxLength50 = Validation.maxLength(50);
  maxLength200 = Validation.maxLength(200);


  componentDidMount() {
    let initialValues = {
      durationType: FIXED_DURATION
    };

    if (this.isEditMode) {
      initialValues = {
        operationType: this.itemToEdit.operationTypeId,
        durationType: this.itemToEdit.manHours ? MAN_HOURS : FIXED_DURATION,
        operationName: this.itemToEdit.displayName,
        instructions: this.itemToEdit.instructions
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine) ||
                    this.props.sData.get('loading') ||
                    this.props.sData.get('hasError') ||
                    (this.props.sData.get('showIgnoreValidationCheckbox') &&
                     !this.props.sData.get('isIgnoreValidationWarnings') )
        }
      ]
    };
  }

  getOperationTypes() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.operationTypes ? dialogData.operationTypes : [];
  }

  onSubmit = (data) => {

    let newData = {
      operationTypeId: data.operationType,
      displayName: data.operationName,
      isManHours: data.durationType === MAN_HOURS,
      instructions: data.instructions,
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
      newData.orgId = this.itemToEdit.orgId;
    }

    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  onHide =() => {
    this.props.actions.hide();
  };

  handleOperationTypeOnChange = (data) => {
    if (data && (data.label === 'Kit' || data.label === 'Cut')) {
      this.props.change('operationName', data.label);
    } else {
      this.props.change('operationName', '');
    }
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-operation-dialog"
        className="create-operation-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('code')+'*'} htmlFor="operationType" className="inline left-side no-margin">
          <Combobox id="operationType" name="operationType" options={this.getOperationTypes()}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.getOperationTypes())}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.handleOperationTypeOnChange}
          />
        </InputSection>

        <InputSection label={this.labels.get('name')+'*'} htmlFor="operationName" className="inline right-side no-margin">
          <TextField id="operationName" name="operationName" className="short-textfield"
                     validate={[Validation.required, this.maxLength50]} maxLength={50}
          />
        </InputSection>

        <InputSection label={this.labels.get('durationType')} htmlFor="durationType" className="inline left-side">
          <Combobox id="durationType" name="durationType" options={durationTypeOptions}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(durationTypeOptions)}
                    isDisabled={!PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled}
                    onChangeCallback={this.handleDurationTypeOnChange}
          />
        </InputSection>

        <InputSection label={this.labels.get('instructions')} htmlFor="instructions">
          <TextAreaField id="instructions" name="instructions" className="instructions-textField"
                         validate={this.maxLength200} maxLength={200}
          />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createOperationDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
  }
)(CreateOperationDialog);

CreateOperationDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired
};


