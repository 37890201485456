import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';

class BagsViewGrid extends React.PureComponent {
  filterConfig = [{ fieldName: 'businessId', filterName: 'businessId', getOptions: false }];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.bags.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.bag.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.BAG_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId;
          },
        },
      },
    ];
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid
          gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_BAGS}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
        ></Grid>
      </div>
    );
  }
}

export default reduxForm({
  form: 'bagsViewGrid',
})(BagsViewGrid);
