import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';
import DatePickerTouch from 'infrastructure/js/components/controls/DatePickerTouch/datepickerTouch';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import {isTouchApp} from 'infrastructure/js/utils/appHelper';

import './reportTaskProgressDialog.scss';

function maxDateValidate(value, allValues) {
  return Validation.maxDateTime(moment().utcOffset(0, true))(value, allValues);
}

const ReportProgressDialog = ({ sData, show, actions, handleSubmit, initialize }) => {
  const { submit, hide } = actions;
  const itemData = sData.get('itemToEdit');
  const isStarted = itemData.status === getEnumValue(enumTypes.TASK_STATUS)('STARTED');
  const DatePickerComponent = isTouchApp() ? DatePickerTouch : DatePicker;

  const [maxTime, setMaxTime] = useState(moment());

  const labels = useMemo(() => {
    const labels = createLabelHelper('mat.dialog.reportTaskProgressDialog.');
    const dialogLabels = createLabelHelper('mat.dialog.');

    return {
      title: isStarted ? labels.get('header.title.started') : labels.get('header.title.completed'),
      dateInput: isStarted ? labels.get('startedAt') : labels.get('completedAt'),
      submitBtn: isStarted ? labels.get('footer.markAsStarted') : labels.get('footer.markAsCompleted'),
      cancelBtn: dialogLabels.get('cancel'),
    };
  }, [isStarted]);

  useEffect(() => {
    initialize({
      reportDate: moment().utcOffset(0, true),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSubmit = (data) => {
    const selectedDate = DateTimeHelper.ConvertFromDate(data.reportDate);
    itemData[isStarted ? 'startTime' : 'endTime'] = selectedDate;
    submit(itemData);
  };

  const dialogButtons = {
    left: [
      {
        id: 'cancel',
        text: labels.cancelBtn,
        action: hide,
      },
    ],
    right: [
      {
        id: 'submit',
        text: labels.submitBtn,
        bsStyle: 'primary',
        action: handleSubmit(doSubmit)
      },
    ],
  };

  const handleDateChange = (date) => {

    if (!date) {
      setMaxTime(moment());
      return;
    }

    if (!date?._isValid) {
      return;
    }

    if (moment().isSame(date, 'day')) {
      setMaxTime(moment());
      return;
    }

    setMaxTime(moment({ hour: 23, minute: 59 }));
  };

  return (
    <Dialog
      id="report-task-progress-dialog"
      className="report-task-progress-dialog"
      titleText={labels.title}
      show={show}
      onHide={hide}
      footerButtons={dialogButtons}
    >
      <InputSection label={labels.dateInput + '*'} htmlFor="reportDate" className="inline">
        <DatePickerComponent
          id="reportDate"
          name="reportDate"
          maxDate={moment()}
          maxTime={maxTime}
          minTime={moment({ hour: 0, minute: 0 })}
          showTimeSelect
          timeIntervals={1}
          isClearable={true}
          onChangeCallback={handleDateChange}
          dateFormat={DateTimeHelper.getDateTimeFormat()}
          popperPlacement="auto"
          validate={[Validation.required, Validation.date, maxDateValidate]}
        />
      </InputSection>
    </Dialog>
  );
};

ReportProgressDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  show: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
};

ReportProgressDialog.defaultProps = {
  show: false,
  handleSubmit: () => {
    console.error('handleSubmit is missing!');
  },
  initialize: () => {
    console.error('initialize is missing!');
  },
  change: () => {
    console.error('change is missing!');
  },
};

export default reduxForm({
  form: 'reportTaskProgressDialogForm',
})(ReportProgressDialog);
