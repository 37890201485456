import Network from 'infrastructure/js/modules/network';
import * as projectsService from '../../services/Projects/projectsService';
import * as partsService from '../../services/PartTypes/partTypesService';
import * as kitTypesService from '../../services/KitTypes/kitTypesService';
import * as customerOrdersService from '../../services/CustomerOrders/customerOrdersService';
import * as workOrdersService from '../../services/WorkOrders/workOrdersService';
import { api as workOrderPageApi } from './workOrderPageActions.js';
import * as additionalFieldsService from '../../services/Administration/additionalFieldsService';
import {getEnumValue, enumTypes} from '../../utils/enumHelper';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  WORKORDERPAGE_EDITOR_FETCH_PROJECTS_READY:        "WORKORDERPAGE_EDITOR_FETCH_PROJECTS_READY",
  WORKORDERPAGE_EDITOR_FETCH_PARTS_READY:             "WORKORDERPAGE_EDITOR_FETCH_PARTS_READY",
  WORKORDERPAGE_EDITOR_FETCH_KIT_TYPES_READY:       'WORKORDERPAGE_EDITOR_FETCH_KIT_TYPES_READY',
  WORKORDERPAGE_EDITOR_FETCH_ORDERS_READY:    "WORKORDERPAGE_EDITOR_FETCH_ORDERS_READY",
  WORKORDERPAGE_EDITOR_FETCH_ADDITIONAL_FIELDS_READY: "WORKORDERPAGE_EDITOR_FETCH_ADDITIONAL_FIELDS_READY",

  WORKORDERPAGE_EDITOR_WO_UPDATE_IN_PROGRESS:      "WORKORDERPAGE_EDITOR_WO_UPDATE_IN_PROGRESS",
  WORKORDERPAGE_EDITOR_WO_UPDATE_FINISHED:         "WORKORDERPAGE_EDITOR_WO_UPDATE_FINISHED",
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  fetchProjectsReady: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_FETCH_PROJECTS_READY, payload: payload };
  },
  fetchPartsReady: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_FETCH_PARTS_READY, payload: payload };
  },
  fetchKitTypesReady : function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_FETCH_KIT_TYPES_READY, payload: payload };
  },
  fetchOrdersReady: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_FETCH_ORDERS_READY, payload: payload };
  },
  fetchAdditionalFieldsReady: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_FETCH_ADDITIONAL_FIELDS_READY, payload: payload };
  },


  workOrderUpdateInProgress: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_WO_UPDATE_IN_PROGRESS, payload: payload };
  },
  workOrderUpdateFinished: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_EDITOR_WO_UPDATE_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.fetchAllDataForEditor = function () {
  return function (dispatch, getState) {
    api.fetchAllDataForEditor(dispatch, getState)();
  };
};



jsxActions.updateWorkOrder = function (woId, woUpdateData) {
  return function (dispatch, getState) {

    dispatch(actions.workOrderUpdateInProgress());
    woUpdateData.id = woId;
    workOrdersService.updateWorkOrder(woUpdateData).then((response) => {

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('Update Work Order failed', response);
      }

      let updatedWO = response.data; // NOT IN USE.
      dispatch(actions.workOrderUpdateFinished(updatedWO));  // NOT IN USE.

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)();

      // Command Asset page to reload everything.
      workOrderPageApi.reload(dispatch, getState)(true, woId);


    });

  };
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};



api.fetchAllDataForEditor = function (dispatch, getState) {
  return function () {
    api.fetchProjects(dispatch, getState)();
    api.fetchParts(dispatch, getState)();
    api.fetchKitTypes(dispatch, getState)();
    api.fetchOrders(dispatch, getState)();
    api.fetchAdditionalFields(dispatch, getState)();
  };
};

api.fetchAdditionalFields = function(dispatch, getState) {
  return function() {
    additionalFieldsService.fetchAdditionalFields({entityType: getEnumValue(enumTypes.OBJECT_TYPE)('WO')})
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error("Fetch additional Fields Failed");
          return {success: false};
        }
        dispatch(actions.fetchAdditionalFieldsReady(response));
      });
  };
};

api.fetchProjects = function (dispatch, getState) {
  return function () {
    projectsService.fetchAllProjects()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch Projects Failed");
          return {success: false};
        }
        dispatch(actions.fetchProjectsReady(response));
      });
  };
};


api.fetchParts = function (dispatch, getState) {
  return function () {
    partsService.fetchAllParts()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch Parts Failed");
          return {success: false};
        }
        dispatch(actions.fetchPartsReady(response));
      });
  };
};

api.fetchKitTypes = function (dispatch, getState) {
  return function () {
    kitTypesService.fetchAllKitTypes()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Kit Types Failed');
          return {success: false};
        }
        dispatch(actions.fetchKitTypesReady(response));
      });
  };
},


api.fetchOrders = function(dispatch, getState) {
  return function() {
    customerOrdersService.fetchCustomerOrders()
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error("Fetch Orders Failed");
          return {success: false};
        }
        dispatch(actions.fetchOrdersReady(response));
      });
  };
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS



