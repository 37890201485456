import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import * as plyTypesService from '../../../../services/PlyTypes/plyTypesService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_PLY_TYPE_DIALOG_SHOW: 'CREATE_PLY_TYPE_DIALOG_SHOW',
  CREATE_PLY_TYPE_DIALOG_HIDE: 'CREATE_PLY_TYPE_DIALOG_HIDE',
  CREATE_PLY_TYPE_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_PLY_TYPE_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_PLY_TYPE_DIALOG_SUBMIT_FINISHED: 'CREATE_PLY_TYPE_DIALOG_SUBMIT_FINISHED',
  CREATE_PLY_TYPE_DIALOG_FETCH_DATA_READY: 'CREATE_PLY_TYPE_DIALOG_FETCH_DATA_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function (payload) {
    return { type: actionTypes.CREATE_PLY_TYPE_DIALOG_SHOW, payload: payload };
  },
  hide: function (payload) {
    return { type: actionTypes.CREATE_PLY_TYPE_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function (payload) {
    return { type: actionTypes.CREATE_PLY_TYPE_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.CREATE_PLY_TYPE_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function (payload) {
    return { type: actionTypes.CREATE_PLY_TYPE_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function (data, isEditMode, reloadParentComponent) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function (dispatch, getState) {
  return function () {
    dispatch(actions.hide());
  };
};

api.show = function (dispatch, getState) {
  return function (data = null) {
    dispatch(actions.show(data));
  };
};

api.submit = function (dispatch, getState) {
  return function (data, isEditMode, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    let method = isEditMode ? plyTypesService.updatePlyType : plyTypesService.createPlyType;

    method(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create/Edit ply type failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return { success: true };
    });
  };
};
