import Network from 'infrastructure/js/modules/network';
import * as locationsService from '../../../services/Locations/locationsService';
import * as operationsService from '../../../services/Operations/operationsService';
import * as stationsService from '../../../services/Stations/stationsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import {api as navigatorActionsApi} from '../../AppTopbar/navigatorActions';
import {api as sideNavigatorActionsApi} from '../../SideNavigator/sideNavigatorActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actionTypes = {
  DIGITAL_TWIN_FETCH_TREE_DATA_FINISHED:  'DIGITAL_TWIN_FETCH_FINISHED',
  DIGITAL_TWIN_SET_LOADING:               'DIGITAL_TWIN_SET_LOADING',
  DIGITAL_TWIN_FETCH_OPERATIONS_FINISHED: 'DIGITAL_TWIN_FETCH_OPERATIONS_FINISHED',
  DIGITAL_TWIN_UNMOUNT:                   'DIGITAL_TWIN_UNMOUNT',
};

const actions = {
  setLoading: function (payload) {
    return {type: actionTypes.DIGITAL_TWIN_SET_LOADING, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.DIGITAL_TWIN_FETCH_TREE_DATA_FINISHED, payload: payload};
  },
  fetchOperationsFinished: function (payload) {
    return { type: actionTypes.DIGITAL_TWIN_FETCH_OPERATIONS_FINISHED, payload: payload };
  },
  unmount: function () {
    return { type: actionTypes.DIGITAL_TWIN_UNMOUNT };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    api.fetchPlant(dispatch, getState)();
    api.fetchOperations(dispatch, getState)();
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.fetchPlant(dispatch, getState)();
  }
};

jsxActions.submit = function(data, isEditMode, isStation, isSection) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, isStation, isSection);
  };
};

jsxActions.move = function(data, isEditMode, isStation, isSection) {
  return function(dispatch, getState) {
    data.nodeMove = true;
    return api.submit(dispatch, getState)(data, isEditMode, isStation, isSection);
  };
};

jsxActions.delete = function(data) {
  return function(dispatch, getState) {
    return api.delete(dispatch, getState)(data);
  };
};

jsxActions.unmount = function () {
  return function (dispatch) {
    dispatch(actions.unmount());
  };
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchPlant = function(dispatch, getState) {
  return function() {
    dispatch(actions.setLoading(true));

    locationsService.fetchPlant().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Get Digital Twin initial data failed.', response);
        dispatch(actions.setLoading(false));
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      // if (__DEV__) {
      //   console.log('\n\n');
      //   _printAllIds(response.data?.root);
      //   console.log('\n\n');
      // }

      let plant = response.data?.root || {};
      _normalize(plant);

      dispatch(actions.fetchDataFinished([plant]));
      dispatch(actions.setLoading(false));
      return { success: true};
    });

  }
};

api.fetchOperations = function (dispatch, getState) {
  return function () {
    operationsService.fetchAllOperations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch operations failed', response);
        return { success: false };
      }
      let allOperations = response.dataList?.map((obj) => {return {value: obj.id, label: obj.displayName, data: obj}});
      let allPrimaryOperations = response.dataList?.map((obj) => {return {value: obj.id, label: obj.displayName + ' (primary)', data: obj}});
      let payload = {operations: allOperations, allPrimaryOperations};

      dispatch(actions.fetchOperationsFinished(payload));
      return { success: true};
    });
  };
};

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, isStation, isSection=false) {
    dispatch(actions.setLoading(true));

    let method = _getMethod(isEditMode, isStation, isSection);

    if (isEditMode) {
      data = isSection ? data : isStation ? {stations: [data]} : {locations: [data]};
    }

    return method(data).then((response) => {
      dispatch(actions.setLoading(false));

      if (!Network.isResponseValid(response)) {
        console.error('Create/Edit section / station / location failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      const validations = Network.hasValidationWarnings(response);
      if (validations?.warnings) {
        const messageDialogDescriptor = _warningMessageDialogDescriptor(validations);
        if (messageDialogDescriptor) {
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      }

      //update stations in the Top Navigator menu
      navigatorActionsApi.fetchLocations(dispatch, getState)();

      //update stations in the Side Navigator menu
      sideNavigatorActionsApi.fetchLocations(dispatch, getState)();

      let data = (isEditMode && !isSection) ? response.dataList?.[0] : response.data;

      return {success: true, data};
    });
  };
};

api.delete = function(dispatch, getState) {
  return function (data) {
    dispatch(actions.setLoading(true));

    return locationsService.removeLocations( {ids: [data]}).then((response) => {
      dispatch(actions.setLoading(false));

      if (!Network.isResponseValid(response)) {
        console.error('Delete item failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if(response.dataList){
        let errors = response.dataList.filter(data => data.applicationResponseStatus.statusType === 'ERROR');
        if(errors.length > 0) {
          let labels = createLabelHelper('mat.administration.matsettings.error.');
          let names = errors.map(e => e.data.name);
          let children = names.join(', ');
          let title = `${labels.get('deleteItemRelatedAssets')} ${children}`;
          let type = 'warning';
          let className = 'oneBackground';

          let messageDialogDescriptor = {title, type, className};
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          return {success: false};
        }
        else {
          let labels = createLabelHelper('mat.administration.matsettings.');
          let type = 'success';
          let className = 'oneBackground';
          let count = response.dataList.length;
          let messageDialogDescriptor = {title: labels.get('success.deleteItem', 'xxx', { count }), type, className};
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      }

      //update stations in the Top Navigator menu
      navigatorActionsApi.fetchLocations(dispatch, getState)();

      //update stations in the Side Navigator menu
      sideNavigatorActionsApi.fetchLocations(dispatch, getState)();

      return {success: true};
    });
  }
}


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _getMethod(isEditMode, isStation, isSection) {

  if (isSection) {
    return isEditMode ? locationsService.updateSection : locationsService.createSection;
  }
  if (isStation) {
    return isEditMode ? stationsService.updateStations : stationsService.createStation;
  }
  return isEditMode ? locationsService.updateLocations : locationsService.createLocation;
}

//removing children: [] from the leafs (stations, locations)
function _normalize(item) {
  if (item.children?.length) {
    item.children.forEach((child) => {
      _normalize(child);
    });
  }

  if (!item?.isSection) {
    delete item.children;
  }
}

//for debug only in __DEV__ environment
function _printAllIds(item) {
  console.log('--- ' +
    _toStr('name', 20) +
    _toStr('id') +
    _toStr('parentId') +
    _toStr('prevSibId') +
    _toStr('nextSibId') );

  _printIds(item);
}

function _printIds(item) {
  if (!item) {
    return null;
  }

  console.log('---- ' +
    _toStr(item.name, 20) +
    _toStr(item.id) +
    _toStr(item.parentId) +
    _toStr(item.prevSiblingId) +
    _toStr(item.nextSiblingId) );

  if (item.children?.length) {
    item.children.forEach((child, index) => {
      _printIds(child);
    });
  }
}

function _toStr(value, num=10) {
  if (value === null) {
    value = 'null'
  }
  return value.toString().padEnd(num, ' ');
}

function _warningMessageDialogDescriptor(warnings) {
  let validations = dialogHelper.getValidationArea(warnings);

  if (validations && validations.length > 0) {
    let messages = [];
    validations.forEach((v) => {
      if (v && v.messages) {
        v.messages.forEach((m) => {
          messages.push(m);
        });
      }
    });

    if (messages.length > 0) {
      let title = messages[0] ? messages[0].message : 'Invalid Input';
      let type = 'warning';
      let className = 'oneBackground';

      return { title, type, className };
    }
  }
  return null;
}

