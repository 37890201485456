import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import Button from 'infrastructure/js/components/controls/Button/button';
import DropdownButton from 'infrastructure/js/components/controls/DropdownButton/dropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {ImportTypes , ImportAcceptFileTypes} from '../../../../enums/importTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./header.scss');

export default class WorkOrderHeader extends React.PureComponent{

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.workorder.grid.header.');
  }

  isOperationDisabled = (operation) => {
    return (operation.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP') ||
            operation.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('MERGE') ||
            operation.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS') ||
            operation.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION')) ?
    !this.props.sHasRows || this.props.sSelectedRowsData.size > 1  : !this.props.sHasRows;
  };

  renderPrimaryOperations = () => {
    if (!this.props.operations || this.props.operations.length === 0) {
      return null;
    }
    //TODO - remove cut and kit from filter when they are supported
    let primaryOperations = this.props.operations.filter((operation) => operation.primary &&
                                                                        operation.operationType.name !== getEnumValue(enumTypes.OPERATION_TYPE)('CUT') &&
                                                                        operation.operationType.name !== getEnumValue(enumTypes.OPERATION_TYPE)('KIT') &&
                                                                        operation.operationType.name !== getEnumValue(enumTypes.OPERATION_TYPE)('AFP') );
    let buttons = primaryOperations.map((operation) => {
      let isDisabled = this.isOperationDisabled(operation);
      return (<Button
                id={operation.displayName}
                className="border-btn"
                key={operation.displayName}
                disabled={isDisabled || this.props.loading}
                onClick={() => {
                  this.props.actions.onOpenDialogClick(operation, this.props.sSelectedRowsData)
                }}
              >
                {operation.displayName}
              </Button>);
    });

    return buttons;
  };

  renderSecondaryOperations = () => {
    if (!this.props.operations || this.props.operations.length === 0) {
      return null;
    }
    let secondaryOperations = this.props.operations.filter((operation) => !operation.primary);
    let menuItems = secondaryOperations.map((operation, index) => {
      let isDisabled = this.isOperationDisabled(operation);

      return (<Dropdown.Item key={index}
                eventKey={index}
                href=""
                disabled={isDisabled}
                onClick={() => {
                  this.props.actions.onOpenDialogClick(operation)
                }}
      >
        {operation.displayName}
      </Dropdown.Item>);
    });

    return menuItems;
  };

  markWOasComplete = () => {
    let selectedWOs = this.props.gridData.get('selectedRowsData');
    let payload = {
      preselectedAssets: selectedWOs,
      status: getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED'),
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('WO')]
    };

      this.props.actions.markWOasComplete(payload);
  };

  markWOasCanceled = () => {
    let selectedWOs = this.props.gridData.get('selectedRowsData');
    let payload = {
      preselectedAssets: selectedWOs,
      status: getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED'),
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('WO')]
    };

      this.props.actions.markWOasCanceled(payload);
  };

  openImportWorkOrdersDialog = () => {
    let config = {
      importTypes: [ImportTypes.IMPORT_WO],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.props.actions.reloadParentComponent
    };
    this.props.actions.importFilesWizardActions.openWizard(config);
  };

  openImportAttributesUpdateWizard = () => {
    let importType =  PermissionManager.isPartTypeOriented() ?
      getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE') :
      getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE');

    this.props.actions.onMenuItemImportAttributesUpdateClick({importType});
  };

  renderImportWOsMenuItem =() => {
    if (PermissionManager.hasImportAssetsPermissions()) {
    return  <Dropdown.Item eventKey={104}  href=""
                           onClick={this.openImportWorkOrdersDialog}
    >
      {this.labels.get('menu.importwo')}
    </Dropdown.Item>
    }
    else return '';
  };

  openCreateGroupDialog = () => {
    let selectedWOs = this.props.gridData.get('selectedRowsData');
    let payload = {
      preselectedWO: selectedWOs && selectedWOs.size > 0 ? selectedWOs.get(0) : null,
    };
    this.props.actions.openCreateGroupDialog(payload);
  };

  renderCreateGroupMenuItem = () => {
    if (PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled && PermissionManager.hasCreateAssetsPermissions()) {
      return <Dropdown.Item eventKey={105}  href=""
                       onClick={this.openCreateGroupDialog}
                       disabled={!this.props.sHasRows || this.props.gridData.get('selectedRowsData').size > 1}
      >
        {this.labels.get('buttons.createGroup')}
      </Dropdown.Item>
    }
    return '';
  }

  renderCreateWo =() => {
    if (PermissionManager.hasCreateAssetsPermissions()) {
      return <Dropdown.Item key="100" eventKey={100} href=""
                          onClick={this.props.actions.openCreateWorkOrderDialog}
      >
        {this.labels.get('buttons.createwo')}
      </Dropdown.Item>
    }
    else return '';
  }
  renderMarkAsCompleteItem = () => {
    if (PermissionManager.hasMarkWoCompletePermissions()) {
      return <Dropdown.Item key="101" eventKey={101}
        onClick={this.markWOasComplete}
        // disabled={this.props.gridData.get('selectedRowsData').size != 1}
      >
        {this.labels.get('buttons.markwoascomplete')}
      </Dropdown.Item>
    }
    else return '';
  };

  renderMarkAsCanceledItem = () => {
    if (PermissionManager.hasMarkWoCanceledPermissions()) {
      return <Dropdown.Item key="202" eventKey={202}
        onClick={this.markWOasCanceled}
      >
        {this.labels.get('buttons.markwoascanceled')}
      </Dropdown.Item>
    }
    else return '';
  };

  renderExportWOsListItem = () => {
    return (
      <Dropdown.Item key="102" eventKey={102}
                     onClick={this.exportWOsList}
      >
        {this.labels.get('buttons.export')}
      </Dropdown.Item>
    )
  }

  renderEditAttributesItem =() => {
    if (PermissionManager.hasEditAttributesPermissions()) {
      return  <Dropdown.Item key="103" eventKey={103}
                             onClick={this.edit}
      >
        {this.labels.get('buttons.edit')}
      </Dropdown.Item>
    }
    else return '';
  };

  renderImportAttributesUpdateMenuItem = () => {
    if (PermissionManager.hasEditAttributesPermissions()) {
      return <Dropdown.Item eventKey={106} href=""
                            onClick={this.openImportAttributesUpdateWizard}
      >
        {this.labels.get('menu.importAttributesUpdate')}
      </Dropdown.Item>
    }
    return '';
  };

  edit = () => {
    let payload = {
      preselectedAssets: this.props.gridData.get('selectedRowsData'),
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('WO')]
    };
    this.props.actions.editWoAttributes.show(payload);
  };

  exportWOsList = () => {
    this.props.actions.export( {gridName : gridsNames.LOCATION_WORK_ORDER} );
  };

// NOTE: Regarding SAAS-13798 - hide 'Cut & Kit' wizard
//   getHeaderItems() {
//     let {actions, loading, sHasRows, sSelectedRowsData} = this.props;
//
//     let items = {
//       buttons: [
//         {
//           id:'refreshAll',
//           label: '',
//           icon: 'pl pl-refresh',
//           action: this.reloadPage,
//           disabled: loading,
//           tooltip: headerItemsLabels.REFRESH_ALL,
//         },
//         {
//           id: 'cutAndKit',
//           label: headerItemsLabels.CUT_AND_KIT,
//           icon: 'pl pl-cut-icon',
//           counter: sSelectedRowsData.size,
//           disabled: loading,
//           action: actions.headerActions.onCutAndKitClick,
//           actionData: {
//             preselectedAssets: this.props.sSelectedRowsData,
//             preselectedAssetsType: getEnumValue(enumTypes.OBJECT_TYPE)('WO'),
//             origin: 'workordersTab',
//             parentComponent: this.props.reloadParentComponent,
//           },
//           loading: loading,
//         },
//       ],
//     };
//     return items;
// }

  getHeaderItems() {
    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: this.props.actions.clearFilters,
          disabled: (this.props.loading),
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          disabled: (this.props.loading),
          tooltip: headerItemsLabels.REFRESH_ALL,
        }
      ],
    };
    return items;
  }

  reloadPage = () => {
    if (this.props.actions.reloadParentComponent) {
      this.props.actions.reloadParentComponent();
    }
  };

  render() {
    if (!this.props.gridData) {
      return null;
    }

    let headerItems = this.getHeaderItems();

    return (
      <div className="wo-header">
        <div className="title">{this.labels.get('title')}</div>

        <div className="header-buttons">
          <Header  {...headerItems}/>
        </div>

        <div className="operations-buttons">

          { this.renderPrimaryOperations()}

          <DropdownButton id="wo-kebab-menu" className="border-btn" disabled={this.props.loading}>

            {this.renderCreateGroupMenuItem()}
            { this.renderSecondaryOperations()}
            {this.renderCreateWo()}
            {this.renderMarkAsCompleteItem()}
            {this.renderMarkAsCanceledItem()}
            {this.renderExportWOsListItem()}
            {this.renderEditAttributesItem()}
            {this.renderImportAttributesUpdateMenuItem()}
            {this.renderImportWOsMenuItem()}

          </DropdownButton>
        </div>
      </div>
    );
  }
}

WorkOrderHeader.defaultProps = {
};
WorkOrderHeader.propTypes = {
};
