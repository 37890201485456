import Network from 'infrastructure/js/modules/network';

export function createPlyType(data) {
  return Network.post('plyTypes', data);
}

export function updatePlyType(data) {
  return Network.put('plyTypes', {plyTypes: [data]});
}

export function deletePlyTypes({ids}) {
  return Network.delete('plyTypes', {plyTypesIds: ids});
}

export function fetchPlyTypes(query = {}) {
  return Network.post('plyTypes/items', query);
}

export function searchPlyTypes(query = {}) {
  return Network.post('plyTypes/search/items', query);
}