import Network from 'infrastructure/js/modules/network';

export function createReasonType(data) {
  return Network.post('reason-type', data);
}

export function editReasonType (data) {
  return Network.post('reason-type/update', {reasonTypes: [data]});
}

export function removeReason(ids) {
  return Network.delete('reason-type', {reasonTypeIds: ids.ids});
}
