import { Map } from 'immutable';
import defaultFormReducer from '../../defaultFormReducer';
import { formsNames } from '../../../enums/formsNames';

let defaultState = {
  loading: false,
  itemToEdit: null,
  formData: null,
  isClone: false,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    default:
      return defaultFormReducer(state, action, formsNames.CREATE_KIT_TYPE_FORM, defaultState);
  }
}
