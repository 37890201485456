import React from 'react';
import WorkOrdersHeader    from './Header/header.js';
import WorkOrderGrid from '../../Common/Grids/CommonWorkOrdersGrid/commonWorkOrdersGrid';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import CreateWorkOrderDialog  from '../../Dialogs/CreateWorkOrderDialog/createWorkOrderDialog';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import EditWoAttributesDialog       from '../../Dialogs/EditWoAttributesDialog/editWoAttributesDialog.js';
import EditWorkOrdersStatusDialog from       '../../Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialog';
import {gridsNames} from '../../../enums/gridsNames';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./workOrderView.scss');

export default class WorkOrderView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.workorder.');
  }

  getWorkOrdersHeaderItems = () => {
    let workOrderHeaderActions = {
      onOpenDialogClick:            this.props.actions.onOpenDialogClick,
      openCreateWorkOrderDialog:    this.props.actions.openCreateWorkOrderDialog,
      openCreateGroupDialog:        this.props.actions.openCreateGroupDialog,
      markWOasComplete:             this.props.actions.markWOasComplete,
      markWOasCanceled:             this.props.actions.markWOasCanceled,
      export:                       this.props.actions.exportActions.export,
      editWoAttributes:             this.props.actions.editWoAttributesActions,
      importFilesWizardActions:     this.props.actions.importFilesWizardActions,
      reloadParentComponent:        this.props.actions.locationPageActions.reload,
      clearFilters:                 this.props.actions.clearFilters,
      onMenuItemImportAttributesUpdateClick: this.props.actions.headerActions.onMenuItemImportAttributesUpdateClick,
      ...this.props.actions.workOrderDialogActions,
      ...this.props.actions.createWorkOrderDialogActions,
    };

    return {
      actions: workOrderHeaderActions,
      operations: this.props.sWorkOrderViewData.get("operations"),
      gridData: this.props.sWorkOrderGridData,
      sHasRows: this.props.sHasRows,
      sSelectedRowsData: this.props.sSelectedRowsData,
      loading: this.isLoading(),
    };
  };

  isLoading = () => {
    return (this.props.sWorkOrderGridData ? this.props.sWorkOrderGridData.get('loading') : false);
  };

  reloadLocationPage = () => {
    if (this.props.actions.locationPageActions && this.props.actions.locationPageActions.reload) {
      this.props.actions.locationPageActions.reload();
    }
  };

  render() {
    let workOrdersHeaderItems = this.getWorkOrdersHeaderItems();

    return (
      <div className="location-workOrder-view">
            <WorkOrdersHeader {...workOrdersHeaderItems}/>

            <WorkOrderGrid gridName={gridsNames.LOCATION_WORK_ORDER}
                           defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                           actions={{...this.props.actions.workOrderGridActions, ...this.props.actions.fetchEntitiesActions}}
                           showPickDate={PermissionManager.getOrgPreferences().woPickDateSupported}/>

            <PL_DialogWrapper dialogComponent={CreateWorkOrderDialog}
                              defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                              show={this.props.sCreateWorkOrderDialogData.get('show')}
                              sData={this.props.sCreateWorkOrderDialogData}
                              actions={{...this.props.actions.createWorkOrderDialogActions, ...this.props.actions.fetchEntitiesActions}}
                              sLoggedInUser={this.props.sLoggedInUser}
                              reloadParentComponent={this.reloadLocationPage}
            />
            <PL_DialogWrapper dialogComponent={EditWoAttributesDialog}
                              defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                              show={this.props.sEditWoAttributesData.get('show')}
                              actions={this.props.actions.editWoAttributesActions}
                              sData={this.props.sEditWoAttributesData}
                              reloadParentComponent={this.reloadLocationPage}
                              sLoggedInUser={this.props.sLoggedInUser}
            />
            <PL_DialogWrapper dialogComponent={EditWorkOrdersStatusDialog}
                              show={this.props.sEditWorkOrdersStatusData.get('show')}
                              actions={this.props.actions.editWorkOrdersStatusActions}
                              sData={this.props.sEditWorkOrdersStatusData}
                              reloadParentComponent={this.reloadLocationPage}/>
      </div>
    );

  }
}

