import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as kitTypesViewActions} from '../../../actions/Administration/MatSettingsPage/kitTypesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as kitTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/kitTypesViewGridActions';
import { jsxActions as createKitTypeFormActions} from '../../../actions/Administration/MatSettingsPage/createKitTypeFormActions.js';
import { jsxActions as importKitTypesDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/importKitTypesDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import KitTypesView from '../../../components/Administration/MatSettingsPage/KitTypesView/kitTypesView.js';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(kitTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      kitTypesViewGridActions: bindActionCreators(kitTypesViewGridActions, dispatch),
      createKitTypeFormActions : bindActionCreators(createKitTypeFormActions, dispatch),
      importKitTypesDialogActions : bindActionCreators(importKitTypesDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let KitTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_KIT_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_KIT_TYPES) : null;
  	return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateKitTypeFormData: state.administration.getIn(['matSettingsPage', 'createKitTypeForm']),
      sImportKitTypesDialogData: state.administration.getIn(['matSettingsPage', 'importKitTypesDialog']),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(KitTypesView);


export default KitTypesViewContainer;



