import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';
import { getResponseValidationDetails } from 'infrastructure/js/components/Form/formHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

const actionTypes = {
  FETCH_FORM_DATA_READY: '_FETCH_FORM_DATA_READY',
  SET_BUSY: '_SET_BUSY',
  CLEAR_FORM_DATA: '_CLEAR_FORM_DATA',
  SET_VALIDATION_WARNINGS_DATA: '_SET_VALIDATION_WARNINGS_DATA',
  HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
};

export function getActionTypes(prefix) {
  let keys = Object.keys(actionTypes);
  let res = {};
  keys.forEach((key) => {
    res[prefix + actionTypes[key]] = prefix + actionTypes[key];
  });
  return res;
}

export function getActions(prefix) {
  return {
    fetchFormDataReady(payload) {
      return { type: prefix + actionTypes.FETCH_FORM_DATA_READY, payload };
    },
    clearFormData() {
      return { type: prefix + actionTypes.CLEAR_FORM_DATA, payload: null };
    },
    setBusy(payload) {
      return { type: prefix + actionTypes.SET_BUSY, payload };
    },
    setValidationWarningsData(payload) {
      return { type: prefix + actionTypes.SET_VALIDATION_WARNINGS_DATA, payload };
    },
    hideIgnoreValidationWarningsCheckbox() {
      return { type: prefix + actionTypes.HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX };
    },
    toggleIgnoreValidationWarningsCheckbox(payload) {
      return { type: prefix + actionTypes.TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX, payload };
    },
  };
}

export function getJsxActions(api) {
  return {
    submit(data, messageDialogBuilder, reloadParentComponent, submitConfigData = null) {
      return function (dispatch, getState) {
        api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent, submitConfigData);
      };
    },

    fetchFormData(fetchConfig, callback) {
      return function (dispatch, getState) {
        api.fetchFormData(dispatch, getState)(fetchConfig, callback, true);
      };
    },

    clearFormData() {
      return function (dispatch, getState) {
        api.clearFormData(dispatch, getState)();
      };
    },

    hideIgnoreValidationWarningsCheckbox() {
      return function (dispatch, getState) {
        api.hideIgnoreValidationWarningsCheckbox(dispatch, getState)();
      };
    },

    toggleIgnoreValidationWarningsCheckbox(value) {
      return function (dispatch, getState) {
        api.toggleIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
      };
    },

    abandonDirtyForm(cb) {
      return function (dispatch, getState) {
        api.abandonDirtyForm(dispatch, getState)(cb);
      };
    },
  };
}

export function getApiActions(actions, config) {
  if (config.getSubmitMethod === undefined || typeof config.getSubmitMethod !== 'function') {
    console.error('formActionsHelper: config.getSubmitMethod error: ', config.getSubmitMethod);
    return {};
  }

  return {
    submit(dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent, submitConfigData = null) {
        dispatch(actions.setBusy(true));

        config
          .getSubmitMethod(submitConfigData)(data)
          .then((response) => {
            dispatch(actions.setBusy(false));

            let validations = getResponseValidationDetails(response);
            if (validations) {
              dispatch(actions.setValidationWarningsData(validations));
              return response; //TODO: L test it!
            }

            dispatch(actions.hide());

            if (!Network.isResponseValid(response)) {
              console.error(config.getSubmitMethod(submitConfigData).name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return response; //TODO: L test it!
            }

            if (reloadParentComponent) {
              reloadParentComponent();
            }

            if (messageDialogBuilder) {
              let messageDialogDescriptor = messageDialogBuilder(...this.getMessageDialogBuilderData(dispatch, getState)(response, data));
              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
          })
          .catch((error) => {
            console.error('formActionsHelper: submit failed, catch error: ', error);
            dispatch(actions.setBusy(false));
            dispatch(actions.hide());
          });
      }.bind(this);
    },

    getMessageDialogBuilderData(dispatch, getState) {
      return function (response) {
        return [response.data, messageDialogApi.close(dispatch, getState)];
      };
    },

    hideIgnoreValidationWarningsCheckbox(dispatch) {
      return function () {
        dispatch(actions.hideIgnoreValidationWarningsCheckbox());
      };
    },

    toggleIgnoreValidationWarningsCheckbox(dispatch) {
      return function (value) {
        dispatch(actions.toggleIgnoreValidationWarningsCheckbox(value));
      };
    },

    clearFormData(dispatch) {
      return function () {
        dispatch(actions.clearFormData());
      };
    },

    abandonDirtyForm(dispatch, getState) {
      return function (cb) {
        const dialogLabels = createLabelHelper('mat.dialog.');
        const title = dialogLabels.get('warning');
        const submitButtonLabel = dialogLabels.get('ok');
  
        const message = createLabelHelper('mat.navigation.confirmation.datanotsaved.')
          .get('text')
          .split('\n')
          .map((subStr) => {
            return (
              <>
                {subStr}
                <br />
              </>
            );
          });
  
        let confirmationConfig = {
          title,
          message,
          submitButtonLabel,
          cancelHandler: messageDialogApi.close(dispatch, getState),
          submitHandler: () => {
            messageDialogApi.close(dispatch, getState)();
            cb();
          },
        };
  
        messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
      };
    },
  };
}
