import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper.js';
import TimeEditorCell from 'infrastructure/js/components/Grid/TimeEditorCell/timeEditorCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import TaskScheduled from 'infrastructure/js/components/DynamicIcons/TaskScheduled.js';
import TaskStarted from 'infrastructure/js/components/DynamicIcons/TaskStarted.js';
import TaskCompleted from 'infrastructure/js/components/DynamicIcons/TaskCompleted.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import ProgressCell from '../../../Common/CustomGridCells/ProgressCell/progressCell';
import { gridsNames } from '../../../../enums/gridsNames';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import TaskDetailRow from './taskDetailRow';
import TaskSyncedIcon from '../../../../../assets/svg/task-synced.svg?url';
import TaskNotSyncedIcon from '../../../../../assets/svg/task-not-synced.svg?url';
import { enumTypes, getEnumValue } from '../../../../utils/enumHelper';
import TickingTimeCell from '../../../Common/CustomGridCells/TickingTimeCell/tickingTimeCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import { navigationStates } from '../../../../enums/navigationStates';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import HumanCapacityChip from './humanCapacityChip';
import { filterTypes } from 'infrastructure/js/enums/filterTypes.js';
import { filterAlignmentTypes } from 'infrastructure/js/enums/filterAlignmentTypes';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import {
  getCompletedQuantityInfo,
  getFormattedTokenDetails,
  getQuantityInfo
} from '../../../Common/Helpers/SchedulerHelper';
import { isToolRequired } from '../../../../utils/toolRequiredHelper';
import MaterialHelper from '../../../../utils/materialHelper';
import {isCuringStation} from '../../../../utils/locationHelper';
import * as AppHelper from 'infrastructure/js/utils/appHelper';

import './tasksGrid.scss';

class TasksGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'name', filterName: 'name', getOptions: false },
    { fieldName: 'workOrder.name', filterName: 'operationWorkOrder', getOptions: false },
    { fieldName: 'kitType.businessId', filterName: 'kitTypeBusinessId', getOptions: false },
    { fieldName: 'material.businessId', filterName: 'operationMaterial', getOptions: true },
    { fieldName: 'operationStatus', filterName: 'operationStatus', getOptions: true },
    { fieldName: 'humanCapacity', filterName: 'plannedHumanCapacity', getOptions: false },
    { fieldName: 'startTime', filterName: 'plannedOrActualStartTime', getOptions: false },
    { fieldName: 'endTime', filterName: 'plannedOrActualEndTime', getOptions: false },
    { fieldName: 'timeLeft', filterName: 'timeLeft', getOptions: false },
    { fieldName: 'quantity', filterName: 'quantity' },
    { fieldName: 'vacuumPort', filterName: 'vacuumPort', getOptions: true },
    {fieldName: 'workOrderDescription', filterName: 'operationWorkOrderDescription'},
    {fieldName: 'productionLine', filterName: 'productionLine', getOptions: true},
  ];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.tasks.grid.');
    this.filterLabels = createLabelHelper('mat.filter.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    const { completed, started, scheduled } = getSystemColors();
    const colorsMap = {
      SCHEDULED: scheduled,
      STARTED: started,
      COMPLETED: completed,
    };

    const isTouchApp = AppHelper.isTouchApp();

    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.name.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'name',
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_NAME,
          action: this.props.actions.fetchEntities,
          filter: [FetchEntitiesFilters.ALL],
        },
        width: 180,
        columnOptions: {
          pinned: 'left',
          lockPosition: 'left',
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'workOrder.name',
        title: this.labels.get('columns.wo.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'operationWorkOrder',
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_WO_BUSINESS_ID,
          action: this.props.actions.fetchEntities,
          filter: [FetchEntitiesFilters.ALL_TASKS],
        },
        width: 170,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return params.data.workOrder
              ? {
                  id: params.data.workOrder.id,
                  label: params.data.workOrder.businessId,
                  type: navigationStates.WORKORDER,
                }
              : null;
          },
        },
      },
      {
        fieldName: 'quantity',
        title: this.labels.get('columns.quantity.title'),
        filterType: filterTypes.NONE,
        // filterName: 'kitTypeBusinessId',  //TODO: L TOOL CAPACITY - handle with Server API
        width: 140,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return getQuantityInfo(data.quantity, data.workOrderQuantity);
          },
        },
      },
      {
        fieldName: 'kitType.businessId',
        title: this.labels.get('columns.kitType.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitTypeBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID,
          action: this.props.actions.fetchEntities,
        },
        width: 170,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'productionLine',
        title: this.labels.get('columns.slot.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT,
        filterName: 'productionLine',
        width: 120,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      ...(isCuringStation(this.props.locationDetails)
        ? [
            {
              fieldName: 'vacuumPort',
              title: this.labels.get('columns.chain.title'),
              filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT,
              filterName: 'vacuumPort',
              columnOptions: {
                cellComponent: LabelWithTooltipCell,
              },
            },
          ]
        : []),
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT,
        filterName: 'operationMaterial',
        width: 180,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material?.name, params.data.material?.businessId);
          },
        },
      },
      ...(PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled
        ? [
            {
              fieldName: 'humanCapacity',
              title: this.labels.get('columns.plannedHumanCapacity.title'),
              width: 230,
              filterName: 'plannedHumanCapacity',
              columnOptions: {
                cellComponent: HumanCapacityCell,
              },
            },
          ]
        : []),
      {
        fieldName: 'plannedTool.name',
        title: this.labels.get('columns.plannedTool.title'),
        width: 260,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'startTime',
        title: this.labels.get('columns.startTime.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.DATE_SELECT,
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterName: 'plannedOrActualStartTime',
        filterLabel: this.filterLabels.get('date.startTime'),
        width: 161,
        isTime: true,
        columnOptions: {
          cellClassRules: {
            'grid-purple-cell': this.calculateStartTimeClass,
          },
          sort: 'asc',
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data ? DateTimeHelper.DateTimeFormat(params.data?.startTime?.epochDateTime * 1000) : '';
          },
          headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
        },
      },
      {
        fieldName: 'endTime',
        title: this.labels.get('columns.endTime.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.DATE_SELECT,
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterName: 'plannedOrActualEndTime',
        filterLabel: this.filterLabels.get('date.endTime'),
        width: 161,
        isTime: true,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            if (params.data?.operationStatus === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')) {
              return params.data ? DateTimeHelper.DateTimeFormat(params.data?.endTime?.epochDateTime * 1000) : '';
            }
            return '';
          },
          headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
        },
      },
      {
        fieldName: 'timeLeft',
        title: this.labels.get('columns.timeLeft.title'),
        filterName: 'timeLeft',
        width: 120,
        columnOptions: {
          lockVisible: true,
          cellClass: 'time-left-cell',
          editable: ({ data }) => data?.operationStatus === getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
          cellEditor: 'timeEditorCell',
          cellRendererParams: {
            updateRowData: this.props.actions.updateRowData,
          },
          cellEditorParams: {
            change: this.props.change,
          },
          cellComponent: TickingTimeCell,
          valueGetter: this.timeLeftValueGetter,
          valueSetter: this.timeLeftValueSetter,
        },
      },
      {
        fieldName: 'executionProgressPercents',
        title: this.labels.get('columns.progress.title'),
        pinned: 'right',
        lockPosition: 'right',
        width: 127,
        columnOptions: {
          sortable: false,
          cellComponent: ProgressCell,
        },
      },
      {
        fieldName: 'tokenValue',
        title: this.labels.get('columns.tokensDetails.title'),
        width: 295,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getFormattedTokenDetails(params.data?.tokenValue);
          },
        },
      },
      {
        fieldName: 'workOrderDescription',
        title: this.labels.get('columns.woDescription.title'),
        width: 220,
        filterName: 'operationWorkOrderDescription',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'completedQuantity',
        title: this.labels.get('columns.completedQuantity.title'),
        width: 180,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return getCompletedQuantityInfo(data.completedQuantity, data.quantity);
          },

        },
      },
      {
        fieldName: 'operationStatus',
        title: this.labels.get('columns.status.title'),
        filterType: isTouchApp ? filterTypes.NONE : filterTypes.MULTI_SELECT,
        filterName: 'operationStatus',
        filterAlignment: filterAlignmentTypes.RIGHT,
        width: 125,
        columnOptions: {
          resizable: false,
          pinned: 'right',
          lockPosition: 'right',
          cellComponent: ({ value }) => {
            const Icon = this.getIcon(value) ?? 'span';
            return (
              <div>
                <Icon color={colorsMap[value]} />
              </div>
            );
          },
        },
      },
      {
        fieldName: 'collapse',
        title: '',
        width: 42,
        columnOptions: {
          lockVisible: true,
          resizable: false,
          cellClass: 'collapse-cell',
          pinned: 'right',
          lockPosition: 'right',
          sortable: false,
          suppressColumnsToolPanel: true,
          cellRenderer: 'agGroupCellRenderer',
          headerComponentParams: {
            headerIcon: 'arrow-icon',
          },
          valueGetter: () => {
            return '';
          },
        },
      },
      {
        fieldName: 'isSync',
        title: '',
        width: 30,
        columnOptions: {
          resizable: false,
          cellClass: 'sync-cell',
          pinned: 'right',
          lockPosition: 'right',
          sortable: false,
          headerValueGetter: GridHelper.headerValueGetter,
          cellComponent: ({ value }) => {
            const icon = value ? TaskSyncedIcon : TaskNotSyncedIcon;
            return (
              <div>
                <img src={icon} />
              </div>
            );
          },
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.sync.tooltip'),
          }
        },
      },
    ];
  };

  timeLeftValueSetter = ({ newValue, data }) => {
    if (newValue) {
      const newRowData = {
        ...data,
        timeLeftDuration: DateTimeHelper.ConvertHoursMinutesToMinutes(newValue.hours, newValue.minutes),
      };
      this.props.actions.updateSingleTaskEndTime(newRowData);
    }
  };

  timeLeftValueGetter = ({ data }) => {
    const operationStatus = data?.operationStatus;
    if (
      operationStatus === getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED') ||
      operationStatus === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')
    ) {
      return {
        hours: 0,
        minutes: 0,
      };
    }

    return {
      ...DateTimeHelper.ConvertMinutesToHoursMinutesObject(Math.abs(data?.timeLeft)),
      isNegativeDuration: data?.timeLeft < 0,
      loading: data?.loading,
    };
  };

  calculateStartTimeClass = ({ data }) => {
    return data?.startTime?.epochDateTime > data?.plannedStartTime?.epochDateTime;
  };

  getIcon(value) {
    if (value) {
      switch (value) {
        case 'SCHEDULED':
          return TaskScheduled;
        case 'STARTED':
          return TaskStarted;
        case 'COMPLETED':
          return TaskCompleted;
        default:
          return null;
      }
    }
    return null;
  }

  onRowGroupOpened = ({ data, node: { expanded } }) => {
    if (isToolRequired(data)) {
      this.props.actions.initDetailRow(data);
    }
  };

  render() {
    return (
      <div className="tasks-grid pl-grid-slim">
        <Grid
          gridName={gridsNames.LOCATION_TASKS}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
          gridProps={{
            gridNameSuffix: this.props.locationDetails?.locationType,
            components: {
              timeEditorCell: TimeEditorCell,
              masterDetailRowComponent: TaskDetailRow,
            },
            onRowGroupOpened: this.onRowGroupOpened,
            detailCellRendererParams: {
              updateRowData: this.props.actions.updateRowData,
              change: this.props.change,
              onOperationBtnClick: this.props.actions.onOpenDialogClick,
            },
            isMasterDetail: true,
            keepDetailRows: true,
            getRowId: (data) => {
              return data?.data?.id;
            },
            detailRowAutoHeight: true,
          }}
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'tasksGrid',
})(TasksGrid);

function HumanCapacityCell({ data }) {
  const { splitAssignments, operationStatus } = data;

  if (splitAssignments?.length) {
    return (
      <div className="human-capacity-cell">
        <HumanCapacityChip {...splitAssignments[0]} />
      </div>
    );
  } else if (operationStatus === 'SCHEDULED') {
    return <div className="human-capacity-cell">{'N/A'}</div>;
  }

  return null;
}
