import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MultiSelectFieldAsync from 'infrastructure/js/components/controls/MultiSelectFieldAsync/multiSelectFieldAsync';
import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../../utils/materialHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';

require('./entitiesMultiSelectFieldAsync.scss');

export default class EntitiesMultiSelectFieldAsync extends React.PureComponent {

  constructor(props) {
    super(props);

    this.hasPreSelectedEntities = !!props.preSelectedEntities && (props.preSelectedEntities.size > 0);
    this.disabled = this.hasPreSelectedEntities && props.disableOnPreselect;
    this.autoloadOnMount = !this.hasPreSelectedEntities || !props.disableOnPreselect; //do not show a loading spinner when hasPreSelectedEntities
    this.entitiesType = props.entitiesType;

    this.controlsLabels = createLabelHelper('mat.controls.');
    //for a workaround
    this.cache = {};
    this.state = {key: 0,
      autoFocus: props.autoFocus,
      // autoFocus: props.autoFocus ? props.autoFocus : undefined,
      currentInput: '',
      currentValues: [],
      results: [],
    };

    this.resultsFetched = false;
    this.handleResultsOnFetchComplete = false;
    this.lastInputChangeTime = 0;
    // this.isScannerInput = false;
  }

  componentDidMount() {
    this.initData(this.props.preSelectedEntities);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //workaround: to clear the Async's cache object and options when the entity type gets changed:
    if (nextProps.entitiesType !== this.props.entitiesType) {
      this.entitiesType = nextProps.entitiesType;
      //1.clear all cached entries in the Async's cache object,
      this.clearCache();
      //2. force the Async new rendering and calling the loadOptions()
      this.setState({
        key: this.state.key === 0 ? 1 : 0,
        currentInput: '',
        currentValues: [],
        results: [],
      });
    }

    if (nextProps.preSelectedEntities !== this.props.preSelectedEntities) {
      this.initData(nextProps.preSelectedEntities);
    }
  }

  clearCache = () => {
    Object.keys(this.cache).forEach(entry => {
      delete this.cache[entry]
    })
  };

  setPreSelectionRedux = ( value) => {
    setTimeout(() => {this.props.change( this.props.name, value) }, 0);
  };

  initData =(preSelectedEntities) => {
    let hasPreSelectedEntities = !!preSelectedEntities && (preSelectedEntities.size > 0);
     if (hasPreSelectedEntities) {
       let optionsData = this.convertEntitiesToOptionsData(preSelectedEntities);
       if (!this.props.isMulti && optionsData && optionsData.length > 0) {
         optionsData = optionsData[0];
       }
       this.setPreSelectionRedux(optionsData );

       this.setState({currentValues: optionsData});
    }
  };

  //1. convert to the object format with {value, label, data} for the MultiSelectField
  //2. remove x (close) from the preselected entities if disableOnPreselect === true
  convertEntitiesToOptionsData = (entities) => {
    let result = [];
    entities.forEach((entity) => {
      result.push({
        value: entity.id,
        label: entity.businessId ? entity.businessId : entity.name,
        data: entity
      });
    });
    return result;
  };


// renderer functions
  getIcon = (option) => {
    switch(option.data.alertStatus) {
      case 'ERROR':
        return 'error-icon error-color';
      case 'WARNING':
        return 'warning-icon warning-color';
    }
    return '';
  };


  getComponentToRender  = (props) => {
    if (!props || !props.data) {
      return null;
    }

    let value = props.data;

    if (value.__isNew__) {
      return (
        <div className='select-row select-value-row'>
          {value.label}
        </div>
      );
    }
    if (value.data) {
      let businessId = value.data.businessId;
      let icon = this.getIcon(value);
      return (
          <div className='select-row select-value-row'>
            <Label className="column" text={businessId !== undefined ? businessId : value.label}/>
            {this.extraDataColumn(value)}
            {this.customDataColumn(value)}
            <span className={'column icon pl pl-' + icon}/>
          </div>
      );
    }
  };


  customDataColumn  = (value) => {
    if (this.props.customValueRenderer) {
      return this.props.customValueRenderer(value);
    }
    return null;
  };


  optionRenderer = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    if (option.promtToRefineFetch) {
      let label = this.controlsLabels.get('combobox.refineSearch');
      return (
        <div className='column refine-more-options'>{label}</div>
      );
    }

    if (option.__isNew__) {
      return (
        <div className='column'>{option.label}</div>
      )
    }

    let businessId = option.data.businessId;
    let icon = this.getIcon(option);
    let location = option.data.locationName ? option.data.locationName : '';
    return (
      <div className="select-row select-option-row">
        <span className={'column icon pl pl-' + icon}/>
        <Label className="column" text={businessId !== undefined ? businessId : option.label}/>
        {this.extraDataColumn(option)}
        <Label className="column" text={location}/>
      </div>
    );
  };

  extraDataColumn  = (value) => {
    let text = '', desc = '';
    if (this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL') ||
        this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL') ||
        this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')) {
      let name = value.data.material?.materialName ||  '';
      let id =   value.data.material?.businessId || '';
      text = MaterialHelper.getMaterialFullLabel(name, id);
    }
    else if (this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      text = value.data.kitType && value.data.kitType.businessId ? value.data.kitType.businessId : '';
    }

    else if (this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) {
      text = value.data.toolType && value.data.toolType.businessId ? value.data.toolType.businessId : '';
    }

    else if (this.entitiesType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')) {
      text = value?.data?.groupType?.businessId || ' ';
    }

    return <Label className='column extra-data' text={text} />;
  };

  getEntityPropertyType = () => {
    switch (this.entitiesType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return EntityPropertyTypes.ROLL_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return EntityPropertyTypes.SPOOL_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
        return EntityPropertyTypes.RESIN_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return EntityPropertyTypes.KIT_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
        return EntityPropertyTypes.PART_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
        return EntityPropertyTypes.KIT_TYPE_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return EntityPropertyTypes.TOOL_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return EntityPropertyTypes.GROUP_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
        return EntityPropertyTypes.WO_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'):
        return EntityPropertyTypes.LOCATION_NAME;
      case getEnumValue(enumTypes.OBJECT_TYPE)('STATION'):
        return EntityPropertyTypes.STATION_NAME;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TAG'):
        return EntityPropertyTypes.TAG_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TASK'):
        return EntityPropertyTypes.SCHEDULER_TASK_NAME;
      case getEnumValue(enumTypes.OBJECT_TYPE)('PICK_LIST'):
        return EntityPropertyTypes.PICK_LIST_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('REPORTED_CUT'):
        return EntityPropertyTypes.REPORTED_CUT;
      case getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT'):
        return EntityPropertyTypes.SHIPMENT_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('BAG'):
        return EntityPropertyTypes.BAG_BUSINESS_ID;
      case getEnumValue(enumTypes.OBJECT_TYPE)('PLY_TYPE'):
        return EntityPropertyTypes.PLY_TYPE_BUSINESS_ID;
      case 'USER_NAME':
        return EntityPropertyTypes.USER_NAME;
    }
    console.error('Unknown entity type: ' + this.entitiesType);
    return null;
  };

  //-------------------------------------------------

  onChangeCallback = (newValue, oldValue) => {
    this.setState({
      currentValues: newValue,
    });
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(newValue, oldValue);
    }
  };

  onInputChangeCallback = (value) => {
    this.setState({
      currentInput: value,
    });
    this.resultsFetched = false;
    this.lastInputChangeTime = new Date().getTime();

    if (this.props.onInputChangeCallback) {
      this.props.onInputChangeCallback(value);
    }
  };

  onKeyDownCallback = (e) => {
    if (e.key === 'Enter' && this.state.currentInput) {

      //------------- FOR DEBUG ONLY
      // let lastEnterKeyClickTime = new Date().getTime();
      // let isScannerInput = (lastEnterKeyClickTime - this.lastInputChangeTime) < 100; // < 100 ms
      // console.log('onKeyDownCallback() delta  resultsFetched', (lastEnterKeyClickTime - this.lastInputChangeTime), this.resultsFetched);
      //------------------------------

      if (!this.resultsFetched) {
        this.handleResultsOnFetchComplete = true;
        e.preventDefault();
        return;
      }

      let exactMatchFound = this.state.results.find((result) => {
        return (result.label.toUpperCase() === this.state.currentInput.toUpperCase());  //should be case insensitive
      });

      let isScannerInput = (new Date().getTime() - this.lastInputChangeTime) < 100; // < 100 ms

      if (isScannerInput && !exactMatchFound) {
      // if (!exactMatchFound) { //in this case it is impossible to select with manual Enter
        e.preventDefault();  //prevent selection
      }
      // if (!isScannerInput && !exactMatchFound && !this.state.results?.length) {
      //   e.preventDefault();
      //   console.log('4. ------------isScannerInput=' + isScannerInput + ' exactMatchFound=' + exactMatchFound, this.state.results?.length)
      // }
    }
  };

  onFetchCompleteCallback = (value) => {
    this.resultsFetched = true;

    this.setState({results: value ? value.options: []});

    if (this.handleResultsOnFetchComplete) {
      this.handleResultsOnFetchComplete = false;
      if (value && value.options) {
        this.handleResults(value.options);
      }
    }
    if (this.props.onFetchCompleteCallback) {
      this.props.onFetchCompleteCallback(value);
    }
  };

  handleResults = (results) => {
    if (results) {
      let exactMatchFound = results.find((result) => {
        return (result.label.toUpperCase() === this.state.currentInput.toUpperCase());    //should be case insensitive
      });

      if (exactMatchFound) {
        let newValue, oldValue;

        if (this.props.isMulti) {
          oldValue = [...this.state.currentValues];
          let isAlreadySelected = oldValue.find((item) => item.value === exactMatchFound.value);
          if (isAlreadySelected) {
            this.setState({key: this.state.key === 0 ? 1 : 0});   //clear the component's input
            return;
          }
          newValue = [...this.state.currentValues, exactMatchFound];
        }
        else {
          oldValue = this.state.currentValues;
          newValue = exactMatchFound;
        }

        this.setPreSelectionRedux(newValue);
        this.setState({key: this.state.key === 0 ? 1 : 0, currentValues: newValue});

        if (this.props.onChangeCallback) {
          this.props.onChangeCallback(newValue, oldValue);
        }
      }
    }
  };

  //to stay in focus during the scanning
  onFocusCallback = () => {
    this.setState({autoFocus: true});
  };

  onBlurCallback = () => {
    this.setState({autoFocus: false});
  };
  //----------------------------------------------------

  render() {
    let {fetchConfig, ...other} = this.props;
    let curConfig = {
      action: fetchConfig.action,
      filter: fetchConfig.filter,
      entityType: this.getEntityPropertyType(),
      searchBy: fetchConfig.searchBy,
    };

    let isMultiValueClearable = ! (this.hasPreSelectedEntities && this.props.disableOnPreselect);

    let singleValueRenderer = (this.props.isMulti === false) ? (this.props.singleValueRenderer ? this.props.singleValueRenderer : this.getComponentToRender) : null;
    let multiValueRenderer = (this.props.isMulti !== false) ? (this.props.multiValueRenderer ? this.props.multiValueRenderer : this.getComponentToRender) : null;


    return (
      <MultiSelectFieldAsync
        cache={this.cache}
        defaultOptions={this.autoloadOnMount}
        singleValueRenderer={singleValueRenderer}
        multiValueRenderer={multiValueRenderer}
        optionRenderer={this.optionRenderer}
        useSimpleInput={false} //should be overridable
        autoFocus={this.state.autoFocus}
        onFocus={this.onFocusCallback}
        onBlur={this.onBlurCallback}
        {...other}
        key={this.state.key}
        className={classNames('entities-multi-select-field', this.props.className)}
        isDisabled={this.disabled || this.props.isDisabled}
        isMultiValueClearable={isMultiValueClearable}
        fetchConfig={curConfig}
        onInputChangeCallback={this.onInputChangeCallback}
        onKeyDownCallback={this.onKeyDownCallback}
        onFetchCompleteCallback={this.onFetchCompleteCallback}
        onChangeCallback={this.onChangeCallback}
      />
    );
  }
}

EntitiesMultiSelectFieldAsync.defaultProps = {
  disableOnPreselect : true,
  disabled : false,
  isMulti: true,
};
EntitiesMultiSelectFieldAsync.propTypes = {
  name: PropTypes.string.isRequired,
  disableOnPreselect : PropTypes.bool,
  fetchConfig: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
};

