import { Map } from 'immutable';
import defaultFormReducer from '../defaultFormReducer';
import { formsNames } from '../../enums/formsNames';

let defaultState = {
  loading: false,
  formData: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    default:
      return defaultFormReducer(state, action, formsNames.UPDATE_KIT_PLIES_FORM, defaultState);
  }
}
