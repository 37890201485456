import { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getValidationMessages } from './formHelper';
import useLabels from 'infrastructure/js/hooks/useLabels';
import ErrorIcon from 'infrastructure/assets/svg/error-triangle.svg';
import WarningIcon from 'infrastructure/assets/svg/warning-triangle.svg';

import './validationMessagesBox.scss';

const ValidationMessagesBox = forwardRef(({ validationMessages }, ref) => {
  const labels = useLabels('mat.form.validationMessage.');

  if (!validationMessages?.errors?.length && !validationMessages?.warnings?.length) {
    return null;
  }

  const { errors, warnings } = getValidationMessages(validationMessages);

  const borderClass = errors?.length ? 'error-border' : 'warning-border';

  return (
    <div className={cn('validation-messages-box', borderClass)} ref={ref}>
      {errors?.length > 0 && (
        <div>
          <div className="validation-messages_title">
            <ErrorIcon className="icon" />
            <span>{labels.get('title.errors','',{count: errors.length})}</span>
          </div>
          {errors?.map((error, index) => (
            <div key={index} className="validation-message_row">
              <span>{error}</span>
            </div>
          ))}
        </div>
      )}
      {warnings?.length > 0 && (
        <div>
          <div className="validation-messages_title">
            <WarningIcon className="icon" />
            <span>{labels.get('title.warnings','',{count: warnings.length})}</span>
          </div>
          {warnings?.map((warning, index) => (
            <div key={index} className="validation-message_row">
              <span>{warning}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

ValidationMessagesBox.displayName = 'ValidationMessagesBox';

ValidationMessagesBox.propTypes = {
  validationMessages: PropTypes.object,
};

export default ValidationMessagesBox;