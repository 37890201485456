import React from 'react';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import {AddRemoveListExt} from '../../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import ToolCategoryOperationListItem from '../../ToolCategoryOperationListItem/toolCategoryOperationListItem';

require('./createToolCategoryDialog.scss');

class CreateToolCategoryDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createToolCategory.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.preSelectedItemsIds = this.itemToEdit?.operations?.map(item => item.id) || [];
  }

  componentDidMount() {
    let initialValues = {operations: [{}]};

    if (this.isEditMode) {
      initialValues = {
        code: this.itemToEdit.businessId,
        operations: this.getInitialOperations(this.itemToEdit.operations)
      };
    }

    this.props.initialize(initialValues);
  }

  getInitialOperations = (operations) => {
    let res = [];
    res = operations?.map?.((item) => {return {operationName: item.id}})
    return res;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    let newData = {
      businessId: data.code,
      operationIds:  data.operations?.map((item) => item.operationName) || [],
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }

    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getAllOperations = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.operations ? dialogData.operations : [];
  };


  getToolCategoryOperationListItem = (itemProps) => {
    return (
      <ToolCategoryOperationListItem
        key={itemProps.id + '-' + itemProps.index}
        {...itemProps}
        labels={this.labels}
      />
    );
  };

  renderOperationSection = () => {
    let allOptions = this.getAllOperations();
    return(
      <div>
        <div className="list-operations-title input-section">
          <label className="column">{this.labels.get('operations')}</label>
        </div>

        <AddRemoveListExt name='operations'
                       id='operations'
                       itemRendererComponent={this.getToolCategoryOperationListItem}
                       itemRendererOptions={allOptions}
                       preSelectedItemsIds={this.preSelectedItemsIds}
                       addButtonLabel={this.labels.get('addOperation')}
                       hideAddAtButton={true}
        />
      </div>
    )
  };

  maxLength100 = Validation.maxLength(100);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-tool-category-dialog"
        className="create-tool-category-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('code') + '*'} htmlFor="code" className="inline left-side no-margin">
          <TextField id="code" name="code" className="short-textfield"
                     validate={[Validation.required, this.maxLength100]}
          />
        </InputSection>

        {this.renderOperationSection()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createToolCategoryDialog',
  }
)(CreateToolCategoryDialog);

CreateToolCategoryDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};



