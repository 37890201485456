import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as cutKitStationActions }     from '../../actions/LocationPage/KittingView/kittingViewActions';
import { jsxActions as kittingGridActions }       from '../../actions/LocationPage/KittingView/kittingGridActions';
import { jsxActions as reportKitDialogActions }   from '../../actions/LocationPage/KittingView/reportKitDialogActions.js';
import { jsxActions as createKitWizardActions }   from '../../actions/LocationPage/KittingView/createKitWizardActions';
import { jsxActions as cancelKitReportDialogActions }   from '../../actions/LocationPage/KittingView/cancelKitReportDialogActions.js';
import { jsxActions as importFilesWizardActions }      from '../../actions/Wizards/ImportFiles/importFilesWizardActions';
import { jsxActions as locationPageActions }      from '../../actions/LocationPage/locationPageActions';
import { jsxActions as fetchEntitiesActions } from '../../actions/FetchEntities/fetchEntitiesActions.js';


import {gridsNames} from '../../enums/gridsNames';
import KittingView from '../../components/LocationPage/KittingView/kittingView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {

      // TODO: Separate Cut and Kit actions.
      cutKitStation:    bindActionCreators(cutKitStationActions, dispatch),
      kittingGridActions:  bindActionCreators(kittingGridActions, dispatch),
      reportKitDialog:  bindActionCreators(reportKitDialogActions, dispatch),
      createKitWizard:  bindActionCreators(createKitWizardActions, dispatch),
      cancelKitReportDialog:  bindActionCreators(cancelKitReportDialogActions, dispatch),
      importFilesWizardActions:      bindActionCreators(importFilesWizardActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
    }
  }
}


let KittingViewContainer = connect(
  (state) => {
    let kittingGrid = state.grid.get(gridsNames.LOCATION_KITTING) ? state.grid.get(gridsNames.LOCATION_KITTING) : null;
  	return {
      sKittingGridData:  kittingGrid,
      sSelectedRowsData: kittingGrid ? kittingGrid.get('selectedRowsData') : List(),
      sSelectedCutToKit: state.cutKitStation.get('selectedCutToKit'),
      sCancelKitReportDialog:  state.cutKitStation.get('cancelKitReportDialog'),
      sKittingViewData:   state.cutKitStation.get('kittingViewData'),
      sReportKitDialog:  state.cutKitStation.get('reportKitDialog'),
      sCreateKitWizard:  state.cutKitStation.get('createKitWizard'),
      sLoggedInUser : state.login.get('loggedInUser'),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),

    };
  },
  mapDispatchToProps
)(KittingView);

export default KittingViewContainer;




