import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as assetPageActions }             from '../../actions/AssetPage/assetPageActions';
import {jsxActions as headerActions}                  from '../../actions/Header/headerActions';
import { jsxActions as activityLogTabGridActions }    from '../../actions/AssetPage/activityLogTabGridActions.js';
import { jsxActions as archiveAssetsDialogActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import { jsxActions as activateAssetsDialogActions }  from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as shelfLifeUpdateDialogActions } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as weightWizardActions } from '../../actions/AssetPage/weightWizardActions';
import { jsxActions as reportMaintenanceDialogActions }      from '../../actions/Dialogs/ReportMaintenanceDialog/reportMaintenanceDialogActions';
import { jsxActions as createAttachmentDialogActions }      from '../../actions/Dialogs/createAttachmentDialog/createAttachmentDialogActions';
import { jsxActions as editAttachmentDialogActions }      from '../../actions/Dialogs/editAttachmentDialog/editAttachmentDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import AssetPage from '../../components/AssetPage/assetPage.js';
import {jsxActions as printRfidTagsActions} from "../../actions/Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions";
import {gridsNames} from "../../enums/gridsNames";



function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header:                       bindActionCreators(headerActions, dispatch),
      assetPageActions:             bindActionCreators(assetPageActions, dispatch),
      activityLogTabGridActions:    bindActionCreators(activityLogTabGridActions, dispatch),
      archiveAssetsDialogActions :  bindActionCreators(archiveAssetsDialogActions, dispatch),
      activateAssetsDialogActions : bindActionCreators(activateAssetsDialogActions, dispatch),
      shelfLifeUpdateDialogActions: bindActionCreators(shelfLifeUpdateDialogActions, dispatch),
      createAttachmentDialogActions: bindActionCreators(createAttachmentDialogActions, dispatch),
      editAttachmentDialogActions: bindActionCreators(editAttachmentDialogActions, dispatch),
      weightWizardActions: bindActionCreators(weightWizardActions, dispatch),
      reportMaintenanceDialogActions: bindActionCreators(reportMaintenanceDialogActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      printRfidTagsActions:   bindActionCreators(printRfidTagsActions, dispatch),
    }
  }
}


let AssetPageContainer = connect(
  (state) => {

  	return {
      selectedTab:                state.assetPage.getIn(['data', 'selectedTab']),
      asset:                      state.assetPage.getIn(['data', 'asset']),
      sArchiveAssetsDialogData:   state.dialogs.get('archiveAssetsDialog'),
      sCreateAttachmentDialogData:   state.dialogs.get('createAttachmentDialog'),
      sEditAttachmentDialogData:   state.dialogs.get('editAttachmentDialog'),
      sActivateAssetsDialogData:  state.dialogs.get('activateAssetsDialog'),
      sShelfLifeUpdateDialogData: state.dialogs.get('shelfLifeUpdateDialog'),
      sEditWeightDialogData: state.assetPage.get('weightWizard'),
      sReportMaintenanceDialogActionsData:  state.dialogs.get('reportMaintenanceDialog'),
      //for ShelfLifeUpdateView Dialog only
      assetDetails:               state.assetPage.getIn(['overviewTab', 'assetDetails']),
      sLoggedInUser : state.login.get('loggedInUser'),
      sPrintRfidTagsDialogData:    state.dialogs.get('printRfidTagsDialog'),
      attachmentTabGrid: state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) ? state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) : null

    };
  },
  mapDispatchToProps
)(AssetPage);

export default AssetPageContainer;



