import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import useLabels from 'infrastructure/js/hooks/useLabels';

import './kitTypePlyTypeListItem.scss';

const ComboboxPortal = withPortal(Combobox);
const EntitiesMultiSelectPortal = withPortal(EntitiesMultiSelect);

export default function KitTypePlyTypeListItem({ index, name, requiredMaterials, actions, change }) {
  const labels = useLabels('mat.administration.matsettings.kittypes.kitTypeForm.');

  const fetchConfig = {
    entityType: EntityPropertyTypes.PLY_TYPE_BUSINESS_ID,
    filter: [FetchEntitiesFilters.ALL],
    action: actions.fetchEntities,
  };

  const isInListOfRequiredMaterials = useCallback(({value}, allValues) => {
    const requiredMaterialsValues = allValues.materials.map(({material}) => material);
    return requiredMaterialsValues.includes(value) ? undefined : labels.get('plyTypesListItem.validation.notInRequiredMaterialsList');
  }, [labels]);

  const isExceedingExpectedPliesNum = useCallback(({value}, {plyTypes, materials, bags}, {touch, submitFailed}, name) => {
    const expectedPliesNum = materials.find(({material}) => material === value)?.quantity;
    let addedPliesNum = 0;

    if(plyTypes?.length > 0){
      addedPliesNum = plyTypes?.filter(({material}) => material?.value === value).length;
    } else {
      addedPliesNum = Object.keys(bags).reduce((acc, key) => {
        return acc + bags[key]?.filter(({material}) => material?.value === value).length;
      }, 0);
    }

    if(expectedPliesNum !== addedPliesNum){
      submitFailed && touch(name);
      return labels.get('validation.numberOfExpectedPliesNotEqualAddedPlies');
    }
  }, [labels]);

  return (
    <div className="kit-type-ply-type-list-item">
      <label id={name + '.[index]'} className="item-index">
        {index + 1}
      </label>

      <EntitiesMultiSelectPortal
        id={name + '.[plyType]'}
        name={name + '.[plyType]'}
        entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('PLY_TYPE')]}
        fetchConfig={fetchConfig}
        change={change}
        showEntitiesTypes={false}
        isMulti={false}
        validate={Validation.required}
        classNames={{
          menuPortal: () => 'plytype-select-menu-portal',
        }}
      />
      <ComboboxPortal
        id={name + '.[material]'}
        name={name + '.[material]'}
        options={requiredMaterials}
        validate={[Validation.required, isInListOfRequiredMaterials, isExceedingExpectedPliesNum]}
        noOptionsRenderer={() => labels.get('plyTypesListItem.materialMenuPlaceholder')}
      />
    </div>
  );
}

KitTypePlyTypeListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array,
};
