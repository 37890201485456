import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { ImportTypes, ImportAcceptFileTypes } from '../../../../enums/importTypes';
import { getEnumValue, enumTypes } from '../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ResinsGrid from './ResinsGrid/resinsGrid';
import {
  getLocationAssetTypes,
  getLocationAssetTypesForPrintPdfLabels, isFreezerLocation,
  isTransitArea
} from '../../../../utils/locationHelper';
import CreateResin from '../Dialogs/CreateResinDialog/createResinDialog';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./resinsView.scss');

export default class ResinsView extends Component {
  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.assets.');
  }

  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getMoveAssetsDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
      ],
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')),
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  ifNotActiveAssetExists = (data) => {
    const isFound =
      isTransitArea &&
      data.some((element) => {
        if (element.assetStatus !== 'ACTIVE') return true;
        return false;
      });
    return isFound;
  };

  ifRejectedExists = (data) => {
    const isFound =
      isTransitArea &&
      data.some((element) => {
        if (element.assetStatus === 'REJECTED') return true;
        return false;
      });
    return isFound;
  };

  getBringAssetsDialogConfig = () => {
    let {sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
      ],
      entitiesTypes: getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')),
    };
  };

  getImportConfig = () => {
    return {
      importTypes: [ImportTypes.IMPORT_RESINS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.onRefreshAll,
      shouldIncludeLocation: false, //import is done from a location , the import file locationId column is optional.
      showPrintLabels: true,
    };
  };

  getPrintPdfLabelsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ALL],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: getLocationAssetTypesForPrintPdfLabels(this.props.sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')),
    };
  };

  getAddToGroupDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      preselectedAssets: sSelectedRowsData,
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')),
    };
  };

  onRefreshAll = () => {
    this.props.actions?.reloadParentComponent();
  };

  getHeaderItems() {
    let { actions, sHasRows, sSelectedRowsData, sLocationDetails, loading } = this.props;
    let dialogConfig = this.getDialogConfig();
    let importConfig = this.getImportConfig();

    let selectedRowsData = this.props.sShipmentsGridData
      ? this.props.sShipmentsGridData.get('selectedRowsData')
      : [];

    let items = {
      title: this.labels.get('title.resins'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.LOCATION_ASSET_RESINS}
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.onRefreshAll,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading,
        },
        {
          id: 'moveTo',
          label: headerItemsLabels.MOVE_TO,
          icon: 'pl pl-move-assets',
          counter: sSelectedRowsData.size,
          action: actions.headerActions.onMenuItemMoveToClick,
          actionData: this.getMoveAssetsDialogConfig(sSelectedRowsData),
          disabled: loading || this.ifNotActiveAssetExists(sSelectedRowsData),
        },
        {
          id: 'bringHere',
          label: headerItemsLabels.BRING_HERE,
          icon: 'pl pl-bring-assets',
          action: actions.headerActions.onMenuItemBringHereClick,
          actionData: this.getBringAssetsDialogConfig(),
          disabled: loading,
        },
      ],

      kebab: {
        disabled: loading,
        menuItems: [
          ...(PermissionManager.hasUpdateShelfLifePermissions()
            ? [
                {
                  id: 'updateShelfLife',
                  label: headerItemsLabels.UPDATE_SELF_LIFE,
                  disabled: !sHasRows,
                  action: actions.headerActions.onUpdateShelfLifeClick,
                  actionData: dialogConfig,
                },
              ]
            : []),

          ...(PermissionManager.hasCreateAssetsPermissions()
            ? [
                {
                  id: 'createResin',
                  label: headerItemsLabels.CREATE_RESIN,
                  action: actions.headerActions.onCreateResinClick
                },
              ]
            : []),

          ...(PermissionManager.hasArchiveAssetsPermissions()
            ? [
                {
                  id: 'archive',
                  label: headerItemsLabels.ARCHIVE,
                  action: actions.headerActions.onMenuItemArchiveClick,
                  actionData: dialogConfig,
                  disabled: !sHasRows,
                },
              ]
            : []),

          {
            id: 'export',
            label: headerItemsLabels.EXPORT_RESINS_LIST,
            action: actions.headerActions.export,
            actionData: { gridName: gridsNames.LOCATION_ASSET_RESINS },
            disabled: !sHasRows,
          },

          ...(PermissionManager.hasGroupAssetPermissions() &&
          PermissionManager.getOrgPreferences().groupsEnabled
            ? [
                {
                  id: 'addToGroup',
                  label: headerItemsLabels.ADD_TO_GROUP,
                  disabled: !sHasRows,
                  action: actions.headerActions.onAddToGroupDialogClick,
                  actionData: this.getAddToGroupDialogConfig(),
                },
              ]
            : []),

          ...(PermissionManager.hasEditAttributesPermissions()
            ? [
                {
                  id: 'editAttributes',
                  label: headerItemsLabels.EDIT_ATTRIBUTES,
                  disabled: !sHasRows,
                  action: actions.headerActions.onMenuItemEditAssetAttributesClick,
                  actionData: dialogConfig,
                },
              ]
            : []),

          ...(PermissionManager.hasImportAssetsPermissions()
            ? [
                {
                  id: 'importResins',
                  label: headerItemsLabels.IMPORT_RESINS,
                  action: actions.headerActions.importItems,
                  actionData: importConfig,
                },
              ]
            : []),

          ...(PermissionManager.hasImportAssetsPermissions()
            ? [
                {
                  id: 'importResinsViaBarcode',
                  label: headerItemsLabels.IMPORT_RESINS_VIA_BARCODE,
                  action: actions.headerActions.onImportAssetsViaBarcodeClick,
                  actionData: { importType: ImportTypes.IMPORT_RESINS },
                },
              ]
            : []),

          ...(PermissionManager.hasEditAttributesPermissions()
            ? [
                {
                  id: 'importAttributesUpdate',
                  label: headerItemsLabels.IMPORT_ATTRIBUTES_UPDATE,
                  action: actions.headerActions.onMenuItemImportAttributesUpdateClick,
                  actionData: { importType: getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_RESIN') },
                },
              ]
            : []),

          {
            id: 'printRfidTags',
            label: headerItemsLabels.PRINT_RFID_TAGS,
            action: actions.headerActions.onMenuItemPrintRfidTagsClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          },

          ...(PermissionManager.getOrgPreferences().printZplGenericAssetEnabled
            ? [
                {
                  id: 'printEmptyTags',
                  label: headerItemsLabels.PRINT_EMPTY_TAGS,
                  action: actions.headerActions.onMenuItemPrintEmptyRfidTagsClick,
                },
              ]
            : []),

          {
            id: 'printPdfLabels',
            label: headerItemsLabels.PRINT_LABEL,
            action: actions.headerActions.onMenuItemPrintLabelClick,
            actionData: this.getPrintPdfLabelsDialogConfig(),
          },
        ],
      },
    };
    return items;
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="resins-view">
        <Header {...headerItems} />

        <ResinsGrid actions={{...this.props.actions.assetsResinsGridActions, ...this.props.actions.fetchEntitiesActions}}
          locationDetails={this.props.sLocationDetails}
          cellAction={this.props.actions.getAssetRelatedAssets}
        />

        <PL_DialogWrapper dialogComponent={CreateResin}
                          show={this.props.sDataCreateResin.get('show')}
                          actions={this.props.actions.createResinActions}
                          sData={this.props.sDataCreateResin}
        />
      </div>
    );
  }
}

ResinsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object.isRequired,
};
