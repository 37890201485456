import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import * as kitTypesService from '../../../services/KitTypes/kitTypesService.js';
import * as partTypesService from '../../../services/PartTypes/partTypesService.js';
import * as projectsService from '../../../services/Projects/projectsService.js';
import * as materialsService from '../../../services/Materials/materialsService.js';
import * as operationsService from '../../../services/Operations/operationsService.js';
import * as toolsService from '../../../services/Tools/toolsService.js';
import * as locationsService from '../../../services/Locations/locationsService.js';
import MaterialHelper from '../../../utils/materialHelper';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import * as additionalFieldsHelper from '../../../components/Common/Helpers/AdditionalFieldsHelper';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';
import ToolTypeHelper, { TOOL_TYPES } from '../../../utils/toolTypeHelper';
import * as formActionsHelper from '../../Forms/formActionsHelper';
import { formsNames } from '../../../enums/formsNames';
import { getResponseValidationDetails } from 'infrastructure/js/components/Form/formHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the formActionsHelper's submit() method
};

export const actionTypes = {
  ...formActionsHelper.getActionTypes(formsNames.CREATE_KIT_TYPE_FORM),
};

const actions = {
  ...formActionsHelper.getActions(formsNames.CREATE_KIT_TYPE_FORM),
};

export let api = {
  ...formActionsHelper.getApiActions(actions, config),

  fetchFormData(dispatch, getState) {
    return function () {
      dispatch(actions.setBusy(true));

      let promise1 = partTypesService.fetchPartTypes();
      let promise2 = projectsService.fetchAllProjects();
      let promise3 = materialsService.fetchAllMaterials();
      let promise4 = operationsService.fetchAllOperations();
      let promise5 = locationsService.fetchActiveLocations();
      let fetchAdditionalFieldsPromise = additionalFieldsService.fetchAdditionalFields({
        entityType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'),
      });

      let promise6 = toolsService.fetchToolTypes();

      return Promise.all([promise1, promise2, promise3, promise4, promise5, fetchAdditionalFieldsPromise, promise6]).then((allResults) => {
        const invalidResponse = allResults.find((response) => {
          return !Network.isResponseValid(response);
        });

        if (invalidResponse) {
          console.error('Failed to get the Kit Type initial data', invalidResponse);
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          dispatch(actions.fetchFormDataReady());
          return { success: false };
        }
        let partTypes = allResults[0].dataList;
        let projects = allResults[1].dataList.map((obj) => {
          return obj;
        });
        let materials = allResults[2].dataList;
        let operations = allResults[3].dataList;
        let locations = allResults[4].dataList;
        let additionalFields = allResults[5].dataList;
        let toolTypes = allResults[6].dataList;

        let formData = {
          partTypes: partTypes.map((obj) => {
            return { value: obj.id, label: obj.businessId, data: obj };
          }),
          projects: projects.map((obj) => {
            return { value: obj.id, label: obj.businessId, data: obj };
          }),
          materials: materials.map((obj) => {
            return { value: obj.id, label: MaterialHelper.getMaterialFullLabel(obj.materialName, obj.businessId), data: obj };
          }),
          operations: operations.map((obj) => {
            return { value: obj.id, label: obj.displayName, data: { ...obj, stations: _getOperationStations(obj.id, locations) } };
          }),
          locations: locations.map((obj) => {
            return { value: obj.id, label: obj.name, data: obj };
          }),
          additionalFields: additionalFields,
          toolTypes: toolTypes.map((obj) => {
            return {
              value: obj.id,
              label: ToolTypeHelper.getToolTypeFullLabel(obj.description, obj.businessId),
              data: obj,
            };
          }),
        };

        let previousToolIdLabel = createLabelHelper('').get('mat.administration.matsettings.dialog.createkittype.operations.toolType.previousToolId');
        formData.toolTypes.push({ value: TOOL_TYPES.PREV_TOOL_ID, label: previousToolIdLabel });

        dispatch(actions.fetchFormDataReady(formData));
        dispatch(actions.setBusy(false));

        return { success: true };
      });
    };
  },

  submit(dispatch, getState) {
    return function (data, isEditMode, isClone, reloadParentComponent) {
      dispatch(actions.setBusy(true));

      let method = isClone ? kitTypesService.cloneKitType : isEditMode ? kitTypesService.editKitType : kitTypesService.createKitType;

      data.additionalFieldsValues = additionalFieldsHelper.convertToAdditionalFields(data['additionalFields'], data.additionalFieldsDefinitions);
      delete data.additionalFields;
      delete data.additionalFieldsDefinitions;

      method(data).then((response) => {
        dispatch(actions.setBusy(false));

        let validations = getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return { success: false };
        }

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit kit type failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return { success: true };
      });
    };
  },

  removeBag(dispatch, getState) {
    return function (cb) {
      const dialogLabels = createLabelHelper('mat.dialog.');
      const title = dialogLabels.get('warning');
      const submitButtonLabel = dialogLabels.get('delete');

      const message = createLabelHelper('mat.administration.matsettings.kittypes.kitTypeForm.').get('removeBagConfirmation')

      let confirmationConfig = {
        title,
        message,
        submitButtonLabel,
        cancelHandler: messageDialogApi.close(dispatch, getState),
        submitHandler: () => {
          messageDialogApi.close(dispatch, getState)();
          cb();
        },
      };

      messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
    };
  },
};

export let jsxActions = {
  ...formActionsHelper.getJsxActions(api),

  submit(data, isEditMode, isClone, reloadParentComponent) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, isEditMode, isClone, reloadParentComponent);
    };
  },

  removeBag(cb) {
    return function (dispatch, getState) {
      return api.removeBag(dispatch, getState)(cb);
    };
  }
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _getOperationStations(operationId, locations = []) {
  return locations
    .filter(({ operations }) => operations?.some(({ id }) => id === operationId))
    .map((location) => ({ value: location.id, label: location.name, data: location }));
}
