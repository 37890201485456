import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Nav } from 'react-bootstrap';
import cn from 'classnames';
import useResizeObserver from 'infrastructure/js/hooks/useResizeObserver';
import RightArrow from '../../../assets/svg/tab-arrow-right.svg';
import LeftArrow from '../../../assets/svg/tab-arrow-left.svg';

import './tabs.scss';

// #  https://react-bootstrap.github.io/components.html#tabs
export function getClassName(tabType) {
  switch (tabType) {
    case 'hidden':
      return 'pl-hidden-tabs';
    case 'topbar':
    case 'page':
      return 'pl-topbar-tabs';
    case 'content':
      return 'pl-content-tabs';
    case 'wizard-sidebar':
      return 'pl-wizard-sidebar-tabs';
    case 'wizard-horizontal':
      return 'pl-wizard-horizontal-tabs';
    case 'form':
      return 'pl-form-tabs';
    case 'modal':
    case 'default':
    default:
      return 'pl-tabs';
  }
}

export default function PL_Tabs({ className, tabType, mountOnEnter, unmountOnExit, mountOnlyActiveTab, children, ...rest }) {
  if (mountOnlyActiveTab) {
    mountOnEnter = true;
    unmountOnExit = true;
  }

  return (
    <Tabs {...rest} animation={false} className={cn(getClassName(tabType), className)} mountOnEnter={mountOnEnter} unmountOnExit={unmountOnExit}>
      {children}
    </Tabs>
  );
}

PL_Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  tabType: PropTypes.oneOf([
    'topbar',
    'page',
    'content',
    'modal',
    'default',
    'wizard-sidebar',
    'wizard-horizontal',
    'form',
    'hidden',
    undefined,
    null,
  ]),
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  activeKey: PropTypes.any,
  id: PropTypes.any.isRequired,
  onSelect: PropTypes.func, //(key: any, event: SyntheticEvent) => void; called on tab select
  mountOnlyActiveTab: PropTypes.bool,
};

PL_Tabs.defaultProps = {
  className: '',
  children: [],
  tabType: null,
  mountOnEnter: false,
  unmountOnExit: false,
  activeKey: undefined,
  onSelect: () => {},
  mountOnlyActiveTab: false,
};

PL_Tabs.Tab = Tab;

export function PL_CustomTabs({ tabs, id, className, animation = false, tabType, allowOverflow = false, buttons, ...cleanProps }) {
  const [isOverflow, setIsOverflow] = useState(false);
  const tabsRef = useRef(null);

  const onResize = useCallback((element) => {
    const { clientWidth = 0, scrollWidth = 0 } = element?.target || {};
    if (clientWidth < scrollWidth) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resizeObserver = useResizeObserver(onResize);

  if (!tabs?.length) return null;

  const { links, panes } = tabs.reduce(
    (acc, tab, i) => {
      acc.links.push(
        <Nav.Item as="li" role="presentation" key={tab?.eventKey || `${id}-${i}`}>
          <Nav.Link as="button" eventKey={tab?.eventKey || i}>
            <div>
              <span>{tab.title}</span>
            </div>
          </Nav.Link>
        </Nav.Item>
      );
      acc.panes.push(
        <Tab.Pane key={tab?.eventKey || `${id}-tab-${i}`} eventKey={tab?.eventKey || i}>
          {tab.pane}
        </Tab.Pane>
      );
      return acc;
    },
    { links: [], panes: [] }
  );

  const scrollTabs = (direction) => {
    tabsRef.current.scrollBy({ left: direction * 100, behavior: 'smooth' });
  };

  const tabsRefCallback = (node) => {
    tabsRef.current = node;
    resizeObserver(node);
  }

  return (
    <div className={cn('pl-tabs-container', className, { 'tabs-overflow': allowOverflow, 'overflow-active': isOverflow })}>
      <Tab.Container {...cleanProps} id={id} >
        <div className="pl-tabs-header">
          <Nav className={getClassName(tabType) + ' nav-tabs'} as="ul" ref={tabsRefCallback}>
            {links}
          </Nav>
          <div className="pl-tabs-buttons">
            {isOverflow && allowOverflow && (
              <div className="tabs-scroll-buttons">
                <button onClick={() => scrollTabs(-1)}>
                  <LeftArrow className="scroll-button-icon" stroke="#782753" />
                </button>
                <button onClick={() => scrollTabs(1)}>
                  <RightArrow className="scroll-button-icon" stroke="#782753" />
                </button>
              </div>
            )}
            {buttons}
          </div>
        </div>
        <Tab.Content animation={animation}>{panes}</Tab.Content>
      </Tab.Container>
    </div>
  );
}
