import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import OperationsViewGrid from './OperationsViewGrid/operationsViewGrid';
import {MatSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import CreateOperationDialog from './Dialogs/CreateOperationDialog/createOperationDialog';

export default class OperationsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.operations.view.');
  }

  componentDidMount() {
    this.props.actions.init(false);
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_OPERATIONS);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateOperationClick,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditOperationClick,
          actionData: selectedRowsData.get(0)
        }
      ],

      kebab: {
        disabled: !hasRows,
        menuItems: [
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveOperationsClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData: selectedRowsData
          }
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();

    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <OperationsViewGrid actions={{...this.props.actions.operationsViewGridActions, ...this.props.actions.fetchEntitiesActions }}/>

        <PL_DialogWrapper dialogComponent={CreateOperationDialog}
                          show={this.props.sCreateOperationDialogData.get('show')}
                          actions={this.props.actions.createOperationDialogActions}
                          sData={this.props.sCreateOperationDialogData}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

OperationsView.propTypes = {
  sGrid: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

