import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import CreateToolTypeDialog from './Dialogs/CreateToolTypeDialog/createToolTypeDialog';
import ToolTypesViewGrid from './ToolTypesViewGrid/ToolTypesViewGrid';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import {MatSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {gridsNames} from '../../../../../enums/gridsNames.js';
import ImportToolTypesDialog from './Dialogs/ImportToolTypesDialog/importToolTypesDialog';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../../enums/importTypes';

export default class ToolTypesView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.tooltypes.view.');
  }

  componentDidMount() {
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_TYPES);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let items =  {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        ...(PermissionManager.hasCreateOrEditToolTypesPermissions() ? [ //Note: show the buttons only for PS users
            {
              id:'create',
              label: headerItemsLabels.CREATE,
              icon: 'pl pl-icon-add',
              action: actions.header.onCreateToolTypeClick,
            },
            {
              id:'edit',
              label: headerItemsLabels.EDIT,
              icon: 'pl pl-edit-icon',
              disabled: !(hasRows && selectedRowsData.size === 1),
              action: actions.header.onEditToolTypeClick,
              actionData: selectedRowsData.get(0)
            },
          ] : []),
        ],
      kebab: {
        menuItems: [
          {
            id:'importToolTypes',
            label: headerItemsLabels.IMPORT_TOOL_TYPES,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_TOOL_TYPES],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id:'importToolTypesJson',
            label: headerItemsLabels.IMPORT_TOOL_TYPES_JSON,
            action: actions.header.onMenuItemImportToolTypesJsonClick,
            disabled: false,
            actionData: {parentComponent: actions.reload}
          },
          {
            id:'exportToolTypes',
            label: headerItemsLabels.EXPORT_TOOL_TYPES,
            action: actions.header.exportItems,
            disabled: !hasRows,
            actionData: {gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES}
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveToolTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData:  selectedRowsData,
          },
        ]
      }
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <ToolTypesViewGrid actions={{...this.props.actions.toolTypesViewGridActions, ...this.props.actions.fetchEntitiesActions}} />

        <PL_DialogWrapper dialogComponent={CreateToolTypeDialog}
                          show={this.props.sCreateToolTypeDialogData.get('show')}
                          actions={this.props.actions.createToolTypeDialogActions}
                          sData={this.props.sCreateToolTypeDialogData}
                          reloadParentComponent={this.reloadPage}
        />

        <PL_DialogWrapper dialogComponent={ImportToolTypesDialog}
                          show={this.props.sImportToolTypesDialogData.get('show')}
                          actions={this.props.actions.importToolTypesDialogActions}
                          sData={this.props.sImportToolTypesDialogData}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}



