import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import MaterialHelper from '../../../utils/materialHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue, getLookupOptions } from '../../../utils/enumHelper';
import { DateHelper } from '@bryntum/scheduler';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import { getPatternStyle } from 'infrastructure/js/components/DynamicIcons/TaskStarted';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper';
import { isArray } from 'lodash';

let moment = require('moment');

export const schedulerFormat = 'YYYY-MM-DD HH:mm:ss';

export function convertToSchedulerData(rawData) {
  const customColors = getSystemColors();

  let data = {
    resources: [],
    events: [],
    dependencies: [],
    shifts: [],
    timeDefinition: {},
    timeRanges: [],
    totalUtl: 0,
    metrics: [],
  };
  if (!rawData) {
    return data;
  }

  // rawData.shifts = rawData.shifts.slice(0, 2);   //TODO: L a new API should return the shifts config instead of calculated shifts

  data.totalUtl = rawData.totalUtl;

  //resources
  data.resources = _convertResources(rawData.resources);

  //events
  data.events = [];

  for (let i = 0; i < rawData.events.length; i++) {
    const rawEvent = rawData.events[i];
    if (!rawEvent.resource) {
      continue;
    }

    let {
      startDate,
      endDate,
      plannedStartDisplayDate,
      plannedEndDisplayDate,
      actualStartDisplayDate,
      actualEndDisplayDate,
      estimatedEndDisplayDate,
      estimatedStartDisplayDate,
    } = _getDates(rawEvent, schedulerFormat);

    data.events.push({
      id: rawEvent.id,
      resourceId: rawEvent.resource?.id,
      isSectionEvent: rawEvent.resource.section,
      plannedResource: rawEvent.plannedResource,
      actualResource: rawEvent.actualResource,
      name: _getFormattedEventName(rawEvent),
      startDate,
      endDate,
      productionLine: rawEvent.productionLine,
      plannedStartDate: rawEvent.plannedStartDate,
      plannedStartDisplayDate,
      plannedEndDate: rawEvent.plannedEndDate,
      plannedEndDisplayDate,
      actualStartDisplayDate,
      actualEndDisplayDate,
      estimatedEndDisplayDate,
      estimatedStartDisplayDate,
      wo: rawEvent.workOrder,
      quantity: rawEvent.quantity,
      completedQuantity: rawEvent.completedQuantity,
      workOrderQuantity: rawEvent.workOrderQuantity,
      toolCapacity: rawEvent.toolCapacity,
      locked: rawEvent.locked,
      split: rawEvent.split,
      kitType: rawEvent.kitType,
      taskDuration: rawEvent.plannedDuration,
      actualDuration: rawEvent.actualDuration,
      valid: rawEvent.valid,
      materialName: rawEvent.material ? MaterialHelper.getMaterialFullLabel(rawEvent.material.name, rawEvent.businessId) : '',
      validationMessages: rawEvent.validationMessages,
      isLockedForResource: _getIsLockedForResource(rawEvent),
      eventIcon: _getEventIcons(rawEvent),
      cls: _getEventCSSClass(rawEvent),
      style: _getEventCustomStyle(rawEvent, customColors),
      operationStatus: rawEvent.status,
      operation: rawEvent.operation,
      priority: rawEvent.priority ? getLookupOptions(enumTypes.PRIORITY_TYPE).find((x) => x.value === rawEvent.priority).label : '',
      recipe: rawEvent.groupName ? rawEvent.groupName : '',
      vacuumPort: rawEvent.vacuumPort,
      plannedTool: rawEvent.plannedTool,
      actualTool: rawEvent.actualTool,
      compatibleToolType: rawEvent.compatibleToolType,
      splitAssignments: rawEvent.splitAssignments,
      mainTaskId: rawEvent.mainTaskId,
      tokenValue: rawEvent.tokenValue,
      plannedSetupTimeMinutes: rawEvent.plannedSetupTimeMinutes,
      plannedSetupTimePercent: rawEvent.plannedSetupTimePercent,
      setupLead: rawEvent.setupLead,
    });
  }

  //dependencies
  data.dependencies = rawData.dependencies.map(({ id, fromId, toId, workOrderId }) => {
    return {
      id,
      from: fromId,
      to: toId,
      relatedWos: [workOrderId],
    };
  });

  //resourceTimeRanges
  data.resourceTimeRanges = [];
  let index = 0;
  rawData.shifts.map((shift) => {
    let startDate = DateTimeHelper.ConvertToDate(shift.startDate).format(schedulerFormat);
    let endDate = DateTimeHelper.ConvertToDate(shift.endDate).format(schedulerFormat);

    if (!shift.resourceIds && !shift.resourceIds.length) {
      return;
    }

    rawData.resources.map((resource) => {
      let matchedResource = shift.resourceIds ? shift.resourceIds.find((r) => r === resource.id) : null;
      if (!matchedResource) {
        return;
      }
      data.resourceTimeRanges.push({
        id: index++,
        resourceId: resource.id,
        startDate: startDate,
        endDate: endDate,
        name: '', //shift.name,
        timeRangeColor: '',
        cls: 'pl-scheduler-shift',
      });
    });
  });

  //timeDefinition
  if (!rawData.timeDefinition) {
    console.error('scheduler: timeDefinition is not defined');
    data.timeDefinition = {
      startDate: new Date().toString(),
      endDate: new Date().toString(),
    };
  } else {
    data.timeDefinition = {
      startDate: DateTimeHelper.ConvertToDate(rawData.timeDefinition.startTime).format('YYYY-MM-DD'),
      endDate: DateTimeHelper.ConvertToDate(rawData.timeDefinition.endTime).format('YYYY-MM-DD'),
    };
  }

  data.metrics = _convertToKPIsData(rawData.metrics);

  return data;
}

function _getFormattedEventName(rawEvent) {
  const tileNameOrgPreference = PermissionManager.getOrgPreferences().schedulerTaskTileName;
  const { workOrder, kitType, name: eventName } = rawEvent;

  if (!workOrder || !tileNameOrgPreference) {
    //non operational tasks dont have a workorder
    return eventName;
  }

  switch (tileNameOrgPreference) {
    case 'work_order':
      return eventName;
    case 'work_order_description':{
      const description = workOrder.description ? workOrder.description + '-' : '';
      return eventName.replace(workOrder.businessId + '-', description);
    }
    case 'kit_type':
      return eventName.replace(workOrder.businessId, kitType.businessId ?? '');
    case 'kit_type_description':{
      const description = kitType.description ? kitType.description + '-' : '';
      return eventName.replace(workOrder.businessId + '-', description);
    }
    default:
      return eventName;
  }
}

const weekDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export function getWeekStartDayIndex() {
  let day = PermissionManager.getOrgPreferences().startDayOfWeek;
  if (day) {
    let index = weekDays.indexOf(day);
    return index >= 0 && index <= 6 ? index : 0;
  }
  return 0;
}

export function getWeekHorizonStart() {
  return PermissionManager.getOrgPreferences().schedulerStartHorizonWeek ?? 'today';
}

export function convertToServerData(events, forceStartDate) {
  let data = { events: [] };
  if (!events) {
    return data;
  }

  let dFormat = DateTimeHelper.getDateFormat();
  let orgLocale = DateTimeHelper.getUserOrgLocale();
  data.events = events.map((event) => {
    let startDate = moment(forceStartDate ?? event.startDate, dFormat, orgLocale, true).utcOffset(0, true);
    let endDate = moment(event.endDate, dFormat, orgLocale, true).utcOffset(0, true);

    return {
      id: event.id,
      resourceId: event.resource,
      startDate: DateTimeHelper.ConvertFromDate(startDate),
      endDate: DateTimeHelper.ConvertFromDate(endDate),
    };
  });

  return data;
}

export function getEarliestEventDate(data) {
  if (!data || data.length === 0) {
    return null;
  }

  let events = data.filter((e) => {
    return e.resource && e.startDate && e.startDate.epochDateTime && e.status !== getEnumValue(enumTypes.TASK_STATUS)('UNASSIGNED');
  });
  if (events?.length > 0) {
    let epochs = events.map((ev) => ev.startDate.epochDateTime);
    let earliest = Math.min(...epochs);
    let earliestDate = moment(DateTimeHelper.ConvertToDate({ epochDateTime: earliest }).format(schedulerFormat));

    //note: returns utc Date
    return earliestDate.toDate();
  }
  return null;
}

export function getColorInformation(data) {
  if (isArray(data)) {
    return data.map(({ value, defaultValue }) => ({ color: value, defaultColor: defaultValue }));
  }
  if (typeof data === 'object') {
    const { value, defaultValue } = data;
    return { color: value, defaultColor: defaultValue };
  }

  return {};
}

/**
 * Returns a formatted string representing the start and end dates of the event.
 *
 * @param {object} event The event object.
 * @returns {object} An Object of formatted strings representing the dates of the event.
 */
export const getEventFormattedDates = (event) => {
  return _getDates(event, schedulerFormat);
};

/**
 * Returns a formatted string representing the duration.
 *
 * @param {number} duration The duration in minutes.
 * @returns {string} A formatted string representing the duration.
 */
export const getFormattedDuration = (duration) => {
  // Check if the duration is valid.
  if (!duration || isNaN(duration)) {
    return '';
  }
  // Return a formatted string representing the duration.
  return DateTimeHelper.ConvertMinutesToHoursMinutes(duration);
};

export function getQuantityInfo(value1, value2) {
  let value = value2 || value2 === 0 ? ` (${value2})` : '';
  return `${value1 ?? ''}${value}`;
}

export function getCompletedQuantityInfo(value1, value2) {
  if (value1 || value1 === 0) {
    let ratio = PermissionManager.getOrgPreferences().showIncompleteTaskRatio;
    if (ratio) {
      return value2 ? `${Math.round((value1 * 100) / value2)}%` : '0%';
    }
    return value2 ? `${value1} of ${value2}` : `${value1} of 0`;
  }
  return '';
}

export const getFormattedTokenDetails = (value) => {
  if (!value?.partsTokens && !value?.toolTokens && !value?.stationTokens) {
    return '';
  }
  return `${value?.partsTokens || '--'} / ${value?.toolTokens || '--'} / ${value?.stationTokens || '--'}`;
};

export const getFormattedSetupTime = (plannedSetupTimeMinutes, plannedSetupTimePercent) => {
  if (plannedSetupTimeMinutes) {
    const setupTime = DateTimeHelper.ConvertMinutesToHoursMinutes(plannedSetupTimeMinutes);
    return `${setupTime} (${plannedSetupTimePercent}%)`;
  }
  return '';
};

//////////////////////////////////////////////////////////////////////

function _getEventIcons(rawEvent) {
  let icon = '';
  if (rawEvent?.split) {
    icon = 'pl pl-split-task-icon';
  }
  if (!rawEvent?.valid) {
    icon = rawEvent.warningSeverity === 'HIGH' ? 'pl pl-error-icon' : rawEvent.warningSeverity === 'MEDIUM' ? 'pl pl-warning-icon' : '';
  }
  return icon;
}

function _getEventCSSClass(rawEvent) {
  const { filtered, status, taskType, locked } = rawEvent;
  let cssClass = '';

  if (taskType === 'NON_OPERATIONAL') {
    cssClass = 'event-non-operational';
  } else if (status === getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')) {
    cssClass = 'event-scheduled';
  } else if (status === getEnumValue(enumTypes.TASK_STATUS)('STARTED')) {
    cssClass = 'event-started';
  } else if (status === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')) {
    cssClass = 'event-completed';
  }

  if (locked) {
    cssClass += ' event-locked';
  }

  return `scheduler-event ${cssClass} `;
}

const _getEventCustomStyle = (event, colors) => {
  const { status, taskType } = event;

  if (taskType === 'NON_OPERATIONAL') {
    return `background-color:${colors.maintenance}`;
  }

  if (status === getEnumValue(enumTypes.TASK_STATUS)('STARTED')) {
    const { css } = getPatternStyle(colors.started);
    return css;
  }
  if (status === getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')) {
    return `background-color:${colors.scheduled}`;
  }
  if (status === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')) {
    return `background-color:${colors.completed}`;
  }
  return '';
};

function _convertToKPIsData(metrics) {
  let weeks = [];

  let labels = createLabelHelper('');

  metrics?.weeks?.forEach((w, index) => {
    let weekNumber = _getWeekNumber(new Date(w.startingDay));
    let kpis =
      w.kpis?.map((kpi) => {
        return {
          leftData: kpi.value + '%',
          leftDataClassName: kpi.value <= 60 ? 'red' : kpi.value <= 80 ? 'orange' : 'green',
          rightData: labels.get(kpi.labelKey, kpi.name),
          rightDataClassName: '',
        };
      }) || [];
    weeks[weekNumber] = kpis;
  });
  return {
    days: [],
    threeDays: [],
    weeks: weeks,
    twoWeeks: [],
  };
}

function _getWeekNumber(date) {
  if (date && !isNaN(date.getTime())) {
    //if Date is valid
    let [, weekNumber] = DateHelper.getWeekNumber(date, getWeekStartDayIndex());
    return weekNumber;
  }
  console.error('SchedulerHelper._getWeekNumber(): date = ', date);
  return 0;
}

function _getDates(rawEvent, schedulerFormat) {
  let startDate =
    rawEvent.startDate && rawEvent.startDate.epochDateTime ? DateTimeHelper.ConvertToDate(rawEvent.startDate).format(schedulerFormat) : '';
  let endDate = rawEvent.endDate && rawEvent.endDate.epochDateTime ? DateTimeHelper.ConvertToDate(rawEvent.endDate).format(schedulerFormat) : '';
  let plannedStartDisplayDate =
    rawEvent.plannedStartDate && rawEvent.plannedStartDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.plannedStartDate).format(DateTimeHelper.getDateTimeFormat())
      : '';
  let plannedEndDisplayDate =
    rawEvent.plannedEndDate && rawEvent.plannedEndDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.plannedEndDate).format(DateTimeHelper.getDateTimeFormat())
      : '';
  let actualStartDisplayDate =
    rawEvent.actualStartDate && rawEvent.actualStartDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.actualStartDate).format(DateTimeHelper.getDateTimeFormat())
      : '';
  let actualEndDisplayDate =
    rawEvent.actualEndDate && rawEvent.actualEndDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.actualEndDate).format(DateTimeHelper.getDateTimeFormat())
      : '';
  let estimatedEndDisplayDate =
    rawEvent.estimatedEndDate && rawEvent.estimatedEndDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.estimatedEndDate).format(DateTimeHelper.getDateTimeFormat())
      : '';
  let estimatedStartDisplayDate =
    rawEvent.estimatedStartDate && rawEvent.estimatedStartDate.epochDateTime
      ? DateTimeHelper.ConvertToDate(rawEvent.estimatedStartDate).format(DateTimeHelper.getDateTimeFormat())
      : '';

  return {
    startDate,
    endDate,
    plannedStartDisplayDate,
    plannedEndDisplayDate,
    actualStartDisplayDate,
    actualEndDisplayDate,
    estimatedEndDisplayDate,
    estimatedStartDisplayDate,
  };
}

function _getIsLockedForResource(rawEvent) {
  return rawEvent.taskType === 'NON_OPERATIONAL';
}

function _convertResources(resources) {
  let labels = createLabelHelper('');
  resources.forEach((resource) => {
    resource.utl = `${resource.utl.toString()}%`;
    if (resource.validationWarning?.id) {
      resource.validationWarning = labels.get(resource?.validationWarning?.id || '', '');
    }

    // remove parentId if it does not exist in the resources array
    if (!resources.some((r) => r.id === resource.parentId)) {
      resource.parentId = null;
    }
  });

  return resources;
}
