import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import { api as sideNavigatorApi} from '../../SideNavigator/sideNavigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import { api as assetTypeGridApi} from './assetTypesViewGridActions';
import { api as bagsGridApi} from './bagsViewGridActions.js';
import { api as materialTypeGridApi} from './materialTypesViewGridActions';
import { api as toolCategoryGridApi} from './toolCategoriesViewGridActions';
import { api as toolTypeGridApi} from './toolTypesViewGridActions';
import { api as toolGroupGridApi} from './toolGroupsViewGridActions';
import { api as kitTypeGridApi} from './kitTypesViewGridActions';
import { api as partTypeGridApi} from './partTypesViewGridActions';
import { api as plyTypeGridApi} from './plyTypesViewGridActions.js';
import { api as groupTypeGridApi} from './groupTypesViewGridActions';
import { api as projectTypeGridApi} from './projectsViewGridActions';
import { api as shelfLifeGridApi} from './shelfLifeUpdateViewGridActions';
import { api as operationGridApi} from './operationsViewGridActions';
import { api as shiftGridApi} from './shiftTypesViewGridActions';

import * as assetTypesService from '../../../services/AssetTypes/assetTypesService';
import * as bagsService from '../../../services/Bags/bagsService.js';
import * as materialsService from '../../../services/Materials/materialsService';
import * as toolsService from '../../../services/Tools/toolsService';
import * as kitTypesService from '../../../services/KitTypes/kitTypesService';
import * as partTypesService from '../../../services/PartTypes/partTypesService';
import * as plyTypesService from '../../../services/PlyTypes/plyTypesService.js';
import * as projectsService from '../../../services/Projects/projectsService';
import * as locationsService from '../../../services/Locations/locationsService';
import * as reasonTypesService from '../../../services/ReasonTypes/reasonTypesService';
import * as operationsService from '../../../services/Operations/operationsService';
import * as shiftsService from '../../../services/Shifts/shiftsService';
import * as groupsService from '../../../services/Groups/groupsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_MAT_SETTINGS_SELECTED_MENU_ITEM_CHANGED : 'ADMINISTRATION_MAT_SETTINGS_SELECTED_MENU_ITEM_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setSelectedMenuItemId: function(payload) {
    return {type: actionTypes.ADMINISTRATION_MAT_SETTINGS_SELECTED_MENU_ITEM_CHANGED , payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(loadFirstTime) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(loadFirstTime);
  }
};

jsxActions.onSelectedMenuItemChanged  = function (selectedItemId) {
  return function(dispatch, getState) {
    dispatch(actions.setSelectedMenuItemId(selectedItemId));
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(loadFirstTime) {
    navigatorApi.setLoading(loadFirstTime)(dispatch, getState);

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('matsettings'),
      type: navigationStates.MAT_SETTINGS,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);
    navigatorApi.setLoading(false)(dispatch, getState);
  }
};

api.removeAssetType = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(assetTypesService.removeAssetType, data, assetTypeGridApi);
  }
};

api.removeBags = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(bagsService.deleteBags, data, bagsGridApi);
  }
};

api.removeMaterialTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(materialsService.removeMaterials, data, materialTypeGridApi);
  }
};

api.removeToolCategories = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(toolsService.removeToolCategories, data, toolCategoryGridApi);
  }
};

api.removeToolTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(toolsService.removeToolTypes, data, toolTypeGridApi);
  }
};

api.removeToolGroups = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(toolsService.removeToolGroups, data, toolGroupGridApi);
  }
};

api.removeKitTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(kitTypesService.removeKitTypes, data, kitTypeGridApi);
  }
};

api.removePartTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(partTypesService.removePartTypes, data, partTypeGridApi);
  }
};

api.removePlyTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(plyTypesService.deletePlyTypes, data, plyTypeGridApi);
  }
};

//-----------------------
api.removeGroupTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(groupsService.removeGroupTypes, data, groupTypeGridApi);
  }
};
//-----------------------

api.removeShiftTypes = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(shiftsService.removeShiftTypes, data, shiftGridApi);
  }
};

api.removeProjects = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(projectsService.removeProjects, data, projectTypeGridApi);
  }
};

api.removeOperation = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(operationsService.removeOperation, data, operationGridApi);
  }
};

api.removeReason = function(dispatch, getState) {
  return function(data) {
    _executeDeletion(dispatch, getState)(reasonTypesService.removeReason, data, shelfLifeGridApi);
  }
};




/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

  function _executeActivation (dispatch, getState) {
    return function(method, data, gridApi) {
      let ids = [];
      let businessIds = [];

      data.forEach((item) => {
        ids.push(item.id);
        let displayName = _getItemDisplayName(item);
        businessIds.push(displayName);
      });

      method({ids}).then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error(`${method.name}() failed`);
          messageDialogApi.showError(dispatch, getState)('mat.administration.matsettings.error.activateItem', {businessId: businessIds[0]});
          return;
        }

        messageDialogApi.showSuccess(dispatch, getState)('mat.administration.matsettings.success.activateItem', {businessId: businessIds[0]});
        gridApi.reload(dispatch, getState)();

        navigatorApi.fetchLocations(dispatch, getState)();
      });
    }
  }

  function _executeDeactivation (dispatch, getState) {
    return function(method, data, gridApi) {
      let ids = [];
      let businessIds = [];

      data.forEach((item) => {
        ids.push(item.id);
        let displayName = _getItemDisplayName(item);
        businessIds.push(displayName);
      });

      method({ids}).then((response) => {

        if (!Network.isResponseValid(response) ||
            (response.data && response.data.jobStatus !== 'COMPLETED') ) {
          console.error(`${method.name}() failed`);
          messageDialogApi.showError(dispatch, getState)('mat.administration.matsettings.error.deactivateItem', {businessId: businessIds[0]});
          return;
        }

        messageDialogApi.showSuccess(dispatch, getState)('mat.administration.matsettings.success.deactivateItem', {businessId: businessIds[0]});
        gridApi.reload(dispatch, getState)();

        navigatorApi.fetchLocations(dispatch, getState)();
      });
    }
  }

  function _getItemDisplayName(item) {
    return item.displayName ? item.displayName : item.businessId ? item.businessId : item.id;
  }


  function _executeDeletion (dispatch, getState) {
    return function(method, data, gridApi) {
      let ids = [];
      data.forEach((item) => {
        return ids.push(item.id);
      });

      return method({ids}).then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error(`_executeDeletion(): ${method.name}() failed`);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if(response.dataList){
          let errors = response.dataList.filter(data => data.applicationResponseStatus.statusType === 'ERROR');
          if(errors.length > 0) {
            let labels = createLabelHelper('mat.administration.matsettings.error.');
            let names = errors.map(e => e.data.name);
            let children = names.join(', ');
            let title = `${labels.get('deleteItemRelatedAssets')} ${children}`;
            let type = 'warning';
            let className = 'oneBackground';

            let messageDialogDescriptor = {title, type, className};
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
          else {
            let labels = createLabelHelper('mat.administration.matsettings.');
            let type = 'success';
            let className = 'oneBackground';
            let count = response.dataList.length;
            let messageDialogDescriptor = {title: labels.get('success.deleteItem', 'xxx', { count }), type, className};
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
        }

        gridApi.reload(dispatch, getState)();

        return {success: true};
      });
    }
  }

function _executeMethodWithComplexData (dispatch, getState) {

  return function(method, data , gridApi) {
    method(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error(`${method.name}() failed`);
        return;
      }
      gridApi.reload(dispatch, getState)();
    });
  }
}

