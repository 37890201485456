import * as schedulerService from '../../services/Scheduler/schedulerService';
import Network from 'infrastructure/js/modules/network';
import * as schedulerHelper from '../../components/Common/Helpers/SchedulerHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import * as tasksService from '../../services/Tasks/tasksService';
import * as boHelper from '../Dialogs/batchOperationActionsHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import { api as systemApi } from '../System/systemActions.js';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';
import { api as commonSchedulerApi } from './commonSchedulerActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { navigationStates } from '../../enums/navigationStates';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('SCHEDULER_GANTT'),
  SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED: 'SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED',
  SCHEDULER_GANTT_ZOOM_LEVEL: 'SCHEDULER_GANTT_ZOOM_LEVEL',
  SCHEDULER_GANTT_SCROLL_POSITION: 'SCHEDULER_GANTT_SCROLL_POSITION',
  SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM: 'SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM',
  SCHEDULER_STORE_MOVING_START_DATE: 'SCHEDULER_STORE_MOVING_START_DATE',
  SCHEDULER_ADD_BACKTRACK_LIST_ITEM: 'SCHEDULER_ADD_BACKTRACK_LIST_ITEM',
  SCHEDULER_CLEAR_BACKTRACK_LIST: 'SCHEDULER_CLEAR_BACKTRACK_LIST',
  SCHEDULER_CLEAR_BACKTRACK_REDO_LIST: 'SCHEDULER_CLEAR_BACKTRACK_REDO_LIST',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('SCHEDULER_GANTT'),
  fetchSchedulerDataFinished: function (payload) {
    return { type: actionTypes.SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED, payload: payload };
  },
  zoomLevel: function (payload) {
    return { type: actionTypes.SCHEDULER_GANTT_ZOOM_LEVEL, payload: payload };
  },
  scrollPosition: function (payload) {
    return { type: actionTypes.SCHEDULER_GANTT_SCROLL_POSITION, payload: payload };
  },
  undoLastAction: () => {
    return { type: actionTypes.SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM, payload: { type: 'undo' } };
  },
  redoLastAction: () => {
    return { type: actionTypes.SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM, payload: { type: 'redo' } };
  },
  storeMovedEventOriginDate: (movedItemDate) => {
    return { type: actionTypes.SCHEDULER_STORE_MOVING_START_DATE, payload: movedItemDate };
  },
  addItemToBacktrackList(payload) {
    return { type: actionTypes.SCHEDULER_ADD_BACKTRACK_LIST_ITEM, payload };
  },
  clearBacktrackList() {
    return { type: actionTypes.SCHEDULER_CLEAR_BACKTRACK_LIST };
  },
  clearBacktrackRedoList() {
    return { type: actionTypes.SCHEDULER_CLEAR_BACKTRACK_REDO_LIST };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
};

jsxActions.init = function (isFirstLoading = false) {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.SCHEDULER_GANTT);

    if (isFirstLoading) {
      navigatorApi.setLoading(true)(dispatch, getState);
    }

    commonSchedulerApi.pollSchedulingStatus(dispatch, getState)(api.refreshPageData(dispatch, getState));

    api
      .init(dispatch, getState)()
      .then(() => {
        if (isFirstLoading) {
          navigatorApi.setLoading(false)(dispatch, getState);
        }
      });
  };
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    const statusPollingInterval = getState().predefinedList.getIn(['scheduler', 'asyncScheduling', 'statusPollingInterval']);

    if (statusPollingInterval) {
      clearInterval(statusPollingInterval);
    }

    commonSchedulerApi.resetState(dispatch, getState)();
  };
};

jsxActions.onTaskMove = function (task) {
  return function (dispatch, getState) {
    return api.move(dispatch, getState)(task);
  };
};

jsxActions.onTaskUnschedule = function (task) {
  return function (dispatch, getState) {
    commonSchedulerApi.setLoading(dispatch)(true);
    return api.unAssign(dispatch, getState)([task.eventRecords[0].data]);
  };
};

jsxActions.onTaskDrop = function (taskId, data) {
  return function (dispatch, getState) {
    return api.onTaskDrop(dispatch, getState)(taskId, data);
  };
};

jsxActions.onTaskLock = function ({ data }) {
  return function (dispatch, getState) {
    return api
      .onTaskLock(
        dispatch,
        getState
      )([data])
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Lock task failed', response);
          return;
        }

        api.refreshPageData(dispatch, getState)();
      });
  };
};

jsxActions.onTaskUnlock = function ({ data }) {
  return function (dispatch, getState) {
    return api
      .onTaskUnlock(
        dispatch,
        getState
      )([data])
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Unlock task failed', response);
          return;
        }

        api.refreshPageData(dispatch, getState)();
      });
  };
};

jsxActions.onScrollToNow = function (schedulerInstance) {
  return function (dispatch, getState) {
    return api.scrollToNow(dispatch, getState)(schedulerInstance);
  };
};

jsxActions.onBeforeTaskMove = function (task) {
  return function (dispatch, getState) {
    return api.onBeforeTaskMove(dispatch, getState)(task);
  };
};

jsxActions.reportTaskProgress = function (data) {
  return function (dispatch, getState) {
    return api.reportTaskProgress(dispatch, getState)(data);
  };
};

jsxActions.undo = function (items) {
  return function (dispatch, getState) {
    commonSchedulerApi.setLoading(dispatch)(true);

    return api
      .undo(
        dispatch,
        getState
      )(items)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Undo action failed', response);
          commonSchedulerApi.setLoading(dispatch)(false);
          return;
        }

        api.refreshPageData(dispatch, getState)();
      });
  };
};

jsxActions.redo = function (items) {
  return function (dispatch, getState) {
    commonSchedulerApi.setLoading(dispatch)(true);

    return api
      .redo(
        dispatch,
        getState
      )(items)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Redo action failed', response);
          commonSchedulerApi.setLoading(dispatch)(false);
          return;
        }

        api.refreshPageData(dispatch, getState)();
      });
  };
};

jsxActions.abortAsyncScheduling = function () {
  return function (dispatch, getState) {
    commonSchedulerApi.abortAsyncScheduling(dispatch, getState)();
  };
};

jsxActions.refreshPageData = function (query, runSideEffect) {
  return function (dispatch, getState) {
    api.refreshPageData(dispatch, getState)(query, runSideEffect);
  };
};

jsxActions.showAsyncSchedulingActivityLog = function () {
  return function (dispatch, getState) {
    commonSchedulerApi.showAsyncSchedulingActivityLog(dispatch, getState)();
  };
};


// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

const config = {
  submitMethod: tasksService.bulkUpdateTasksProgress,
};

export let api = {
  ...boHelper.getApiActions(actions, config),
};

api.init = function (dispatch, getState) {
  return function () {
    return api
      .fetchData(dispatch, getState)()
      .then(() => {
        let label = createLabelHelper('mat.header.navigator.predefinedlists.').get('scheduler');

        let topNavigatorData = {
          name: label,
          type: navigationStates.SCHEDULER_GANTT,
          id: -1,
          components: [],
        };
        navigatorApi.setData(dispatch, getState)(topNavigatorData);
      });
  };
};

api.fetchData = function (dispatch, getState) {
  return function (query, runSideEffect) {
    //Block attempt to fetch the data if refresh was clicked and query is empty.
    if (runSideEffect && !query.filters) {
      return null;
    }

    commonSchedulerApi.setLoading(dispatch)(true);

    return schedulerService
      .fetchSchedulerData({ ...query, includeResourceSections: true, includeSectionDependencies: false })
      .then((response) => {
        commonSchedulerApi.setLoading(dispatch)(false);

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Fetch Scheduler data failed', response);
          return { success: false };
        }

        const schedulerData = schedulerHelper.convertToSchedulerData(response.data);
        dispatch(actions.fetchSchedulerDataFinished(schedulerData));

        return response;
      })
      .catch((err) => {
        console.error('Fetch Scheduler data failed ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.refreshPageData = function (dispatch, getState) {
  return function (query, runSideEffect) {
    return api.fetchData(dispatch, getState)(query, runSideEffect);
  };
};

api.onTaskLock = function (dispatch, getState) {
  return function (tasks, backtrackTime = null) {
    commonSchedulerApi.setLoading(dispatch)(true);

    let query = {
      tasksAssignmentsIds: [tasks[0].id],
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .lock(query)
      .then((response) => {
        commonSchedulerApi.setLoading(dispatch)(false);

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - lock failed', response);

          return response;
        }
        if (!backtrackTime) {
          api.addItemToBacktrackList(dispatch)('lock', [tasks]);
        }

        return response;
      })
      .catch((err) => {
        console.error('scheduler - lock failed ', err);
        return { success: false };
      });
  };
};

api.onTaskUnlock = function (dispatch, getState) {
  return function (tasks, backtrackTime = null) {
    commonSchedulerApi.setLoading(dispatch)(true);

    let query = {
      tasksAssignmentsIds: [tasks[0].id],
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .unlock(query)
      .then((response) => {
        commonSchedulerApi.setLoading(dispatch)(false);

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unlock failed', response);

          return response;
        }
        if (!backtrackTime) {
          api.addItemToBacktrackList(dispatch)('unlock', [tasks]);
        }

        return response;
      })
      .catch((err) => {
        console.error('scheduler - unlock failed ', err);
        return { success: false };
      });
  };
};

api.addItemToBacktrackList = function (dispatch) {
  return function (actionType, params) {
    const time = DateTimeHelper.ConvertFromDate(new Date());
    time.systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const action = _getBacktrackAction(actionType, params, time);
    api.clearBacktrackRedoList(dispatch);
    dispatch(actions.addItemToBacktrackList(action));
  };
};

api.clearBacktrackList = function (dispatch) {
  dispatch(actions.clearBacktrackList());
};

api.clearBacktrackRedoList = function (dispatch) {
  dispatch(actions.clearBacktrackRedoList());
};

api.move = function (dispatch, getState) {
  return function (task, backtrackTime = null) {
    commonSchedulerApi.setLoading(dispatch)(true);

    const data = task.eventRecords[0].data;
    const { forceStartDate } = task;

    let convertedTask = schedulerHelper.convertToServerData([data], forceStartDate);

    let query = {
      resourceId: data.resourceId,
      toolId: data.plannedTool?.id || null,
      startTime: {
        epochDateTime: convertedTask.events[0].startDate.epochDateTime,
        systemTimeZone: convertedTask.events[0].startDate.systemTimeZone,
      },
      originalTimestamp: backtrackTime,
    };

    return schedulerService.moveTask(data.id, query).then((response) => {
      commonSchedulerApi.setLoading(dispatch)(false);

      if (!Network.isResponseValid(response)) {
        console.error('Fetch Scheduler data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        api.refreshPageData(dispatch, getState)();
        return response;
      }

      let warnings = response?.applicationResponseStatus?.warnings?.subWarnings;
      if (warnings && warnings.length) {
        let desc = _getMessageDialogBuilder('mat.scheduler.operations.view.warning.title', warnings, null, null, null, 'error');
        messageDialogApi.open(dispatch, getState)(desc);
      }

      if (!backtrackTime) {
        const { lastMovedItemDate } = getState().predefinedList.get('scheduler').get('backtrackList');
        api.addItemToBacktrackList(dispatch)('move', [{ ...task, originalDate: lastMovedItemDate }]);
        dispatch(actions.storeMovedEventOriginDate(null));
      }

      api.refreshPageData(dispatch, getState)();
      return response;
    });
  };
};

api.undoMove = function (dispatch, getState) {
  return function (task, backtrackTime = null) {
    commonSchedulerApi.setLoading(dispatch)(true);

    const data = task.eventRecords[0].data;
    const taskData = _getUndoMoveTaskData(data);
    
    let query = {
      taskAssignment: taskData,
      originalTimestamp: backtrackTime,
    };

    return schedulerService.undoMove(query).then((response) => {
      commonSchedulerApi.setLoading(dispatch)(false);

      if (!Network.isResponseValid(response)) {
        console.error('Fetch Scheduler data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        api.refreshPageData(dispatch, getState)();
        return response;
      }

      let warnings = response?.applicationResponseStatus?.warnings?.subWarnings;
      if (warnings && warnings.length) {
        let desc = _getMessageDialogBuilder('mat.scheduler.operations.view.warning.title', warnings, null, null, null, 'error');
        messageDialogApi.open(dispatch, getState)(desc);
      }

      if (!backtrackTime) {
        const { lastMovedItemDate } = getState().predefinedList.get('scheduler').get('backtrackList');
        api.addItemToBacktrackList(dispatch)('move', [{ ...task, originalDate: lastMovedItemDate }]);
        dispatch(actions.storeMovedEventOriginDate(null));
      }

      api.refreshPageData(dispatch, getState)();
      return response;
    });
  };
};

api.scrollToNow = function (dispatch) {
  return function (schedulerInstance) {
    let scrollOptions = {
      block: 'start',
      //block: 'center',
      edgeOffset: 50, // px.
      animate: true,
    };

    // Update Redux. Does nothing beyond that currently.
    dispatch(actions.scrollPosition(scrollOptions));

    // Command scheduler engine.
    // moment returns a js date object in client local time only, in order to get the native js date in org timezone
    // we must get the formatted date string and construct a js date from it.
    const formattedOrgDate = DateTimeHelper.getOrgDateTime().format('YYYY-MM-DDTHH:mm:ss');
    schedulerInstance.scrollToDate(new Date(formattedOrgDate), scrollOptions);
  };
};

// api.scrollToDate = function () {
//   return function (data, schedulerEngineInstance) {
//     if (!schedulerEngineInstance) {
//       return;
//     }

//     if (data && data.events && data.events.length > 0) {
//       let earliestDate = schedulerHelper.getEarliestEventDate(data.events);

//       if (earliestDate) {
//         let scrollOptions = {
//           block: 'start',
//           edgeOffset: 50,
//           animate: true,
//         };

//         schedulerEngineInstance?.scrollToDate?.(earliestDate, scrollOptions);
//       }
//     }
//   };
// };

// api.onTaskDrop = function (dispatch, getState) {
//   return function (taskId, data) {
//     api
//       .assign(dispatch, getState)(taskId, data)
//       .then((response) => {
//         if (!Network.isResponseValid(response)) {
//           console.error('Assign task failed', response);
//           messageDialogApi.responseError(dispatch, getState)(response);
//           return;
//         }

//         api.refreshPageData(dispatch, getState)();
//       });
//   };
// };

api.assign = function (dispatch, getState) {
  return function (taskId, data, backtrackTime = null, handleValidationWarnings = true) {
    const query = { ...data, originalTimestamp: backtrackTime };
    return schedulerService.assignTask(taskId, query).then((response) => {
      if (!Network.isResponseValid(response)) {
        return response;
      }

      if (handleValidationWarnings) {
        let warnings = _hasValidationWarnings(response.applicationResponseStatus);
        if (warnings) {
          let message = _getLocalizedValidations(warnings);
          let desc = _getAssignMessageDialogBuilder(message);
          messageDialogApi.open(dispatch, getState)(desc);
        }
      }
      if (!backtrackTime && response.data) {
        const { mainTaskId, id } = response.data;
        api.addItemToBacktrackList(dispatch)('assign', [id, mainTaskId, data]);
      }
      return response;
    });
  };
};

api.unAssign = function (dispatch, getState) {
  return function (tasks, backtrackTime = null) {
    let query = {
      tasksAssignmentsIds: [tasks[0].id],
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .unAssign(query)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unAssign Failed');
          return response;
        }

        let errorCode = response?.applicationResponseStatus?.warnings?.mainWarning?.id;
        if (errorCode) {
          let desc = _getUnAssignMessageDialogBuilder(response.applicationResponseStatus.warnings.mainWarning);
          messageDialogApi.open(dispatch, getState)(desc);
          return api.refreshPageData(dispatch, getState)();
        }

        messageDialogApi.showSuccess(dispatch, getState)('mat.scheduler.operations.view.delete.confirmation.success.message');
        if (!backtrackTime && tasks.length === 1) {
          api.addItemToBacktrackList(dispatch)('unAssign', [tasks]);
        }

        api.refreshPageData(dispatch, getState)();
        return response;
      })
      .catch((err) => {
        console.error('Fetch Scheduler data failed ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.onBeforeTaskMove = function (dispatch, getState) {
  return function (task) {
    let labels = createLabelHelper('mat.scheduler.gantt.');
    let title = '';

    dispatch(actions.storeMovedEventOriginDate(task.eventRecord._startDate));

    if (!task) {
      return;
    }
    let operationStatus = task.eventRecord.operationStatus;
    if (operationStatus === 'STARTED') {
      title = labels.get('task.started.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }
    if (operationStatus === 'COMPLETED') {
      title = labels.get('task.completed.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }
    if (task.eventRecord.locked) {
      title = labels.get('task.locked.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }

    if (!title) {
      return;
    }
    let desc = { title, className: 'oneBackground scheduler-page-message-dialog', type: 'error' };
    messageDialogApi.open(dispatch, getState)(desc);
  };
};

api.reportTaskProgress = function (dispatch, getState) {
  return function (
    { taskData, status, isReportAsPlanned, reportData = null, startTime = null, endTime = null, isRollbackToScheduled = false },
    backtrackTime = null
  ) {
    commonSchedulerApi.setLoading(dispatch)(true);

    let requestPromise = null;
    if (isRollbackToScheduled) {
      requestPromise = tasksService.rollbackTaskProgress(taskData.id, { originalTimestamp: backtrackTime });
    } else {
      const performedBy = getState().login.get('loggedInUser')?.id;
      const query = {
        tasksProgressList: [
          {
            taskAssignmentId: taskData.id,
            toolId: taskData.plannedTool?.id,
          },
        ],
        locationId: taskData.plannedResource.id,
        status,
        isReportAsPlanned,
        performedBy,
        startTime,
        completedQuantity: reportData?.completedQuantity ?? null,
        endTime,
      };

      requestPromise = api.submit(dispatch, getState)(query, _getBatchOperationMessageDialogBuilder());
    }

    return requestPromise
      .then((response) => {
        commonSchedulerApi.setLoading(dispatch)(false);

        if (response?.responseData?.data?.jobStatus === 'FAILED') {
          return response;
        }

        if (!backtrackTime) {
          const data = {
            taskData,
            status,
            isReportAsPlanned,
          };

          api.addItemToBacktrackList(dispatch)('reportTaskProgress', [data]);
        }
        api.refreshPageData(dispatch, getState)();

        return response;
      })
      .catch((err) => {
        commonSchedulerApi.setLoading(dispatch)(false);
        console.error('Report task progress failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
      });
  };
};

api.undo = function (dispatch, getState) {
  return function (items) {
    return api
      .undoRedoAction(dispatch, getState)(items[items.length - 1], 'undo')
      .then((response = false) => {
        if (!Network.isResponseValid(response)) {
          return;
        }

        dispatch(actions.undoLastAction());
        return response;
      });
  };
};

api.redo = function (dispatch, getState) {
  return function (items) {
    return api
      .undoRedoAction(dispatch, getState)(items[items.length - 1], 'redo')
      .then((response = false) => {
        if (!Network.isResponseValid(response)) {
          return response;
        }

        dispatch(actions.redoLastAction());
        return response;
      });
  };
};

api.undoRedoAction = (dispatch, getState) => {
  return function (actionItem, actionDirection) {
    const { time } = actionItem;
    const { action, params } = actionItem[actionDirection];
    const { onTaskLock, onTaskUnlock, assign, move, undoMove, reportTaskProgress, unAssign, clearBacktrackList } = api;

    const reversibleActions = {
      lock: onTaskLock,
      unlock: onTaskUnlock,
      unAssign,
      assign,
      move,
      undoMove,
      reportTaskProgress,
    };

    return reversibleActions[action](dispatch, getState)(...params, time).then((response = false) => {
      if (!response) {
        return console.error(`response is missing! please check ${action} function and make sure it return the response`);
      }

      const { data, responseData } = response;

      if (!data) {
        console.error(`${actionDirection} ${action} data is missing!`);
      }

      if (data?.resetClientHistory) {
        clearBacktrackList(dispatch);
      }

      return responseData ?? response;
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _getMessageDialogBuilder(titleKey, warnings, fnCloseDialog, submitFunc, submitKey, type) {
  let title = createLabelHelper('').get(titleKey);
  let children = warnings.map((warning, index) => {
    let message = createLabelHelper('').get(warning.id, undefined, warning.params || undefined);
    return <MessageDialog.MessageArea key={'confirmMessageDataRow' + index} text={message} />;
  });

  let buttons = [];
  if (fnCloseDialog) {
    buttons.push({
      id: 'cancel',
      text: createLabelHelper('mat.dialog.').get('cancel'),
      action: fnCloseDialog,
      bsStyle: 'default',
    });
  }
  if (submitFunc) {
    buttons.push({
      id: 'submit',
      text: createLabelHelper('').get(submitKey),
      action: submitFunc,
      bsStyle: 'primary',
    });
  }

  let className = 'scheduler-page-message-dialog';
  return { title, buttons, children, className, type };
}

function _hasValidationWarnings(status) {
  let errors = _getValidationErrors(status.errors);
  let warnings = _getValidationWarnings(status.warnings);

  if (!errors && !warnings) {
    return null;
  }

  const messages = [...(errors || []), ...(warnings || [])];

  return messages;
}

function _getValidationErrors(errors) {
  if (errors) {
    return errors.mainError ? [errors.mainError] : errors?.subErrors?.length > 0 ? errors.subErrors : null;
  }
  return null;
}

function _getValidationWarnings(warnings) {
  if (warnings) {
    return warnings.mainWarning ? [warnings.mainWarning] : warnings?.subWarnings?.length > 0 ? warnings.subWarnings : null;
  }
  return null;
}

function _getLocalizedValidations(validations) {
  if (validations && validations.length > 0) {
    let validationsLabels = createLabelHelper('');

    let res = validations.map((m) => {
      //Note: params should be 'undefined' (or {} but not null) when there is no params (for react-intl)
      return validationsLabels.get(m.id, undefined, m.params || undefined);
    });

    return res.join(' ');
  }
  return null;
}

function _getAssignMessageDialogBuilder(message) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.warning.title');
  let children = [<MessageDialog.MessageArea key="confirmMessageDataRow" text={message} />];
  let className = 'scheduler-page-message-dialog';
  return { title, children, className, type: 'error' };
}

const _getBatchOperationMessageDialogBuilder = () => {
  return (response, fnCloseDialog) => {
    const title = createLabelHelper('').get('mat.scheduler.operations.view.updateStatus.warning.title', '', { count: response.totalJobItems });
    return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
  };
};

const _getBacktrackAction = (actionType, params, time) => {
  const item = {
    id: Math.floor(Math.random() * 1000),
    time,
  };

  if (actionType === 'lock') {
    item.undo = {
      action: 'unlock',
      params,
    };
    item.redo = {
      action: 'lock',
      params,
    };
  }

  if (actionType === 'unlock') {
    item.undo = {
      action: 'lock',
      params,
    };
    item.redo = {
      action: 'unlock',
      params,
    };
  }

  if (actionType === 'assign') {
    const [taskId, mainTaskId, data] = params;
    item.undo = {
      action: 'unAssign',
      params: [[{ id: taskId }]],
    };
    item.redo = {
      action: 'assign',
      params: [mainTaskId, data],
    };
  }

  if (actionType === 'unAssign') {
    const [event] = params[0];
    const { mainTaskId, resourceId, startDate, toolId = null } = event;
    const assignParams = { resourceId, startTime: DateTimeHelper.ConvertFromDate(startDate), toolId };
    item.undo = {
      action: 'assign',
      params: [`${mainTaskId}`, assignParams],
    };
    item.redo = {
      action: 'unAssign',
      params,
    };
  }

  if (actionType === 'move') {
    const [task] = params;

    const newEventRecords = [{ 
      ...task.eventRecords[0], 
      data: {
        ...task.eventRecords[0].data, 
        plannedStartDate: {
          ...task.eventRecords[0].data.plannedStartDate, 
          epochDateTime: DateTimeHelper.ConvertFromDate(task.eventRecords[0].meta.modified.startDate).epochDateTime
        },
        plannedEndDate: {
          ...task.eventRecords[0].data.plannedEndDate, 
          epochDateTime: DateTimeHelper.ConvertFromDate(task.eventRecords[0].meta.modified.endDate).epochDateTime
        }
      } 
    }];

    const originalTask = { ...task, eventRecords: newEventRecords };

    const undoTask = { ...originalTask, forceStartDate: task.originalDate };
    const redoTask = { ...task, forceStartDate: task.context.startDate };

    item.undo = {
      action: 'undoMove',
      params: [undoTask],
    };
    item.redo = {
      action: 'move',
      params: [redoTask],
    };
  }

  if (actionType === 'reportTaskProgress') {
    const [data] = params;

    item.undo = {
      action: 'reportTaskProgress',
      params: [{ ...data, isRollbackToScheduled: true }],
    };
    item.redo = {
      action: 'reportTaskProgress',
      params: [data],
    };
  }

  return item;
};

function _getUnAssignMessageDialogBuilder(warning) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.warning.title');
  let message = createLabelHelper('').get(warning.id, undefined, warning.params || undefined);
  let children = [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message} />];
  let className = 'scheduler-page-message-dialog';
  let buttons = [];
  let messageDialogDescriptor = { title, buttons, children, className, type: 'error' };
  return messageDialogDescriptor;
}

function _getUndoMoveTaskData(task){
  const { 
    id, 
    mainTaskId,
    plannedStartDate,
    plannedEndDate,
    plannedResource,
    plannedTool,
    valid,
    locked,
    operationStatus,
    validationMessages,
    warningSeverity,
    split,
    setupLead,
    splitAssignments,
    resetClientHistory,
    vacuumPort,
    productionLine
  } = task;
  
  return {
    id, 
    mainTaskId,
    plannedStartTime: plannedStartDate,
    plannedEndTime: plannedEndDate,
    plannedResource: {
      id: plannedResource.id,
      name: plannedResource.name,
      businessId: plannedResource.businessId,
    },
    plannedTool,
    valid,
    locked,
    operationStatus,
    validationMessages,
    warningSeverity,
    split,
    setupLead,
    splitAssignments,
    resetClientHistory,
    vacuumPort,
    productionLine
  };
}