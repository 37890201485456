import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as toolsService from '../../../../services/Tools/toolsService';
import * as kitTypesService from '../../../../services/KitTypes/kitTypesService';
import uniqBy from 'lodash-es/uniqBy';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_TOOLTYPE_DIALOG_SHOW:               'CREATE_TOOLTYPE_DIALOG_SHOW',
  CREATE_TOOLTYPE_DIALOG_HIDE:               'CREATE_TOOLTYPE_DIALOG_HIDE',
  CREATE_TOOLTYPE_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_TOOLTYPE_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_TOOLTYPE_DIALOG_SUBMIT_FINISHED:    'CREATE_TOOLTYPE_DIALOG_SUBMIT_FINISHED',
  CREATE_TOOLTYPE_DIALOG_FETCH_DATA_READY:   'CREATE_TOOLTYPE_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_TOOLTYPE_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_TOOLTYPE_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_TOOLTYPE_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_TOOLTYPE_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.CREATE_TOOLTYPE_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    api.fetchDialogData(dispatch, getState)().then((response) => {
      dispatch(actions.show(data));
    });
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {

    let promise1 = toolsService.fetchToolTypes();
    let promise2 = toolsService.getToolCategories();
    let promise3 = kitTypesService.fetchAllKitTypes();

    return Promise.all([promise1, promise2, promise3]).then((allResults) => {
      let isResultValid = allResults.every((result) => {
        return Network.isResponseValid(result);
      });
      if (!isResultValid) {
        console.error('Get Tool Types initial data failed.');
        return {success: false};
      }

      let toolTypes = allResults[0].dataList || [];
      let toolCategories = allResults[1].dataList || [];
      let kitTypes = allResults[2].dataList || [];

      const uniqueFilteredResponse = uniqBy(toolTypes, 'description').filter(item => item.description);

      let dialogData = {
        toolTypes: uniqueFilteredResponse.map((obj) => {return {value: obj.id, label: obj.description, data: obj}}),
        toolCategories: toolCategories.map((obj) => {return {value: obj.id, label: obj.businessId, data: obj}}),
        kitTypes: kitTypes.map((obj) => {return {value: obj.id, label: obj.businessId, data: obj}}),
      };

      dispatch(actions.fetchDilaogDataReady(dialogData));
      return dialogData;
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    let method = isEditMode ? toolsService.updateToolType : toolsService.createToolType;

    method(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create/Edit tool type failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      } else {
        const successLabelKey = isEditMode ? 'editItem' : 'createItem';
        messageDialogApi.showSuccess(dispatch, getState)(`mat.administration.matsettings.success.${successLabelKey}`, {itemType: 'A Tool Type'});
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return {success: true};
    });
  };
};

