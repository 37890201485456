import * as bagsService from '../../../services/Bags/bagsService';
import { gridsNames } from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_MAT_SETTINGS_BAGS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return bagsService.fetchBags(query);
  };
}

function _convertToRowData(rows) {
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;
