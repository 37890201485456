import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'infrastructure/js/components/controls/DropdownButton/dropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import classNames from 'classnames';
import ButtonWithTooltip from 'infrastructure/js/components/controls/ButtonWithTooltip/buttonWithTooltip';

require('./header.scss');

export const headerItemsLabels = {
  ACTIVATE: 'activate',
  ARCHIVE: 'archive',
  EXPORT: 'export',
  IMPORT: 'import',
  REFRESH: 'refresh',
  SHOW_ON_GANTT: 'showOnGantt',
  EXPORT_TEMPLATE: 'exportTemplate',
  PRINT_RFID_TAG: 'printRfidTag',
  CREATE_USER: 'createuser',
  CREATE_ROLL: 'createRoll',
  CREATE_SPOOL: 'createSpool',
  CREATE_RESIN: 'createResin',
  CREATE_GROUP: 'createGroup',
  CREATE_TOOL: 'createTool',
  MARK_COMPLETE: 'markcomplete',
  MARK_CANCELED: 'markcanceled',
  REOPEN: 'reopen',
  OPEN: 'open',
  UPDATE_SELF_LIFE: 'updateshelflife',
  MOVE_TO: 'moveto',
  BRING_HERE: 'bringHere',
  IMPORT_TOOLS: 'importTools',
  IMPORT_ROLLS: 'importrolls',
  IMPORT_SPOOLS: 'importSpools',
  IMPORT_RESINS: 'importResins',
  IMPORT_ROLLS_VIA_BARCODE: 'importRollsViaBarcode',
  IMPORT_SPOOLS_VIA_BARCODE: 'importSpoolsViaBarcode',
  IMPORT_RESINS_VIA_BARCODE: 'importResinsViaBarcode',
  IMPORT_TAGS: 'importtags',
  IMPORT_KITS: 'importKits',
  IMPORT_GROUPS: 'importgroups',
  IMPORT_WORK_ORDERS: 'importWorkOrders',
  IMPORT_KIT_TYPES: 'importkittypes',
  IMPORT_PLIES_TO_KIT_TYPES: 'importPliesToKitTypes',
  IMPORT_KIT_TYPES_JSON: 'importkittypesJson',
  EXPORT_KIT_TYPES: 'exportkittypes',
  EXPORT_TOOL_TYPES: 'exportToolTypes',
  IMPORT_TOOL_TYPES: 'importToolTypes',
  IMPORT_TOOL_TYPES_JSON: 'importtooltypesJson',
  EXPORT_TOOL_GROUPS: 'exportToolGroups',
  IMPORT_TOOL_GROUPS: 'importToolGroups',
  EXPORT_MATERIAL_TYPES: 'exportmaterialtypes',
  IMPORT_MATERIAL_TYPES: 'importmaterialtypes',
  IMPORT_PART_TYPES: 'importPartTypes',
  EXPORT_PART_TYPES: 'exportPartTypes',
  IMPORT_PLY_TYPES: 'importPlyTypes',
  EXPORT_PLY_TYPES: 'exportPlyTypes',
  IMPORT_BAGS: 'importBags',
  EXPORT_BAGS: 'exportBags',
  IMPORT_PROJECTS: 'importProjects',
  EXPORT_PROJECTS: 'exportProjects',
  IMPORT_GROUP_TYPES: 'importgrouptypes',
  EXPORT_GROUP_TYPES: 'exportgrouptypes',
  IMPORT_OPERATION_SEQUENCE: 'importOperationSequence',
  EXPORT_OPERATION_SEQUENCE: 'exportOperationSequence',
  IMPORT_NESTS: 'importNests',
  IMPORT_NESTS_MULTI_ROLLS: 'importNestsMultiRolls',
  IMPORT_NESTS_ANY_ROLLS: 'importNestsAnyRolls',
  IMPORT_NESTS_WITH_ROLL: 'importNestsWithRoll',
  PRINT_LABEL: 'printlabel',
  EXPORT_KIT_REPORT: 'exportKitReport',
  EXPORT_KIT_ROLLS_REPORT: 'exportKitRollsReport',
  EXPORT_GROUP_REPORT: 'exportGroupReport',
  RESET_PASSWORD: 'resetpassword',
  UNARCHIVE: 'unarchive',
  LOCK: 'lock',
  UNLOCK: 'unlock',
  EDIT_ATTRIBUTES: 'editattributes',
  IMPORT_ATTRIBUTES_UPDATE: 'importAttributesUpdate',
  CREATE_ORG: 'createorg',
  EDIT_ORG: 'editorg',
  REQUEST_ORG_DELETION: 'requestdeletion',
  CLONE: 'clone',
  REJECT_ORG_DELETION: 'rejectdeletion',
  ACCEPT_ORG_DELETION: 'acceptdeletion',
  CREATE: 'create',
  EDIT: 'edit',
  REMOVE: 'remove',
  REMOVE_ALL: 'removeAll',
  DELETE: 'delete',
  DEACTIVATE: 'deactivate',
  REPORT_CUT: 'reportcut',
  CUT_AND_KIT: 'cutAndKit',
  REPORT_MAINTENANCE: 'reportmaintenance',
  CREATE_SUB_ROLLS: 'createsubrolls',
  EXPORT_ACTIVITY_LIST: 'exportactivitylist',
  EXPORT_ROLLS_LIST: 'exportRollsList',
  EXPORT_SPOOLS_LIST: 'exportSpoolsList',
  EXPORT_RESINS_LIST: 'exportResinsList',
  EXPORT_KITS_LIST: 'exportKitsList',
  EXPORT_GROUPS_LIST: 'exportGroupsList',
  EXPORT_TOOLS_LIST: 'exportToolsList',
  SAVE: 'save',
  PREVIEW: 'preview',
  EXPORT_ROLLS_USAGE: 'exportRollsUsage',
  EXPORT_SPOOLS_USAGE: 'exportSpoolsUsage',
  EXPORT_ROLL_USAGE: 'exportRollUsage',
  EXPORT_SPOOL_USAGE: 'exportSpoolUsage',
  ADD_TO_PICK_LIST: 'addToPickList',
  REMOVE_FROM_PICK_LIST: 'removeFromPickList',
  RESET_TO_DEFAULT: 'resetToDefault',
  RESET: 'reset',
  APPLY: 'apply',
  PRINT_RFID_TAGS: 'printRFIDTags',
  PRINT_EMPTY_TAGS: 'printEmptyTags',
  REFRESH_ALL: 'refreshAll',
  CLEAR_FILTERS: 'clearFilters',
  CREATE_KITS: 'createkits',
  REPORT_KITTING: 'reportkitting',
  REMOVE_DATA_FROM_DASHBOARDS: 'removeDataFromDashboards',
  AUTO_SCHEDULE: 'autoSchedule',
  SCHEDULE: 'schedule',
  UNSCHEDULE: 'unschedule',
  EXPORT_TASKS_LIST: 'exportTasksList',
  CREATE_TASK: 'createTask',
  DELETE_TASK: 'deleteTask',
  UPLOAD: 'upload',
  DOWLOAD: 'download',
  EDIT_CATEGORY: 'editCategory',
  ADD: 'add',
  ZOOM_IN: 'zoomIn',
  ZOOM_OUT: 'zoomOut',
  TODAY: 'today',
  NEXT: 'next',
  PREVIOUS: 'previous',
  CREATE_WORK_ORDER: 'createWorkOrder',
  ADD_TO_GROUP: 'addToGroup',
  UNGROUP: 'ungroup',
  EDIT_LENGTH: 'editLength',
  IMPORT_SHIPMENT_DOCUMENT: 'importShipmentDocument',
  CONFIRM_SHIPMENTS_STATUS: 'confirmshipmentstatus',
  IMPORT_SHIPMENT: 'importShipment',
  READY_TO_SHIP: 'readyToShip',
  REJECT_SHIPMENT: 'rejectShipment',
  DELETE_SHIPMENT: 'deleteShipment',
  SHIP_TO_CUSTOMER: 'shipToCustomer',
  DOWNLOAD_SHIPMENT_DOCUMENT: 'downloadShipmentDocument',
  ADD_TO_SHIPMENT: 'addToShipment',
  MARK_AS_RECEIVED: 'markasreceived',
  ADD_REPLACE_SPOOLS: 'addReplaceSpools',
  FIBER_PLACEMENT: 'fiberPlacement',
  CONFIRM_LOCATION: 'confirmLocation',
  UNDO: 'undo',
  REDO: 'redo',
  CREATE_RULE: 'createRule',
  EDIT_RULE: 'editRule',
  DELETE_RULE: 'deleteRule',
  PROCESS_TEMPERATURE_LOG: 'processTemperatureLog',
  OPEN_IN_NEW_TAB: 'openInNewTab',
  COLLAPSE_ALL: 'collapseAll',
  EXPAND_ALL: 'expandAll',
  CANCEL: 'cancel',
  UPDATE_KIT_PLIES: 'updateKitPlies',
};

export default class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.common.header.items.');
  }

  onClick = (button) => {
    button.action(button.actionData);
  };

  onSelect = (button) => {
    button.action(button.actionData);
  };

  createButtons = (buttons) => {
    if (!buttons) {
      return null;
    }

    return buttons.map((button, index) => {
      if (button.buttonComponent) {
        return <button.buttonComponent key={index} {...button} />;
      }

      return (
        <ButtonWithTooltip
          id={button.id}
          key={index}
          className={classNames('main-operation border-btn', button.label ? '' : 'only-icon', button.className)}
          icon={button.icon}
          counter={button.counter}
          loading={button.loading}
          onClick={() => this.onClick(button)}
          disabled={button.disabled}
          tooltip={button.tooltip && !button.disabled ? this.labels.get(button.tooltip) : ''}
          placement='top'
        >
          {button.label ? this.labels.get(button.label) : ''}
        </ButtonWithTooltip>
      )
    });
  };

  createKebab = (kebab) => {
    if (kebab?.menuItems) {
      let menuItems = kebab.menuItems
        .filter((item) => item !== null)
        .map((item, index) => {
          return (
            <Dropdown.Item eventKey={1} key={index} id={index} href="" onClick={() => this.onSelect(item)} disabled={item.disabled}>
              {this.labels.get(item.label)}
            </Dropdown.Item>
          );
        });

      return (
        <DropdownButton
          id="header-kebab-menu"
          className="border-btn btn-default btn-group"
          disabled={kebab.disabled || kebab.menuItems.length === 0}
          loading={kebab.loading}
        >
          {menuItems}
        </DropdownButton>
      );
    }
    return null;
  };

  createTitle = (title) => {
    if (!title) {
      return null;
    }

    return (
      <div className="flex-grow-2">
        <label className="title">{title}</label>
      </div>
    );
  };

  render() {
    let buttons = this.createButtons(this.props.buttons);
    let kebab = this.createKebab(this.props.kebab);
    let title = this.createTitle(this.props.title);
    return (
      <div className={classNames('common-header', this.props.className)}>
        {title}
        <div className="flex-grow-2">{this.props.leftContent}</div>
        {buttons}
        {kebab}
      </div>
    );
  }
}
Header.defaultProps = {
  title: '',
  buttons: [],
  kebab: {},
};

Header.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  kebab: PropTypes.object,
};
