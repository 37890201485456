import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';

class ProjectsViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: true},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.projects.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.projectname.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'businessId',
      },
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_PROJECTS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'projectsViewGrid'
  }
)(ProjectsViewGrid);
