import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize  from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {getEnumValue, enumTypes, getLookupOptions} from '../../../../../utils/enumHelper';
import OperationListItem from '../../../../Common/Controls/AddRemoveList/AddRemoveListItem/OperationListItem/operationListItem';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import {openLocationInNewTab} from '../../../../../utils/locationHelper';
import List from 'infrastructure/js/components/List/list';

import './stationSettingsForm.scss';

const maxLength50 = Validation.maxLength(50);

const validateOverTimeMin = (value, allValues) => {
  const minValue = {
    _hours: 0,
    _minutes: 1
  }
  const currentValue = {
    _hours: allValues.overTime_hours,
    _minutes: allValues.overTime_minutes
  }
  return Validation.timeField.min(minValue)(currentValue, allValues)
};

const validateOverTimeRequired = (value, allValues) => {
  return Validation.timeRequired('overTime')(value, allValues);
};

const validateCycleDurationRequired = (value, allValues) => {
  return Validation.daysHoursMinutesTimeRequired('cycleDuration')(value, allValues);
};

const validateTotalPortsValue = (value, allValues) => {
  if (allValues.activeVacuumPorts !== '' && allValues.activeVacuumPorts !== null) {
    if (value === '' || value === null) {
      return Validation.required(value, allValues);
    }
  }
  return undefined;
};

const validateActivePortsValue = (value, allValues) => {
  if (allValues.totalVacuumPorts !== '' && allValues.totalVacuumPorts !== null) {
    return (value === '' || value === null) ?
      Validation.required(value, allValues) :
      Validation.number.max(allValues.totalVacuumPorts)(value, allValues);
  }
  return undefined;
};



const StationSettingsForm  = ({
                                initialize=() => { console.error('initialize is missing!'); },
                                pristine=true,
                                handleSubmit=() => { console.error('handleSubmit is missing!'); },
                                actions,
                                change=() => { console.error('change is missing!'); },
                                touch=() => { console.error('touch is missing!'); },
                                itemToEdit=null,
                                sData={},
                                onSave=()=>{},
                                onDelete=()=>{},
                              }) => {

  const labels = createLabelHelper('mat.administration.matsettings.digitalTwin.station.');

  const [stationType, setStationType] = useState(null);
  const [primaryOperations, setPrimaryOperations] = useState([]);
  const [secondaryOperations, setSecondaryOperations] = useState([]);
  const [optionsInUse, setOptionsInUse] = useState([]);
  const [currentPrimaryOperationsIds, setCurrentPrimaryOperationsIds] = useState([]);
  const [currentPreselectedOperations, setCurrentPreselectedOperations] = useState([]);
  const [isAvailableOverTime, setIsAvailableOverTime] = useState(itemToEdit?.isOvertimeEnabled || false);
  const [isCycleDurationEnabled, setIsCycleDurationEnabled] = useState(itemToEdit?.isAllowCycleDuration || false);
  const [isResource, setIsResource] = useState(itemToEdit?.isResource || false);
  const [updateKey, setUpdateKey] = useState(0);


  const typeToOpsMap = useMemo(() => {
    return new Map([
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CUT'), [getEnumValue(enumTypes.OPERATION_TYPE)('CUT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('KIT'), [getEnumValue(enumTypes.OPERATION_TYPE)('KIT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT'), [getEnumValue(enumTypes.OPERATION_TYPE)('CUT'), getEnumValue(enumTypes.OPERATION_TYPE)('KIT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CURING'), [getEnumValue(enumTypes.OPERATION_TYPE)('CURING')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL'), [] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('LAYUP'), [getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('DEMOLDING'), [getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('AFP'), [getEnumValue(enumTypes.OPERATION_TYPE)('AFP')] ],
    ]);
  },[]);

  const stationTypes = useMemo(() => getLookupOptions(enumTypes.LOCATION_TYPE, 'STATION')
                      .filter((obj) => {return obj?.value !== getEnumValue(enumTypes.LOCATION_TYPE)('PHANTOM')}), []);

  const automationTypes = useMemo(() => getLookupOptions(enumTypes.AUTOMATION_TYPE), []);

  useEffect(() => {
    initFormValues();
  }, [ itemToEdit]);

  const getAllOperations = useCallback(() => {
    return sData?.operations ?? [];
  }, [sData?.operations]);

  const getAllPrimaryOperations = useCallback(() => {
    return sData?.allPrimaryOperations ?? [];
  }, [sData?.allPrimaryOperations]);

  const initFormValues = () => {
    let initialValues = {
      active: true,
      locationType: getInitialStationType(),
      isResource: false,
      capacity: null,
      tokenCapacity: null,
      isOutsourced: false,
      overTime_minutes: '',
      overTime_hours: '',
      cycleDuration_days: '',
      cycleDuration_hours: '',
      cycleDuration_minutes: '',
      automationType: getEnumValue(enumTypes.AUTOMATION_TYPE)('NONE'),
      vacuumPortsTotal: null,
      vacuumPortsActive: null,
    };

    if (isEditMode()) {
      const cycleDurationObj = DateTimeHelper.ConvertMinutesToDaysHoursMinutesObject(itemToEdit.cycleDuration);

      initialValues.stationName = itemToEdit.name;
      initialValues.automationType = itemToEdit.automationType;
      initialValues.capacity = itemToEdit.capacity;
      initialValues.tokenCapacity = itemToEdit.tokenCapacity;
      initialValues.isOutsourced = itemToEdit.isOutsourced || false;
      initialValues.isResource = itemToEdit.isResource;
      initialValues.jointStartTime = itemToEdit.jointStartTime;
      initialValues.overtimeEnabled = itemToEdit.isOvertimeEnabled;
      if (itemToEdit.maxOvertimeValue) {
        initialValues.overTime_minutes = itemToEdit.maxOvertimeValue % 60 | 0;
        initialValues.overTime_hours = itemToEdit.maxOvertimeValue / 60 | 0;
      }
      initialValues.cycleDurationEnabled = itemToEdit.isAllowCycleDuration || false;
      if (itemToEdit.isAllowCycleDuration) {
        initialValues.cycleDuration_days = cycleDurationObj.days;
        initialValues.cycleDuration_hours = cycleDurationObj.hours;
        initialValues.cycleDuration_minutes = cycleDurationObj.minutes;
      }
      initialValues.totalVacuumPorts = itemToEdit.totalVacuumPorts;
      initialValues.activeVacuumPorts = itemToEdit.activeVacuumPorts;
      initialValues.primaryOperations = getInitialPrimaryOperations(itemToEdit.operations);
      initialValues.secondaryOperations = getInitialSecondaryOperations(itemToEdit.operations);
    }
    initialize(initialValues);

    initOperations(itemToEdit);
  }

  const isEditMode = () => {
    return itemToEdit?.id;
  }

  const initOperations = (itemToEdit) => {
    let idsInUse = [];
    let secondaryInitialOps = [];

    let primaryOpsIds = getPrimaryOperationsIdsByStationType(getInitialStationType()) || [];

    //all operations without primary operations (for the current station type)
    let secondaryOps = getAllOperations().filter((o) => {
      return primaryOpsIds.indexOf(o.value) < 0 && o.data?.operationType !== getEnumValue(enumTypes.OPERATION_TYPE)('AFP');
    } );

    let primaryOps = getAllPrimaryOperations().filter((operation) => {
      return primaryOpsIds.indexOf(operation.value) >= 0;
    } ) || [];


    if (isEditMode() && itemToEdit.operations) {

      //current station's operations without primary operations
      secondaryInitialOps = itemToEdit.operations
        .filter((operation) => {return primaryOpsIds.indexOf(operation.id) < 0; })
        .sort((op1, op2) => {return op1.position < op2.position ? -1 : 1})

      secondaryInitialOps.forEach((item) => {
        idsInUse.push(item.id);
      } );
    }

      setOptionsInUse(idsInUse);
      setCurrentPrimaryOperationsIds(primaryOpsIds);
      setPrimaryOperations(primaryOps);
      setCurrentPreselectedOperations(secondaryInitialOps);
      setSecondaryOperations(secondaryOps);
      setStationType(getInitialStationType());
      setIsAvailableOverTime(itemToEdit?.isOvertimeEnabled);
      setIsCycleDurationEnabled(itemToEdit?.isAllowCycleDuration);
      setIsResource(itemToEdit?.isResource);
      setUpdateKey(updateKey => 1 - updateKey);
  }

  const getInitialStationType = () => {
    if (isEditMode()) {
      return itemToEdit.locationType;
    }
    //by default the 'General' type is selected
    let found = stationTypes.find((item) => {
      return item.value === getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL')
    });
    return found ? found.value : null;
  }

  const getInitialPrimaryOperations = (operations) => {
    if (operations) {

      let primaryOpsIds = getPrimaryOperationsIdsByStationType(getInitialStationType());

      let res = operations
        .filter((operation) => {
          return primaryOpsIds.indexOf(operation.id) > -1
        })
        .sort((op1, op2) => {
          return op1.position < op2.position ? -1 : 1
        })
        .map((operation) => {
          return ({operation: operation.id,});
        });
      return res;
    }
    return null;
  }

  const getInitialSecondaryOperations = (operations) => {
    if (operations) {

      let primaryOps = getPrimaryOperationsIdsByStationType(getInitialStationType());
      let res = operations
        .filter((operation) => {
          return primaryOps.indexOf(operation.id) < 0
        })
        .sort((op1, op2) => {
          return op1.position < op2.position ? -1 : 1
        })
        .map((operation) => {
          return ({operation: operation.id,});
        });
      return res;
    }
    return [];
  }

  const getOperationTypeById = (operationId) => {
    let operation = getAllOperations().find((op) => {
      return op.value === operationId
    });
    return operation ? operation.data.operationType : null;
  };

  const onSubmit = (data) => {
    let operations = [];

    //up to 2 operations will be shown as buttons (primary), others - as a kebab menu items.
    //Cut, Kit operations will be shown as Tabs
    let operationsAsButtons = 0;
    let operationPosition = 0;

    if (data.primaryOperations) {
      data.primaryOperations.forEach((op) => {
        let type = getOperationTypeById(op.operation);
        if (type !== getEnumValue(enumTypes.OPERATION_TYPE)('KIT') && type !== getEnumValue(enumTypes.OPERATION_TYPE)('CUT')) {
          operationsAsButtons++;
        }
        operations.push({operationId: op.operation, isPrimary: true, position: ++operationPosition})
      })
    }

    if (data.secondaryOperations) {
      data.secondaryOperations.forEach((op) => {
        let type = getOperationTypeById(op.operation);
        if (type === getEnumValue(enumTypes.OPERATION_TYPE)('KIT') || type === getEnumValue(enumTypes.OPERATION_TYPE)('CUT')) {
          operations.push({operationId: op.operation, isPrimary: true, position: ++operationPosition})
        }
        else {
          operationsAsButtons++;
          operations.push({operationId: op.operation, isPrimary: (operationsAsButtons < 3), position: ++operationPosition})
        }
      })
    }
    let newData = {
      automationType: data.automationType,
      name: data.stationName,
      locationType: data.locationType,
      stationOperations: operations,
      isResource: data.isResource,
      jointStartTime: data.jointStartTime,
      overtimeEnabled:  data.overtimeEnabled,
      maxOvertimeValue: DateTimeHelper.ConvertHoursMinutesToMinutes(data.overTime_hours, data.overTime_minutes),
      isAllowCycleDuration: data.cycleDurationEnabled,
      cycleDuration: DateTimeHelper.ConvertDaysHoursMinutesToMinutes(data.cycleDuration_days, data.cycleDuration_hours, data.cycleDuration_minutes),
      capacity: data.capacity ? data.capacity : null,
      tokenCapacity: data.tokenCapacity ? data.tokenCapacity : null,
      isOutsourced: data.isOutsourced,
      parentId: itemToEdit.parentId,
      prevSiblingId: null,
      nextSiblingId: null,
    };

    newData.totalVacuumPorts = stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CURING') ? data.totalVacuumPorts : null;
    newData.activeVacuumPorts = stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CURING') ? data.activeVacuumPorts : null;

    if (isEditMode()) {
      newData.id = itemToEdit.id;
      newData.parentId = null;  // do not send parentId on Edit (Server side request)
    }

    actions?.viewActions?.submit(newData, isEditMode(), itemToEdit.isStation,  false)
      .then((response) => {
        if (response && response.success) {
          onSave?.(response.data);
        }
      });
  };

  const onOpenInNewTabHandler = () => {
    openLocationInNewTab(itemToEdit?.id);
  }

  const onDeleteHandler = () => {
    actions?.viewActions?.delete(itemToEdit.id).then((response) => {
      if (response && response.success) {
        onDelete?.();
      }
    });
  }

  const getPrimaryOperationsIdsByStationType = (stationType) => {
    let primaryOps = typeToOpsMap.get(stationType);
    let primaryOperationsIds = getAllOperations()
      .filter((op) => {
        return primaryOps?.indexOf(op.data.operationType) >= 0
      })
      .map((op) => {
        return op.value
      });

    return primaryOperationsIds;
  }

  const onAutomationTypeChange = (newValue, oldValue) => {
    if ( !newValue && oldValue && oldValue.value) {
      //prevent clearing
      change('automationType', oldValue.value);
    }
  }

  const onStationTypeChange = (newValue, oldValue) => {

    //prevent clearing the 'length' field when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let newStationType = newValue ? newValue.value : null;

    let primaryOperationsIds = getPrimaryOperationsIdsByStationType(newStationType);

    let secondaryOps = getAllOperations().filter((o) => {
      return primaryOperationsIds.indexOf(o.value) < 0 && o.data?.operationType !== getEnumValue(enumTypes.OPERATION_TYPE)('AFP');
    } );

    let curPrimaryOps = getAllPrimaryOperations().filter((operation) => {
      return primaryOperationsIds.indexOf(operation.value) >= 0;
    } );

    let primaryOps = typeToOpsMap.get(newStationType);
    let res = primaryOps?.map((po) => {
      let found  =  curPrimaryOps.find((o) => {
        return o.data.operationType === po});

      return found ? { operation: found.data.id } : null;
    });

    change('primaryOperations', res);
    change('secondaryOperations', []);


    setOptionsInUse([]);
    setCurrentPrimaryOperationsIds(primaryOperationsIds);
    setPrimaryOperations(curPrimaryOps);
    setSecondaryOperations(secondaryOps);
    setStationType(newStationType);
  };

  //----------------------

  const getOptionsInUse = (itemIndex) => {

    let idsInUse = [...optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  const getAvailableOptions = (itemIndex) => {
    let allOptions = secondaryOperations;
    let optionsInUse2 = getOptionsInUse(itemIndex);   //????????

    return allOptions.filter((option) => {
      return optionsInUse2.indexOf(option.value) < 0;
    }).map(op => {
      if(op.data.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('CUT')){
        return {
          ...op,
          label: `${op.data.displayName} (Primary tab)`
        }
      }
      return op;
    });
  };

  const onPrimaryOpChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...currentPrimaryOperationsIds];

    if (oldValue) { //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      idsInUse[index] = value.value;
    }

    setCurrentPrimaryOperationsIds(idsInUse);
  };

  const onOperationChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...optionsInUse];

    if (oldValue) { //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      idsInUse[index] = value.value;
    }

    setOptionsInUse(idsInUse);
  };

  const onRemoveCallback = (index) => {
    let idsInUse = [...optionsInUse];

    if (index > -1) {
      idsInUse.splice(index, 1);
    }

    setOptionsInUse(idsInUse);
  };

  const onAddCallback = () => {
    let idsInUse = [...optionsInUse];

    idsInUse.push(null); //hold index in optionsInUse for the new added item

    setOptionsInUse(idsInUse);
  };

  const listItemRenderer = (data) => {
    return (
      <OperationListItem
        {...data}
        onChangeCallback={onOperationChangeCallback}
        options={getAvailableOptions(data.index)}
        stationType={stationType}
        getOperationTypeById={getOperationTypeById}
        labels={labels}
        touch={touch}
      />
    )
  };

  const renderPrimaryOperations = () => {
    if (stationType === getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL')) {
      return null;
    }
    let primeOps = typeToOpsMap.get(stationType);

    //todo - replace operationName with operationType after lookup service is in
    let res = primeOps?.map((op, index)=> {

      const options = primaryOperations.filter((o) => {
        return o.data.operationType === op
      }).map(primaryOp => {
        if(stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT') ||
           stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT') ||
           stationType === getEnumValue(enumTypes.LOCATION_TYPE)('KIT') ) {
          return {
            ...primaryOp,
            label: `${primaryOp.data.displayName} (Primary tab)`
          }
        }
        return primaryOp;
      })

      return (
        <OperationListItem name={'primaryOperations.[' + index + ']'}
                           id={'primaryOperations.[' + index + ']'}
                           key={'primaryOperation'+ index}
                           index={index}
                           options={options}
                           onChangeCallback={onPrimaryOpChangeCallback}
        />
      )

    });

    return <div className="primary-operations-list">{res}</div>
  };

  const onIsResourceCheckboxClick = (event)=>{
    setIsResource(event.target.checked);
    if (!event.target.checked) {
      change('jointStartTime', false);
    }
  };

  const renderOperationSection = () => {
    return(
      <div>
        <div className="list-operations-title input-section">
          <label className="column">{labels.get('operations')}</label>
        </div>

        {renderPrimaryOperations()}

        <List name='secondaryOperations'
                       id='secondaryOperations'
                       itemRenderer={listItemRenderer}
                       updateKey={updateKey}
                       preSelectedItems={isEditMode() ? currentPreselectedOperations : []}
                       onRemoveCallback={onRemoveCallback}
                       onAddCallback={onAddCallback}
                       addButtonLabel={labels.get('addoperation')}
        />
      </div>
    )
  };

  const allowOverTimeClick = (event) => {
    setIsAvailableOverTime(event?.target?.checked);
    change('overTime_hours', '');
    change('overTime_minutes', '');
  };

  const onCycleDurationEnabledClick = (event) => {
    setIsCycleDurationEnabled(event?.target?.checked);
    change('cycleDuration_days', '');
    change('cycleDuration_hours', '');
    change('cycleDuration_minutes', '');
  };

  const renderApplyAutomationSection = () => {
    return (
      <InputSection  className="inline no-margin right-side two-items-section section-with-checkbox">
        <InputSection label={labels.get('applyAutomation')} htmlFor="automationType" className='no-margin'>
          <Dropdown id="automationType" name="automationType"
                    onChangeCallback={onAutomationTypeChange}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(automationTypes)}
                    placeholder={'Select Automation'}
                    options={automationTypes}/>
        </InputSection>

        <Checkbox id="isOutsourced" name="isOutsourced" label={labels.get('outsource')} />
      </InputSection>
    )
  }

  const renderTokenCapacity = (classNames = '') => {
    return (
      <InputSection label={labels.get('tokenCapacity')} htmlFor="tokenCapacity" className={classNames}>
        <TextField id="tokenCapacity" name="tokenCapacity"
                   normalize={Normalize.number(true, 1, 9999)}/>
      </InputSection>
    )
  }

  const renderVacuumPorts = () => {
    if (stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CURING') ) {
      return (
        <InputSection label={labels.get('vacuumPorts')} className="inline right-side">
          <div className="many-items-section">
            <label>{labels.get('vacuumPorts.total')}</label>
            <TextField id="totalVacuumPorts" name="totalVacuumPorts" className="short-textfield"
                       validate={validateTotalPortsValue}
                       normalize={Normalize.number(true, 0, 999)} />
            <label>{labels.get('vacuumPorts.active')}</label>
            <TextField id="activeVacuumPorts" name="activeVacuumPorts" className="short-textfield"
                       validate={validateActivePortsValue}
                       normalize={Normalize.number(true, 0, 999)} />
          </div>
        </InputSection>
      );
    }
    return null;
  }

  const renderSchedulerDataSection = () => {
    if (PermissionManager.getOrgPreferences().schedulerEnabled) {
      return (
        <>
          <InputSection htmlFor="isResource" label={' '} className="inline left-side two-items-section resource-section">
            <Checkbox name="isResource" id="isResource"  label={labels.get('isResource')} onClick={onIsResourceCheckboxClick} />
            <Checkbox name="jointStartTime" id="jointStartTime"  label={labels.get('jointStartTimes')} disabled={!isResource} />
          </InputSection>

          {renderApplyAutomationSection()}

          <InputSection htmlFor="overtimeEnabled" label={' '} className="inline left-side two-items-section">
            <Checkbox onClick={allowOverTimeClick} name="overtimeEnabled" id="overtimeEnabled"  label={labels.get('allowOverTime')}  />
            <TimeField id="overTime" name="overTime" className='over-time'
                       disabled={!isAvailableOverTime}
                       validate={isAvailableOverTime ? [validateOverTimeRequired, validateOverTimeMin] : undefined}
                       hoursCount={true} maxHoursLength={2}/>
          </InputSection>

          <InputSection  className="inline right-side capacity-section two-items-section">
            <InputSection label={labels.get('capacity')} htmlFor="capacity" className="no-margin">
              <TextField id="capacity" name="capacity"
                         normalize={Normalize.number(true, 1, 100)}/>
            </InputSection>

            {renderTokenCapacity('no-margin')}
          </InputSection>

          <InputSection htmlFor="cycleDurationEnabled" label={' '} className="inline left-side two-items-section">
            <Checkbox onClick={onCycleDurationEnabledClick} name="cycleDurationEnabled" id="cycleDurationEnabled"  label={labels.get('cycleDuration')}  />
            <TimeField id="cycleDuration" name="cycleDuration" className='cycle-duration'
                       disabled={!isCycleDurationEnabled}
                       validate={isCycleDurationEnabled ? [validateCycleDurationRequired] : undefined}
                       isDays={true} hoursCount={true}  />
          </InputSection>

          {renderVacuumPorts()}
        </>);
    }
    return null;
  };

  const getHeaderItems = () => {
    return {
      title: isEditMode() ? labels.get('header.title.edit') : labels.get('header.title.create'),
      buttons: [
        ...(isEditMode() ? [
          {
            id: 'openInNewTab',
            icon: 'pl pl-export-icon',
            tooltip: headerItemsLabels.OPEN_IN_NEW_TAB,
            action: onOpenInNewTabHandler
          }]: []),
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: pristine,
          action: handleSubmit(onSubmit),
        },
        ...(isEditMode() ? [
          {
            id: 'delete',
            label: headerItemsLabels.DELETE,
            className: 'no-icon',
            action: onDeleteHandler
          }]: []),
      ]
    }
  };

    let headerItems = getHeaderItems();

    return (
      <div className='station-settings-form'>

        <Header {...headerItems}/>

        <div className='dialog'>
          <div className="modal-body">
            <InputSection label={labels.get('stationname')+'*'} htmlFor="stationName" className="inline left-side">
              <TextField id="stationName" name="stationName"
                         maxLength={50}
                         validate={[Validation.required, maxLength50]}/>
            </InputSection>
            <InputSection label={labels.get('stationtype')+'*'} htmlFor="locationType" className="inline right-side">
              <Combobox id="locationType" name="locationType"
                        options={stationTypes}
                        onChangeCallback={onStationTypeChange}
                        parse={Parse.comboValueOnly()}
                        format={Format.findOptionByValue(stationTypes)}
                        validate={Validation.dropdown.required}
              />
            </InputSection>

            {!PermissionManager.getOrgPreferences().schedulerEnabled && renderTokenCapacity('inline left-side')}

            {!PermissionManager.getOrgPreferences().schedulerEnabled && renderApplyAutomationSection()}

            {renderSchedulerDataSection()}

            {renderOperationSection()}

          </div>
        </div>
      </div>
    );
}

StationSettingsForm.propTypes = {
  itemToEdit : PropTypes.object,
  sData: PropTypes.object,
  actions : PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  pristine: PropTypes.bool,

  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default reduxForm({
    form: 'stationSettingsForm',
  }
)(StationSettingsForm);







