import Network from 'infrastructure/js/modules/network';
import * as schedulerService from '../../services/Scheduler/schedulerService';
import { autoSchedulingStatuses } from '../../enums/autoSchedulingStatuses';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as activityLogDialogApi } from '../ActivityLogDialog/activityLogDialogActions.js';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCHEDULER_ASYNC_STATUS: 'SCHEDULER_ASYNC_STATUS',
  SCHEDULER_ASYNC_ABORTING: 'SCHEDULER_ASYNC_ABORTING',
  SCHEDULER_PAGE_RESET_DATA: 'SCHEDULER_PAGE_RESET_DATA',
  SCHEDULER_SET_LOADING: 'SCHEDULER_SET_LOADING',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  setAsyncSchedulingStatus(payload) {
    return { type: actionTypes.SCHEDULER_ASYNC_STATUS, payload };
  },
  setAsyncSchedulingAborting(payload) {
    return { type: actionTypes.SCHEDULER_ASYNC_ABORTING, payload };
  },
  resetState: function (payload) {
    return { type: actionTypes.SCHEDULER_PAGE_RESET_DATA, payload: payload };
  },
  setLoading: function (payload) {
    return { type: actionTypes.SCHEDULER_SET_LOADING, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.setLoading = function (dispatch) {
  return function (isLoading) {
    dispatch(actions.setLoading(isLoading));
  };
};

api.resetState = function (dispatch) {
  return function () {
    dispatch(actions.resetState());
  };
};

api.pollSchedulingStatus = function (dispatch, getState) {
  return function (onCompleteCB) {
    return schedulerService
      .fetchSchedulingStatus()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Scheduling Status Failed');
          return;
        }

        const { BUSY, COMPLETED, ABORTED } = autoSchedulingStatuses;

        let statusPollingInterval = getState().predefinedList.getIn(['scheduler', 'asyncScheduling', 'statusPollingInterval']);
        const isAborting = getState().predefinedList.getIn(['scheduler', 'asyncScheduling', 'isAborting']);

        const isInProgress = response.data?.status === BUSY;
        const isCompleted = response.data?.status === COMPLETED;

        if (isAborting && !isInProgress) {
          dispatch(actions.setAsyncSchedulingAborting(false));
        }

        if (isInProgress && !statusPollingInterval) {
          statusPollingInterval = setInterval(() => {
            api.pollSchedulingStatus(dispatch, getState)(onCompleteCB);
          }, 10000);
        } else if (!isInProgress && statusPollingInterval) {
          clearInterval(statusPollingInterval);
          statusPollingInterval = null;
        }

        if (isCompleted) {
          onCompleteCB?.();
        }

        const schedulingStatusData = {
          status: response.data?.status,
          summary: response.data?.summaryMessage?.params,
          activityLogId: response.data?.schedulingInsightsJobId,
          scheduledBy: response.data?.scheduledBy,
          statusPollingInterval,
        };

        dispatch(actions.setAsyncSchedulingStatus(schedulingStatusData));
      })
      .catch((err) => {
        console.error('Fetch Scheduling Status Failed ', err);
        return { success: false };
      });
  };
};

api.abortAsyncScheduling = function (dispatch, getState) {
  return function () {
    dispatch(actions.setAsyncSchedulingAborting(true));

    return schedulerService
      .abortAsyncScheduling()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.setAsyncSchedulingAborting(false));
          console.error('Abort Async Scheduling Failed');
          return;
        }
      })
      .catch((err) => {
        dispatch(actions.setAsyncSchedulingAborting(false));
        console.error('Abort Async Scheduling Failed ', err);
        return { success: false };
      });
  };
};

api.lock = function (dispatch, getState) {
  return function (tasksAssignmentsIds, originalTimestamp = null) {
    dispatch(actions.setLoading(true));

    let queryData = {
      tasksAssignmentsIds,
      originalTimestamp,
    };

    return schedulerService
      .lock(queryData)
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - lock failed', response);
        }

        return response;
      })
      .catch((err) => {
        console.error('scheduler - lock failed ', err);
        return { success: false };
      });
  };
};

api.unlock = function (dispatch, getState) {
  return function (tasksAssignmentsIds, originalTimestamp = null) {
    dispatch(actions.setLoading(true));

    let queryData = {
      tasksAssignmentsIds,
      originalTimestamp,
    };

    return schedulerService
      .unlock(queryData)
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unlock failed', response);
        }

        return response;
      })
      .catch((err) => {
        console.error('scheduler - unlock failed ', err);
        return { success: false };
      });
  };
};

api.unAssign = function (dispatch, getState) {
  return function (tasksAssignmentsIds, originalTimestamp = null) {
    let queryData = {
      tasksAssignmentsIds,
      originalTimestamp,
    };

    return schedulerService
      .unAssign(queryData)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unAssign Failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return response;
        }

        let errorCode = response?.applicationResponseStatus?.warnings?.mainWarning?.id;
        if (errorCode) {
          let desc = _getUnAssignMessageDialogBuilder(response.applicationResponseStatus.warnings.mainWarning);
          messageDialogApi.open(dispatch, getState)(desc);
          return response;
        }

        messageDialogApi.showSuccess(dispatch, getState)('mat.scheduler.operations.view.delete.confirmation.success.message');

        return response;
      })
      .catch((err) => {
        console.error('Fetch Scheduler data failed ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.showAsyncSchedulingActivityLog = function (dispatch, getState) {
  return function () {
    const activityLogTitle = createLabelHelper('').get('mat.scheduler.common.insightsDialog.title');
    let activityLogId = getState().predefinedList.getIn(['scheduler', 'asyncScheduling', 'activityLogId']);

    activityLogDialogApi.setConfigData(dispatch, getState)({ activityLogId, activityLogTitle });

    activityLogDialogApi.show(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////
// PRIVATE METHODS - PRIVATE, FOR THIS MODULE ONLY

function _getUnAssignMessageDialogBuilder(warning) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.warning.title');
  let message = createLabelHelper('').get(warning.id, undefined, warning.params || undefined);
  let children = [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message} />];
  let className = 'scheduler-page-message-dialog';
  let buttons = [];
  let messageDialogDescriptor = { title, buttons, children, className, type: 'error' };
  return messageDialogDescriptor;
}
