import React from 'react';
import {reduxForm} from 'redux-form';
import Page from '../Common/Page/page';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import ShipmentCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/shipmentCsvSettingsViewContainer';
import RollCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/rollCsvSettingsViewContainer';
import RollUsageCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/rollUsageCsvSettingsViewContainer';
import SpoolCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/spoolCsvSettingsViewContainer';
import KitCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/kitCsvSettingsViewContainer';
import ToolCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/toolCsvSettingsViewContainer';
import WorkOrderCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/workOrderCsvSettingsViewContainer';
import KitTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/kitTypeCsvSettingsViewContainer';
import PlyTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/plyTypeCsvSettingsViewContainer';
import BagCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/bagCsvSettingsViewContainer';
import KitTypePlyTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/kitTypePlyTypeCsvSettingsViewContainer';
import ToolTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/toolTypeCsvSettingsViewContainer';
import MaterialTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/materialTypeCsvSettingsViewContainer'
import TaskCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/taskCsvSettingsViewContainer';
import RollPdfLabelSettingsViewContainer from '../../../containers/Administration/SettingsPage/PdfLabelSettings/rollPdfLabelSettingsViewContainer';
import KitPdfLabelSettingsViewContainer from '../../../containers/Administration/SettingsPage/PdfLabelSettings/kitPdfSettingsSettingsViewContainer';
import SystemPreferencesViewContainer from '../../../containers/Administration/SettingsPage/SystemPreferences/systemPreferencesViewContainer.js';
import SchedulingPreferencesViewContainer from '../../../containers/Administration/SettingsPage/SchedulingPreferences/schedulingPreferencesViewContainer';
import ExposureTimeCureViewContainer from '../../../containers/Administration/SettingsPage/Alerts/exposureTimeCureViewContainer';
import ExposureTimeBondViewContainer from '../../../containers/Administration/SettingsPage/Alerts/exposureTimeBondViewContainer';
import ExpirationDateViewContainer from '../../../containers/Administration/SettingsPage/Alerts/expirationDateViewContainer';
import WoDueDateViewContainer from '../../../containers/Administration/SettingsPage/Alerts/woDueDateViewContainer';
import DefrostingTimeViewContainer from '../../../containers/Administration/SettingsPage/Alerts/defrostingTimeViewContainer';
import ToolCyclesViewContainer from '../../../containers/Administration/SettingsPage/Alerts/toolCyclesViewContainer';
import ToolUsageTimeViewContainer from '../../../containers/Administration/SettingsPage/Alerts/toolUsageTimeViewContainer';
import SchedulerAlertsViewContainer from '../../../containers/Administration/SettingsPage/Alerts/schedulerAlertsViewContainer';
import MisplacedMaterialViewContainer from  '../../../containers/Administration/SettingsPage/Alerts/misplacedMaterialViewContainer'
import SensorsViewContainer from '../../../containers/Administration/SettingsPage/Sensors/sensorsViewContainer';
import RollAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/rollAdditionalFieldsViewContainer';
import SpoolAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/spoolAdditionalFieldsViewContainer';
import KitAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/kitAdditionalFieldsViewContainer';
import KitTypeAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/kitTypeAdditionalFieldsViewContainer';
import ToolAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/toolAdditionalFieldsViewContainer';
import WoAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/woAdditionalFieldsViewContainer';
import TerminologyViewContainer from '../../../containers/Administration/SettingsPage/TerminologySettings/terminologyViewContainer';
import PageNotFoundPageContainer from '../../../containers/PageNotFoundPage/pageNotFoundPageContainer.js';
import {SettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import GroupAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/groupAdditionalFieldsViewContainer';
import GroupCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/groupCsvSettingsViewContainer';
import GroupTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/groupTypeCsvSettingsViewContainer';
import IPWhitelistViewContainer from '../../../containers/Administration/SettingsPage/AccessControl/ipWhitelistViewContainer';
import {getRoutesFor, getSideNavigatorMenuItems} from '../../../utils/routerHelper';
import SpoolPdfLabelSettingsViewContainer from '../../../containers/Administration/SettingsPage/PdfLabelSettings/spoolPdfLabelSettingsViewContainer';
import SpoolBarcodeSettingsContainer from '../../../containers/Administration/SettingsPage/BarcodeSettings/spoolBarcodeSettingsContainer';
import RollBarcodeSettingsContainer from '../../../containers/Administration/SettingsPage/BarcodeSettings/rollBarcodeSettingsContainer';
import PartTypeCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/partTypeCsvSettingsViewContainer';
import ProjectCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/projectCsvSettingsViewContainer';
import GroupPdfLabelSettingsViewContainer from '../../../containers/Administration/SettingsPage/PdfLabelSettings/groupPdfSettingsViewContainer';
import ReservedViewContainer from '../../../containers/Administration/SettingsPage/Alerts/reservedViewContainer';
import AsnCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/asnCsvSettingsViewContainer';
import ResinCsvSettingsViewContainer from '../../../containers/Administration/SettingsPage/CsvSettings/resinCsvSettingsViewContainer';
import ResinPdfLabelSettingsViewContainer from '../../../containers/Administration/SettingsPage/PdfLabelSettings/resinPdfLabelSettingsViewContainer';
import ResinAdditionalFieldsViewContainer from '../../../containers/Administration/SettingsPage/AdditionalFields/resinAdditionalFieldsViewContainer';
import ResinBarcodeSettingsContainer from '../../../containers/Administration/SettingsPage/BarcodeSettings/resinBarcodeSettingsContainer';
import SchedulingAutomationView from './Scheduling/SchedulingAutomation/schedulingAutomationView';

require('./settingsPage.scss');

class SettingsPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.settings.sidenavigator.');
    this.isAuthorizedUser = PermissionManager.hasPreferencesSettingsTabPermissions();
    this.navigationItems = this.getNavigationItems();
  }

  componentDidMount() {
    this.props.actions.init(true);
  }


  isExpanded(str) {
    return window.location.href.includes(str);
  }


  getNavigationItems() {
    let items = [];

    if (this.isAuthorizedUser) {
      items.push({
        id: SettingsMenuItemsIds.NAV_ELEMENT_SYSTEM_PREFERENCES,
        label: this.labels.get('menuitem.systemPreferences'),
        link: '/Administration/settings/systemPreferences',
        component: SystemPreferencesViewContainer,
        isAllowed: true,
      });
    }

    if (PermissionManager.hasBasicSettingsTabPermissions()) {
      if (PermissionManager.getOrgPreferences().schedulerEnabled) {
        items.push(
          {
            id: SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING,
            label: this.labels.get('menuitem.scheduling'),
            expanded: (!this.isAuthorizedUser && !PermissionManager.getOrgPreferences().schedulerEnabled) || this.isExpanded('/Administration/settings/scheduling/'),
            isAllowed: true,
            items: [
              {
                id: SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING_PREFERENCES,
                label: this.labels.get('menuitem.schedulingPreferences'),
                link: '/Administration/settings/schedulingPreferences',
                component: SchedulingPreferencesViewContainer,
                isAllowed: true,
              },
              {
                id: SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING_AUTOMATION,
                label: this.labels.get('menuitem.schedulingAutomation'),
                link: '/Administration/settings/schedulingAutomation',
                component: SchedulingAutomationView,
                isAllowed: true,
              },
            ]
            });
      }

      items.push(
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS,
          label: this.labels.get('menuitem.alerts'),
          expanded: (!this.isAuthorizedUser && !PermissionManager.getOrgPreferences().schedulerEnabled) || this.isExpanded('/Administration/settings/alerts/'),
          isAllowed: true,
          items: [
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_MISPLACED_MATERIAL,
              label: this.labels.get('menuitem.alerts.children.misplacedMaterial'),
              link: '/Administration/settings/alerts/misplacedMaterial',
              component: MisplacedMaterialViewContainer,
              isAllowed: true,
            },
            ...(PermissionManager.isEtlBondEnabled() ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPOSURE_TIME_BOND,
              label: this.labels.get('menuitem.alerts.children.exposuretime.bond'),
              link: '/Administration/settings/alerts/exposureTimeBond',
              component: ExposureTimeBondViewContainer,
              isAllowed: true,
            }] : []),
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPOSURE_TIME_CURE,
              label: this.labels.get('menuitem.alerts.children.exposuretime.cure'),
              link: '/Administration/settings/alerts/exposureTimeCure',
              component: ExposureTimeCureViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPIRATION_DATE,
              label: this.labels.get('menuitem.alerts.children.expirationdate'),
              link: '/Administration/settings/alerts/expirationDate',
              component: ExpirationDateViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_RESERVED,
              label: this.labels.get('menuitem.alerts.children.reserved'),
              link: '/Administration/settings/alerts/reserved',
              component: ReservedViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_WO_DUE_DATE,
              label: this.labels.get('menuitem.alerts.children.woDueData'),
              link: '/Administration/settings/alerts/woDueDate',
              component: WoDueDateViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_TOOL_CYCLES,
              label: this.labels.get('menuitem.alerts.children.toolCycles'),
              link: '/Administration/settings/alerts/toolCycles',
              component: ToolCyclesViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_TOOL_USAGE_TIME,
              label: this.labels.get('menuitem.alerts.children.toolUsageTime'),
              link: '/Administration/settings/alerts/toolUsageTime',
              component: ToolUsageTimeViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_DEFROSTING_TIME,
              label: this.labels.get('menuitem.alerts.children.defrostingTime'),
              link: '/Administration/settings/alerts/defrostingTime',
              component: DefrostingTimeViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_SCHEDULER,
              label: this.labels.get('menuitem.alerts.children.scheduler'),
              link: '/Administration/settings/alerts/scheduler',
              component: SchedulerAlertsViewContainer,
              isAllowed: true,
            },
          ]
        },
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_CSV,
          label: this.labels.get('menuitem.csv'),
          expanded: this.isExpanded('/Administration/settings/csv/'),
          isAllowed: true,
          items: [
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_ASN,
              label: this.labels.get('menuitem.settings.children.asn'),
              link: '/Administration/settings/csv/asn',
              component: AsnCsvSettingsViewContainer,
              isAllowed: true,
            },

            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_SHIPMENT,
              label: this.labels.get('menuitem.settings.children.shipment'),
              link: '/Administration/settings/csv/shipment',
              component: ShipmentCsvSettingsViewContainer,
              isAllowed: true,
            },

            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_ROLL,
              label: this.labels.get('menuitem.settings.children.roll'),
              link: '/Administration/settings/csv/roll',
              component: RollCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_ROLL_USAGE,
              label: this.labels.get('menuitem.settings.children.rollUsage'),
              link: '/Administration/settings/csv/rollUsage',
              component: RollUsageCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_SPOOL,
              label: this.labels.get('menuitem.settings.children.spool'),
              link: '/Administration/settings/csv/spool',
              component: SpoolCsvSettingsViewContainer,
              isAllowed: true,
            },
            ...(PermissionManager.isResinsEnabled() ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_RESIN,
              label: this.labels.get('menuitem.settings.children.resin'),
              link: '/Administration/settings/csv/resin',
              component: ResinCsvSettingsViewContainer,
              isAllowed: true,
            }] : []),
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_KIT,
              label: this.labels.get('menuitem.settings.children.kit'),
              link: '/Administration/settings/csv/kit',
              component: KitCsvSettingsViewContainer,
              isAllowed: true,
            },
            ...(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_GROUP,
              label: this.labels.get('menuitem.settings.children.group'),
              link: '/Administration/settings/csv/group',
              component: GroupCsvSettingsViewContainer,
              isAllowed: true,
            }] : []),
            ...(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_GROUP_TYPE,
              label: this.labels.get('menuitem.settings.children.groupType'),
              link: '/Administration/settings/csv/groupType',
              component: GroupTypeCsvSettingsViewContainer,
              isAllowed: true,
            }] : []),

            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_TOOL,
              label: this.labels.get('menuitem.settings.children.tool'),
              link: '/Administration/settings/csv/tool',
              component: ToolCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_WO,
              label: this.labels.get('menuitem.settings.children.wo'),
              link: '/Administration/settings/csv/workOrder',
              component: WorkOrderCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_TASK,
              label: this.labels.get('menuitem.settings.children.task'),
              link: '/Administration/settings/csv/task',
              component: TaskCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_KIT_TYPE,
              label: this.labels.get('menuitem.settings.children.kitType'),
              link: '/Administration/settings/csv/kitType',
              component: KitTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_BAG,
              label: this.labels.get('menuitem.settings.children.bag'),
              link: '/Administration/settings/csv/bag',
              component: BagCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_PLY_TYPE,
              label: this.labels.get('menuitem.settings.children.plyType'),
              link: '/Administration/settings/csv/plyType',
              component: PlyTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_KIT_TYPE_PLY_TYPE,
              label: this.labels.get('menuitem.settings.children.kitTypePlyType'),
              link: '/Administration/settings/csv/kitTypePlyType',
              component: KitTypePlyTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_TOOL_TYPE,
              label: this.labels.get('menuitem.settings.children.toolType'),
              link: '/Administration/settings/csv/toolType',
              component: ToolTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_MATERIAL_TYPE,
              label: this.labels.get('menuitem.settings.children.materialType'),
              link: '/Administration/settings/csv/materialType',
              component: MaterialTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_PART_TYPE,
              label: this.labels.get('menuitem.settings.children.partType'),
              link: '/Administration/settings/csv/partType',
              component: PartTypeCsvSettingsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_CSV_PROJECT,
              label: this.labels.get('menuitem.settings.children.project'),
              link: '/Administration/settings/csv/project',
              component: ProjectCsvSettingsViewContainer,
              isAllowed: true,
            }
          ]
        },
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_BARCODE,
          label: this.labels.get('menuitem.barcode'),
          expanded: this.isExpanded('/Administration/settings/barcode/'),
          isAllowed: true,
          items: [
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_BARCODE_ROLL,
              label: this.labels.get('menuitem.barcode.children.rolls'),
              link: '/Administration/settings/barcode/roll',
              component: RollBarcodeSettingsContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_BARCODE_SPOOL,
              label: this.labels.get('menuitem.barcode.children.spools'),
              link: '/Administration/settings/barcode/spool',
              component: SpoolBarcodeSettingsContainer,
              isAllowed: true,
            },
            ...(PermissionManager.isResinsEnabled() ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_BARCODE_RESIN,
              label: this.labels.get('menuitem.barcode.children.resins'),
              link: '/Administration/settings/barcode/resin',
              component: ResinBarcodeSettingsContainer,
              isAllowed: true,
            }] : []),
          ]
        },
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS,
          label: this.labels.get('menuitem.additionalFields'),
          expanded: this.isExpanded('/Administration/settings/additionalFields/'),
          isAllowed: true,
          items: [
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_ROLL,
              label: this.labels.get('menuitem.settings.children.roll'),
              link: '/Administration/settings/additionalFields/roll',
              component: RollAdditionalFieldsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_SPOOL,
              label: this.labels.get('menuitem.settings.children.spool'),
              link: '/Administration/settings/additionalFields/spool',
              component: SpoolAdditionalFieldsViewContainer,
              isAllowed: true,
            },
            ...(PermissionManager.isResinsEnabled() ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_RESIN,
              label: this.labels.get('menuitem.settings.children.resin'),
              link: '/Administration/settings/additionalFields/resin',
              component: ResinAdditionalFieldsViewContainer,
              isAllowed: true,
            }] : []),
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_KIT,
              label: this.labels.get('menuitem.settings.children.kit'),
              link: '/Administration/settings/additionalFields/kit',
              component: KitAdditionalFieldsViewContainer,
              isAllowed: true,
            },

            ...(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled ? [{
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_GROUP,
              label: this.labels.get('menuitem.settings.children.group'),
              link: '/Administration/settings/additionalFields/group',
              component: GroupAdditionalFieldsViewContainer,
              isAllowed: true,
            }] : []),

            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_TOOL,
              label: this.labels.get('menuitem.settings.children.tool'),
              link: '/Administration/settings/additionalFields/tool',
              component: ToolAdditionalFieldsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_WO,
              label: this.labels.get('menuitem.settings.children.wo'),
              link: '/Administration/settings/additionalFields/wo',
              component: WoAdditionalFieldsViewContainer,
              isAllowed: true,
            },
            {
              id: SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_KIT_TYPE,
              label: this.labels.get('menuitem.settings.children.kitType'),
              link: '/Administration/settings/additionalFields/kitType',
              component: KitTypeAdditionalFieldsViewContainer,
              isAllowed: true,
            },

          ]
        },
      )
    }

    let pdfLabelMenuItem = {
      id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL,
      label: this.labels.get('menuitem.pdfLabel'),
      expanded: this.isExpanded('/Administration/settings/pdfLabel/'),
      isAllowed: true,
      items: [
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_ROLL,
          label: this.labels.get('menuitem.settings.children.roll'),
          link: '/Administration/settings/pdfLabel/roll',
          component: RollPdfLabelSettingsViewContainer,
          isAllowed: true,
        },
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_SPOOL,
          label: this.labels.get('menuitem.settings.children.spool'),
          link: '/Administration/settings/pdfLabel/spool',
          component: SpoolPdfLabelSettingsViewContainer,
          isAllowed: true,
        },
        ...(PermissionManager.isResinsEnabled() ? [{
          id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_RESIN,
          label: this.labels.get('menuitem.settings.children.resin'),
          link: '/Administration/settings/pdfLabel/resin',
          component: ResinPdfLabelSettingsViewContainer,
          isAllowed: true,
        }] : []),
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_KIT,
          label: this.labels.get('menuitem.settings.children.kit'),
          link: '/Administration/settings/pdfLabel/kit',
          component: KitPdfLabelSettingsViewContainer,
          isAllowed: true,
        },
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_GROUP,
          label: this.labels.get('menuitem.settings.children.group'),
          link: '/Administration/settings/pdfLabel/group',
          component: GroupPdfLabelSettingsViewContainer,
          isAllowed: true,
        },
      ]
    };

    if (PermissionManager.hasPdfLabelTabPermissions()) {
      items.push(pdfLabelMenuItem);
    }

    if (PermissionManager.hasTerminologyTabPermissions()) {

      let terminologyMenuItem = {
        id: SettingsMenuItemsIds.NAV_ELEMENT_TERMINOLOGY,
        label: this.labels.get('menuitem.terminology'),
        link: '/Administration/settings/terminology',
        component: TerminologyViewContainer,
        isAllowed: true,
      };

      items.push(terminologyMenuItem);
    }

    let sensorsMenuItem = {
      id: SettingsMenuItemsIds.NAV_ELEMENT_LOGS,
      label: this.labels.get('menuitem.iotLogs'),
      isAllowed: true,
      items: [
        {
          id: SettingsMenuItemsIds.NAV_ELEMENT_SENSORS,
          label: this.labels.get('menuitem.iotLogs.children.sensors'),
          link: '/Administration/settings/sensors',
          component: SensorsViewContainer,
          isAllowed: true,
        },
      ],
    };
    items.push(sensorsMenuItem);

    if (PermissionManager.hasIPWhitelistTabPermissions()) {
      items.push({
        id: SettingsMenuItemsIds.NAV_ELEMENT_ACCESS_CONTROL,
        label: this.labels.get('menuitem.accessControl'),
        isAllowed: true,
        items: [
          {
            id: SettingsMenuItemsIds.NAV_ELEMENT_ACCESS_CONTROL_IP_WHITELIST,
            label: this.labels.get('menuitem.accessControl.ipWhitelist'),
            link: '/Administration/settings/ipWhitelist',
            component: IPWhitelistViewContainer,
            isAllowed: true,
          }
        ]
      });
    }

    return items;
  }

  getDefaultRouteConfig() {
    return {
      key: `${this.navigationItems?.[0]?.id}_default`,
      path: '/Administration/settings',
      redirectPath: this.navigationItems?.[0]?.items?.[0]?.link || this.navigationItems?.[0]?.link,
      routeNotFoundComponent: PageNotFoundPageContainer
    }
  }

  render() {
    return (<Page className="mat-settings-page"
                  selectedMenuItemId={this.props.sData.get('selectedMenuItemId')}
                  sideNavigatorMenuItems={getSideNavigatorMenuItems(this.navigationItems)}
                  actions={this.props.actions}
                  routes={getRoutesFor(this.navigationItems, this.getDefaultRouteConfig())}
    />)
  }
}

export default reduxForm({
  form: 'settingsPage'
})(SettingsPage);

