// The Grids' Async filters, Top Search and the Dialogs' Combo boxes fetch/search the entities
// (rolls, kits, workOrders) by these properties: businessId, name etc.

export const EntityPropertyTypes = {
  ROLL_BUSINESS_ID: 'ROLL_BUSINESS_ID',
  SPOOL_BUSINESS_ID: 'SPOOL_BUSINESS_ID',
  RESIN_BUSINESS_ID: 'RESIN_BUSINESS_ID',
  KIT_BUSINESS_ID: 'KIT_BUSINESS_ID',
  PART_BUSINESS_ID: 'PART_BUSINESS_ID',
  TOOL_BUSINESS_ID: 'TOOL_BUSINESS_ID',
  TOOL_TYPE_BUSINESS_ID: 'TOOL_TYPE_BUSINESS_ID',
  TOOL_GROUP_BUSINESS_ID: 'TOOL_GROUP_BUSINESS_ID',
  GROUP_BUSINESS_ID: 'GROUP_BUSINESS_ID',
  BAG_BUSINESS_ID: 'BAG_BUSINESS_ID',
  PLY_TYPE_BUSINESS_ID: 'PLY_TYPE_BUSINESS_ID',
  ASSETS_BUSINESS_ID: 'ASSETS_BUSINESS_ID',
  REPORTED_CUT: 'REPORTED_CUT',
  LOT_NAME: 'LOT_NAME',

  TAG_BUSINESS_ID: 'TAG_BUSINESS_ID',
  WO_BUSINESS_ID: 'WO_BUSINESS_ID',
  KIT_TYPE_BUSINESS_ID: 'KIT_TYPE_BUSINESS_ID',
  PART_TYPE_BUSINESS_ID: 'PART_TYPE_BUSINESS_ID',
  GROUP_TYPE_BUSINESS_ID: 'GROUP_TYPE_BUSINESS_ID',
  GROUP_TYPE_NAME: 'GROUP_TYPE_NAME',
  MATERIAL_TYPE_BUSINESS_ID: 'MATERIAL_TYPE_BUSINESS_ID',
  MATERIAL_TYPE_NAME: 'MATERIAL_TYPE_NAME',
  MATERIAL_TYPE_NAME_AND_BUSINESS_ID: 'MATERIAL_TYPE_NAME_AND_BUSINESS_ID',
  MATERIAL_TYPE_NAME_AND_BUSINESS_ID_PICK_LIST: 'MATERIAL_TYPE_NAME_AND_BUSINESS_ID_PICK_LIST',
  SHIPMENT_BUSINESS_ID: 'SHIPMENT_BUSINESS_ID',
  SHIPMENT_STATUS: 'SHIPMENT_STATUS',
  SHIPMENT_CREATED_DATE: 'SHIPMENT_CREATED_DATE',
  SHIPMENT_UPDATED_DATE: 'SHIPMENT_UPDATED_DATE',
  SHIPMENT_CREATED_BY: 'SHIPMENT_CREATED_BY',
  PICK_LIST_BUSINESS_ID: 'PICK_LIST_BUSINESS_ID',

  LOCATION_NAME: 'LOCATION_NAME',
  STATION_NAME: 'STATION_NAME',
  OPERATION_NAME: 'OPERATION_NAME',

  SCHEDULER_TASK_NAME: 'SCHEDULER_TASK_NAME',
  SCHEDULER_TASK_WO_BUSINESS_ID: 'SCHEDULER_TASK_WO_BUSINESS_ID',
  SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID: 'SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID',

  USER_NAME: 'USER_NAME',
  USER_FIRST_NAME: 'USER_FIRST_NAME',
  USER_LAST_NAME: 'USER_LAST_NAME',

  ORGS: 'ORGS',
};
