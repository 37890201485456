import {reduxForm} from 'redux-form';
import cn from 'classnames';
import useLabels from 'infrastructure/js/hooks/useLabels';
import Header,  {headerItemsLabels} from '../Header/header';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {Prompt} from 'react-router-dom';
import {jsxActions as updateKitPliesFormActions} from '../../../actions/Forms/updateKitPliesFormActions';
import {jsxActions as messageDialogActions} from '../../../actions/MessageDialog/messageDialogActions';
import useRedux from 'infrastructure/js/hooks/useRedux';
import React, {useEffect, useRef, useState} from 'react';
import Tabs,  { PL_CustomTabs as CustomTabs } from 'infrastructure/js/components/Tabs/tabs';
import KitPly from '../KitPly/kitPly';
import KittedPlyIcon from '../../../../assets/svg/ply-kitted.svg';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import {navigationStates} from '../../../enums/navigationStates';

import './updateKitPliesForm.scss';

const getExpectedPliesNum = (bags = []) => {
  return bags?.reduce((acc, bag) => acc + bag?.plies?.length ?? 0,  0);
}

const getKittedPliesNum = (bags = []) => {
  return bags?.reduce((acc, bag) => acc + bag?.plies?.reduce((accum, ply) => ply.kitted ? accum + 1 : accum, 0), 0);
}

const findPlyInBags = (plyName, bags = []) => {
    for (let bag of bags) {
        const ply = bag?.plies?.find(ply => ply.plyTypeBusinessId === plyName);
        if (ply) {
            return ply;
        }
    }
    return null;
}

const findUnkittedPlyInBags = (plyName, bags = []) => {
    for (let bag of bags) {
        const ply = bag?.plies?.find(ply => ply.plyTypeBusinessId === plyName && !ply.kitted);
        if (ply) {
            return ply;
        }
    }
    return null;
}

const updatePlyInBags = (ply, bags = []) => {
    const {bagId, plyIndex, kitted} = ply;

    const newBags = bags.map(bag =>
        bag.bagId === bagId ? {
            ...bag,
            plies: bag.plies.map((ply, index) => {
                return plyIndex === (index + 1) ? {...ply, kitted: kitted} : ply;
            })
        } : bag
    );
    return newBags;
}

const getState = (state) => {
  return {
    formData: state.forms.getIn(['updateKitPliesForm', 'formData']),
    loading: state.forms.getIn(['updateKitPliesForm', 'loading']),
  }
}

const allActions = {
  updateKitPliesFormActions: updateKitPliesFormActions,
  messageDialogActions: messageDialogActions,
};

const scrollTo = (elementId) => {
  const element = document.getElementById(elementId);
  element?.scrollIntoView({
    behavior: 'smooth'
  });
}

function UpdateKitPliesForm ({ history,
                               match
                            }) {

  const { state, actions } = useRedux(allActions, getState);
  const { formData, loading } = state;

  const labels = useLabels('mat.forms.updateKitPliesForm.');
  const dialogLabels = useLabels('mat.dialog.');
  const updateNavBar = useNavBarUpdate();

  const [bags, setBags] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [confirmedAbandon, setConfirmedAbandon] = useState(false);
  const nextLocationPath = useRef(null);
  const [selectedBagTab, setSelectedBagTab] = useState('0');
  const [scannedPly, setScannedPly] = useState(null);
  const inputRef = useRef(null);
  const noBagsDefined = bags.length === 0 || bags[0].bagId === null;
  const expectedPliesNum = getExpectedPliesNum(bags);
  const kittedPliesNum = getKittedPliesNum(bags);

  useEffect(() => {
    inputRef?.current?.focus();
    let assetId = match.params.assetId;
    actions.updateKitPliesFormActions?.fetchFormData(assetId);
    return () => {
      actions.updateKitPliesFormActions?.clearFormData();
    }
  }, []);

  useEffect(() => {
    let newBags = formData?.bags ?? [];
    setBags(newBags);
    setPristine(formData?.bags && JSON.stringify(formData.bags) === JSON.stringify(newBags));
  }, [formData]);

  useEffect(() => {
    if (confirmedAbandon) {
      if (nextLocationPath.current) {
        history.push(nextLocationPath.current);
      }
      else if (nextLocationPath.current === '') {
        history.goBack()
      }
      nextLocationPath.current = null;
    }
  }, [confirmedAbandon]);

  useEffect(() => {
    if (formData) {
      updateNavBar(navigationStates.KIT, formData.kitBusinessId, formData.kitTypeBusinessId);
    }
  }, [updateNavBar, labels, formData]);

  useEffect(() => {
    if (scannedPly) {
      scrollTo(`${scannedPly.bagId ?? 'ply'}-${scannedPly.plyIndex}`)
    }
  }, [scannedPly]);

  const getHeaderItems = () => {
    return {
      title: labels.get('title'),
      buttons: [
        {
          id: 'cancel',
          label: headerItemsLabels.CANCEL,
          className: 'no-icon',
          action: () => pristine ? history.goBack() :
            actions.updateKitPliesFormActions.abandonDirtyForm(() => {
              setConfirmedAbandon(true);
              nextLocationPath.current = '';
            }),
        },
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          action: onSubmit,
          disabled: pristine || loading,
        },
      ],
    };
  };

  const onPlyClickCallback = (ply) => {
    if (ply.kitted === true) {
      updateBags(ply);
      return;
    }

    //Note: show the confirmation before unkitting
    const confirmationConfig = {
      title: labels.get('remove.title'),
      message: ply.bagBusinessId ? labels.get('remove.plyFromBag', '', {plyName: ply.plyTypeBusinessId, bagName: ply.bagBusinessId}) : labels.get('remove.ply', '', {plyName: ply.plyTypeBusinessId}),
      cancelHandler: actions.messageDialogActions.onHide,
      submitButtonLabel: dialogLabels.get('confirm'),
      submitHandler: () => {updateBags(ply); actions.messageDialogActions.onHide();}
    }

    actions.messageDialogActions.showConfirmation(confirmationConfig);
  }

  const updateBags = (ply) => {
    const newBags = updatePlyInBags(ply, bags);
    setBags(newBags);
    setPristine(formData?.bags && JSON.stringify(formData.bags) === JSON.stringify(newBags));
  };

  const handleScannedData = (plyTypeBusinessId) => {
    let ply = findUnkittedPlyInBags(plyTypeBusinessId, bags);

    if (ply) {
        let newPly = {...ply, kitted: true};
        setScannedPly(newPly);
        updateBags(newPly);
        const index = bags.findIndex(item => item.bagId === newPly.bagId);
        if (index >= 0) {
          setSelectedBagTab(index);
        }
        return;
    }

    //Note: show messages when ply not found
    let anyPly = findPlyInBags(plyTypeBusinessId, bags);

    const message = anyPly ?
        labels.get('error.plyAlreadyKitted', '', {plyName: plyTypeBusinessId}) :
        labels.get('error.plyNotLinked', '', {plyName: plyTypeBusinessId}) ;


    const config = {
      title: labels.get('error.title'),
      message: message,
      submitButtonLabel: labels.get('button.gotIt'),
      type:'warning',
    }

    actions.messageDialogActions.showMessage(config);
 }

  const renderPlyTypesList = (bagId) => {
    const bag = bags?.find(item => item.bagId === bagId);

    const plies = bag?.plies?.map((item, index )=> {return (
        <KitPly key={index}
                plyTypeBusinessId={item.plyTypeBusinessId}
                kitted={item.kitted}
                plyIndex={item.plyIndex}
                bagId={bagId}
                bagBusinessId={item.bagBusinessId}
                onClickCallback={onPlyClickCallback}

        >{item.plyTypeBusinessId}</KitPly>
    )}) ?? [];

    return (
        <div className="kit-plies">{
          plies
        }
        </div>)
  }

  const renderBagPane = (bagId) => {
    return renderPlyTypesList(bagId)
  }

  const getBagTabs = () => {
    return bags?.map((bag, index) => {
      const kittedPlies = bag.plies?.filter(ply => ply.kitted)?.length ?? 0;
      const expected = bag.plies?.length ?? 0;
      const isBagKitted = kittedPlies && expected && (kittedPlies === expected);
      const bagTitle = `${bag.bagBusinessId} (${kittedPlies}/${expected})`
      let title =  isBagKitted ?
          <div className="nav-link_title"><KittedPlyIcon className="kitted"/><span>{bagTitle}</span></div>
          :
          bagTitle;
      return { eventKey: ''+index, title: title, pane: renderBagPane(bag.bagId) };
    });
  }

 const getDummyBagTab = () => {
    return bags?.map((bag, index) => {
      return { eventKey: index, title: '', pane: renderBagPane(bag.bagId) };
    });
  }

  const onSubmitSucceeded = () => {
    const config = {
      title: labels.get('save.title'),
      message: labels.get('save.dataIsSaved'),
      submitButtonLabel: labels.get('button.gotIt'),
      type:'success',
    }

    actions.messageDialogActions.showMessage(config);
  }

  const onSubmit = () => {
    let kitId = match.params.assetId;

    const kittedPlies = bags?.reduce((acc, bag) => {
      bag?.plies.filter(p => p.kitted).forEach(ply => {
        const {plyTypeId, bagId, plyIndex} = ply;
        acc.push({plyTypeId, bagId, plyIndex});
      })
      return acc;
    }, []);

    actions.updateKitPliesFormActions.submit({kittedPlies, kitId}, onSubmitSucceeded);
  }

  const handleAbandon = (nextLocation) => {
    if (!confirmedAbandon) {
      actions.updateKitPliesFormActions.abandonDirtyForm(() => {
        setConfirmedAbandon(true);
        nextLocationPath.current = nextLocation.pathname;
      });
      return false;
    }
    return true;
  };

  const restoreFocus = (event) => {
    let id = event.relatedTarget?.id;
    let relatedTarget = event.relatedTarget;

    if (relatedTarget) {
      if ( !relatedTarget.classList?.value?.includes('message-dialog') && relatedTarget.type !== 'text') {
        inputRef?.current?.focus();
      }
    }
  }

  const onKeyPressHandler = (event) => {
    let scanData = event.target.value;
    if (event.key === 'Enter') {
      handleScannedData(scanData);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      })
    }
  }

  const onSelectCallback = (e) => {
    setSelectedBagTab(e);
  }

  const renderLoadingOverlay = () => {
    if (loading) {
      return <Overlay.Loading />;
    }
    return null;
  };

  const renderErrorOverlay = () => {
      if (!formData?.error) {
          return null;
      }
      return <Overlay.Error text={formData?.error} buttonLabel={labels.get('button.goBack')} onClickCallback={() => history.goBack() } />;
  };

  const headerItems = getHeaderItems();

  return (
      <div className="update-kit-plies-form" tabIndex="-1" >
        <Header className='form-header' {...headerItems} />
        <div>{labels.get('subTitle')}</div>

        <label className="scan-status"><span className="scan-status-label">{''} </span>
          <textarea ref={inputRef}
                    autoFocus
                    onBlur={restoreFocus}
                    onKeyPress={onKeyPressHandler}
                    className="barcode-hidden-input"/>
        </label>

        <CustomTabs
            id="bag-tabs"
            className={cn({'no-bags': noBagsDefined})}
            activeKey={selectedBagTab}
            defaultActiveKey="0"
            onSelect={noBagsDefined ? undefined : onSelectCallback}
            allowOverflow={true}
            tabs={noBagsDefined ? getDummyBagTab() : getBagTabs()}
        />

        <div className="plies-total_title">{labels.get('pliesTotal')}</div>
        <div className="plies-total">
          <span>{`${labels.get('pliesTotal.expected')}: ${expectedPliesNum}`} </span>
          <span>{`${labels.get('pliesTotal.kitted')}: ${kittedPliesNum}`}</span>
        </div>

        <Prompt when={!pristine} message={handleAbandon}/>
        {renderLoadingOverlay()}
        {renderErrorOverlay()}
      </div>
  )
}

export default reduxForm({
  form: 'updateKitPliesForm',
})(UpdateKitPliesForm);
