import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as bagsViewActions } from '../../../actions/Administration/MatSettingsPage/bagsViewActions.js';
import { jsxActions as headerSettingsActions } from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as bagsViewGridActions } from '../../../actions/Administration/MatSettingsPage/bagsViewGridActions.js';
import { jsxActions as createBagDialogActions } from '../../../actions/Administration/MatSettingsPage/Dialogs/createBagDialogActions.js';
// import { jsxActions as importBagsDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/importBagsDialogActions';
import { jsxActions as matSettingsPageActions } from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames } from '../../../enums/gridsNames';
import BagsView from '../../../components/Administration/MatSettingsPage/BagsView/bagsView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(bagsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      bagsViewGridActions: bindActionCreators(bagsViewGridActions, dispatch),
      createBagDialogActions: bindActionCreators(createBagDialogActions, dispatch),
      // importBagsDialogActions : bindActionCreators(importBagsDialogActions, dispatch),
      matSettingsPageActions: bindActionCreators(matSettingsPageActions, dispatch),
    },
  };
}

let BagsViewContainer = connect((state) => {
  let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_BAGS)
    ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_BAGS)
    : null;
  return {
    sGrid: grid,
    sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
    sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
    sCreateBagDialogData: state.administration.getIn(['matSettingsPage', 'createBagDialog']),
    // sImportBagsDialogData: state.administration.getIn(['matSettingsPage', 'importBagsDialog']),
    sLoggedInUser: state.login.get('loggedInUser'),
  };
}, mapDispatchToProps)(BagsView);

export default BagsViewContainer;
