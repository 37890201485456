import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, getFormSyncErrors } from 'redux-form';
import { Prompt } from 'react-router-dom';
import { withRouter } from 'react-router';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import { onFormChangeHandler } from 'infrastructure/js/components/Form/formHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import Tabs, { PL_CustomTabs as CustomTabs } from 'infrastructure/js/components/Tabs/tabs';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import List from 'infrastructure/js/components/List/list';
import KitTypeMaterialListItem from '../../../Common/Controls/AddRemoveList/AddRemoveListItem/KitTypeMaterialListItem/kitTypeMaterialListItem';
import AddRemoveListExt from '../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import KitTypeOperationListItem from './KitTypeOperationListItem/kitTypeOperationListItem';
import KitTypeLocationListItem from './KitTypeLocationListItem/kitTypeLocationListItem';
import KitTypePlyTypeListItem from './KitTypePlyTypeListItem/kitTypePlyTypeListItem';
import AdditionalField from '../../../Common/Layout/AdditionalField/AdditionalField';
import ValidationMessagesBox from 'infrastructure/js/components/Form/validationMessagesBox';
import Button from 'infrastructure/js/components/controls/Button/button';
import Popover from 'infrastructure/js/components/popover/Popover/popover';
import EntitiesMultiSelect from '../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import RemoveTabIcon from 'infrastructure/assets/svg/remove-tab.svg'
import ErrorIcon from 'infrastructure/assets/svg/error-triangle.svg';

import {
  TOOL_REQUIRED_OPTIONS_TYPES,
  checkToolRequiredPreviousOptions,
  parseNewAddedItem,
  checkToolPreviousOptionDisabled,
} from '../../../../utils/toolRequiredHelper';

import './kitTypeForm.scss';

const EntitiesMultiSelectPortal = withPortal(EntitiesMultiSelect);

class KitTypeForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.kittypes.kitTypeForm.');

    this.itemToEdit = props.initialData;
    this.isEditMode = ['clone', 'edit'].includes(props.match.params.mode) && !!this.itemToEdit;
    this.isClone = props.match.params.mode === 'clone' && !!this.itemToEdit;

    this.isOperationsSequenceEnabled = false;
    this.isLocationsSequenceEnabled = false;

    let idsInUse = [];

    this.isTaskDurationInSeconds = PermissionManager.getOrgPreferences().taskDurationUnits === 'SECONDS';

    if (this.isEditMode && this.itemToEdit.kitTypeMaterials) {
      this.itemToEdit.kitTypeMaterials.map((item) => {
        idsInUse.push(item.material.id);
      });
    }
    this.operationsIdsInUse = [];
    if (this.isEditMode && this.itemToEdit.operations) {
      this.itemToEdit.operations.forEach((item) => {
        this.operationsIdsInUse.push(item.operationId);
      });
    }

    this.locationsIdsInUse = [];
    if (this.isEditMode && this.itemToEdit.locations) {
      this.itemToEdit.locations.map((item) => {
        this.locationsIdsInUse.push(item.locationId);
      });
    }

    this.bags = [];
    if(this.isEditMode && this?.itemToEdit?.kitTypePlyTypes.length){
      const bagsArray = this.itemToEdit.kitTypePlyTypes
        .map(({bagId, bagBusinessId, bagIndex}) => [bagId, {bagId, bagBusinessId, bagIndex}])
        .filter(([bagId]) => bagId) // filter ply types with null bagId
        .sort((bagA, bagB) => bagA[1].bagIndex - bagB[1].bagIndex); // sort by bagIndex

      this.bags = [...new Map(bagsArray).values()]; //get a unique list of bags
    }

    this.addtionalFieldsInitialValues = [];

    this.bagsFetchConfig = {
      entityType: EntityPropertyTypes.BAG_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ALL],
      action: this.props.actions.fetchEntities,
    };

    this.state = {
      optionsInUse: idsInUse,
      selectedTab: 'details',
      selectedBagTab: this.bags[0]?.bagId,
      operationsDummyKey: 0,
      confirmedAbandon: false,
      bags: this.bags,
    };
  }

  componentDidMount() {
    this.props.actions.fetchFormData();

    let operationPredictionEnabled = PermissionManager.getOrgPreferences().operationPredictionEnabled;
    let productionTimeCalculationEnabled = PermissionManager.getOrgPreferences().productionTimeCalculationEnabled;
    this.isOperationsSequenceEnabled =
      operationPredictionEnabled || productionTimeCalculationEnabled || PermissionManager.getOrgPreferences().schedulerEnabled;

    this.isLocationsSequenceEnabled = PermissionManager.getOrgPreferences().locationPredictionEnabled;

    let initialValues = { materials: [{}] };

    if (this.isEditMode) {
      initialValues = {
        kitType: this.isClone ? '' : this.itemToEdit.businessId,
        partType: this.itemToEdit.partType.id,
        project: this.itemToEdit.project.id,
        description: this.itemToEdit.description,
        tokenValue: this.itemToEdit.tokensPerPart,
        maxDaisyChains: this.itemToEdit.maxDaisyChains,
        materials: KitTypeMaterialListItem.getInitialValues(this.itemToEdit.kitTypeMaterials),
        operations: this.getInitialOperations(this.itemToEdit.operations),
        locations: this.getInitialLocations(this.itemToEdit.locations),
        additionalFields: {},
      };

      let additionalFields = this.props.sData.get('formData')?.additionalFields;
      if(additionalFields){
        additionalFields.map((field) => {
          let match = this.itemToEdit.additionalFieldsValues ? this.itemToEdit.additionalFieldsValues.find((x) => x.field.id === field.id) : null;
          if (match) {
            if (match.field.type.type === 'DATE_PICKER') {
              this.addtionalFieldsInitialValues.push(match);
            }
            initialValues.additionalFields[field.name] =
              match.field.type.type === 'DATE_PICKER' ? DateTimeHelper.ConvertToDate(match.value) : match.value;
          }
        });
      }
    }

    this.props.initialize(initialValues);
  }

  componentDidUpdate(prevProps) {

    if(prevProps.sData.get('formData')?.additionalFields !== this.props.sData.get('formData')?.additionalFields){

      if (this.isEditMode) {
        let initialValues = {
          kitType: this.isClone ? '' : this.itemToEdit.businessId,
          partType: this.itemToEdit.partType.id,
          project: this.itemToEdit.project.id,
          description: this.itemToEdit.description,
          tokenValue: this.itemToEdit.tokensPerPart,
          maxDaisyChains: this.itemToEdit.maxDaisyChains,
          materials: KitTypeMaterialListItem.getInitialValues(this.itemToEdit.kitTypeMaterials),
          operations: this.getInitialOperations(this.itemToEdit.operations),
          locations: this.getInitialLocations(this.itemToEdit.locations),
          additionalFields: {},
          ...this.getInitialBagsWithPlyTypes(this.itemToEdit.kitTypePlyTypes),
        };

      let additionalFields = this.props.sData.get('formData')?.additionalFields;
      if(additionalFields){
        additionalFields.map((field) => {
          let match = this.itemToEdit.additionalFieldsValues ? this.itemToEdit.additionalFieldsValues.find((x) => x.field.id === field.id) : null;
          if (match) {
            if (match.field.type.type === 'DATE_PICKER') {
              this.addtionalFieldsInitialValues.push(match);
            }
            initialValues.additionalFields[field.name] =
              match.field.type.type === 'DATE_PICKER' ? DateTimeHelper.ConvertToDate(match.value) : match.value;
          }
        });
      }

      this.props.initialize(initialValues);
    }
  }
}

  componentWillUnmount() {
    this.props.actions.clearFormData();
  }

  convertServerDataToPlyType = (plyTypeServerData) => {
    return {
      plyType: {
        assetsType: {
          value: getEnumValue(enumTypes.OBJECT_TYPE)('PLY_TYPE'),
          label: ''
        },
        assetsValue: {
          value: plyTypeServerData.plyTypeId,
          label: plyTypeServerData.plyTypeBusinessId,
          data: plyTypeServerData
        }
      },
      material: {
        value: plyTypeServerData.materialId,
        label: plyTypeServerData.materialBusinessId,
      }
    }
  }


  getInitialBagsWithPlyTypes = (_plyTypes) => {
    if(this.bags.length){
      const bags = this.bags.reduce((acc, {bagId}) => {
        const bagReduxName = `${bagId}-plyTypes`;
        const currentBagPlyTypes = _plyTypes
          .filter(({bagId: plyBagId}) => plyBagId === bagId)
          .sort((plyTypeA, plyTypeB) => plyTypeA.plyIndex - plyTypeB.plyIndex)
          .map(this.convertServerDataToPlyType);

        acc[bagReduxName] = currentBagPlyTypes;
        return acc;
      }, {});

      return {bags}
    }

    const plyTypes = _plyTypes
      .sort((plyTypeA, plyTypeB) => plyTypeA.plyIndex - plyTypeB.plyIndex)
      .map(this.convertServerDataToPlyType);

    return {plyTypes};
  }

  getInitialOperations = (operations) => {
    if (operations) {
      return operations.map((item) => {
        const operationData = this.getAllOperations()?.find((operation) => operation.data.id === item.operationId)?.data;

        let obj = {
          operationName: item.operationId,
          splitEnabled: item.splittable,
          duration: {
            [this.isTaskDurationInSeconds ? '_minutes' : '_hours']: item.stdDuration || item.stdDuration === 0 ? (item.stdDuration / 60) | 0 : 0, //set 0 when there is no value (for backward compatibility)
            [this.isTaskDurationInSeconds ? '_seconds' : '_minutes']: item.stdDuration || item.stdDuration === 0 ? item.stdDuration % 60 | 0 : 0, //set 0 when there is no value (for backward compatibility)
          },
          durationPerPart: item.perPart,
          setupTime: {
            _hours: item.setupTime || item.setupTime === 0 ? (item.setupTime / 60) | 0 : '',
            _minutes: item.setupTime || item.setupTime === 0 ? item.setupTime % 60 | 0 : '',
          },
          minHumanCapacity: item.minHumanCapacity,
          maxHumanCapacity: operationData?.manHours ? item.maxHumanCapacity : null,
          startAfterPercentage: item.startAfterPercentage,
          maxHumanCapacityEnabled: operationData?.manHours,
          minimumSplitBlock: {
            _hours: item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0) ? (item.minimumSplitBlock / 60) | 0 : '',
            _minutes: item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0) ? item.minimumSplitBlock % 60 | 0 : '',
          },
          maximumTotalDuration: {
            _hours: item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0) ? (item.maximumTotalDuration / 60) | 0 : '',
            _minutes: item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0) ? item.maximumTotalDuration % 60 | 0 : '',
          },
          maxDistanceEnabled: item.isMaxDistanceEnabled ?? false,
          maxDistance: {
            _hours: item.isMaxDistanceEnabled && (item.maxDistanceMinutes || item.maxDistanceMinutes === 0) ? (item.maxDistanceMinutes / 60) | 0 : '',
            _minutes: item.isMaxDistanceEnabled && (item.maxDistanceMinutes || item.maxDistanceMinutes === 0) ? item.maxDistanceMinutes % 60 | 0 : '',
          },
          toolRequired: item.toolRequirement ?? TOOL_REQUIRED_OPTIONS_TYPES.NO,
          recipe: item.recipe,
          disallowedStations: item.disallowedStations,
        };

        return obj;
      });
    }
    return [];
  };

  getInitialLocations = (items) => {
    if (items) {
      let res = items.map((item) => {
        return { locationName: item.filter((id) => !!id) }; //TODO: L currently to filter out a null from array
      });
      return res;
    }
    return [];
  };

  getMaterials = () => {
    let formData = this.props.sData.get('formData');
    return formData?.materials ?? [];
  };

  getParts = () => {
    let formData = this.props.sData.get('formData');
    return formData?.partTypes ?? [];
  };

  getProjects = () => {
    let formData = this.props.sData.get('formData');
    return formData?.projects ?? [];
  };

  getAllOperations = () => {
    let formData = this.props.sData.get('formData');
    return formData?.operations ?? [];
  };

  getAllLocations = () => {
    let formData = this.props.sData.get('formData');
    return formData?.locations ?? [];
  };

  getRequiredMaterials = (materials) => {
    if (materials) {
      return materials.map((item) => {
        let res = {
          material: {
            id: item.material,
          },
          quantityExpectedPlies: item.quantity,
          length: item.length || item.length === 0 ? UnitHelper.userValueToServerValue(unitTypes.LENGTH, item.length) : null,
          weight: item.weight || item.weight === 0 ? UnitHelper.userValueToServerValue(unitTypes.WEIGHT, item.weight) : null,
          substituteMaterials: item.substituteMaterials
            ? item.substituteMaterials.map((m) => {
                return { id: m.value };
              })
            : [],
        };

        return res;
      });
    }
    return [];
  };

  getOperationDurationByTaskUOM = (duration) => {
    if (!duration) {
      return null;
    }

    return this.isTaskDurationInSeconds
      ? DateTimeHelper.ConvertMinutesSecondsToSeconds(duration._minutes, duration._seconds)
      : DateTimeHelper.ConvertHoursMinutesToMinutes(duration._hours, duration._minutes);
  };

  getKitTypeOperations = (operations) => {
    if (operations) {
      //filter out the empty operations
      let filtered = operations.filter((op) => {
        return !!op.operationName;
      });

      return filtered.map((item) => {
        let res = {
          operationId: item.operationName,
          stdDuration: this.getOperationDurationByTaskUOM(item.duration),
          perPart: item.durationPerPart,
          setupTime: item.setupTime ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.setupTime._hours, item.setupTime._minutes) : null,
          splittable: item.splitEnabled,
          minHumanCapacity: item.minHumanCapacity,
          minimumSplitBlock:
            item.splitEnabled && item.minimumSplitBlock
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.minimumSplitBlock._hours, item.minimumSplitBlock._minutes)
              : null,
          maximumTotalDuration:
            item.splitEnabled && item.maximumTotalDuration
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.maximumTotalDuration._hours, item.maximumTotalDuration._minutes)
              : null,
          isMaxDistanceEnabled: item.maxDistanceEnabled,
          maxDistanceMinutes:
            item.maxDistanceEnabled && item.maxDistance
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.maxDistance._hours, item.maxDistance._minutes)
              : null,
          startAfterPercentage: item.startAfterPercentage || item.startAfterPercentage === 0 ? item.startAfterPercentage : null,
          toolRequirement: item.toolRequired,
          recipe: item.recipe,
          disallowedStations: item.disallowedStations,
        };

        const operationData = this.getAllOperations()?.find((operation) => operation.data.id === item.operationName)?.data;
        if (operationData?.manHours) {
          res.maxHumanCapacity = item.maxHumanCapacity;
        }

        return res;
      });
    }
    return [];
  };

  getKitTypeLocations = (items) => {
    return items
      ? items.map((item) => {
          return item.locationName ? item.locationName : [];
        })
      : [];
  };

  convertPlyTypesToServerData = (data) => {
    if(!data?.plyTypes?.length && !this.state.bags?.length){
      return [];
    }

    // if there are plyTypes in the form data but no bags
    if(data.plyTypes?.length){
      return data.plyTypes.map(({plyType, material}, i) => {
        return {
          bagId: null,
          plyTypeId: plyType.assetsValue.value,
          materialId: material.value,
          plyIndex: i + 1,
          bagIndex: null
        };
      });
    }

    // if there are bags with ply types in the form data
    let plyTypes = [];
    this.state.bags.forEach(({bagId}, bagIndex) => {
      const bagReduxName = `${bagId}-plyTypes`;
      const currentBagPlyTypes = data.bags[bagReduxName]?.map(({plyType, material}, plyIndex) => {
        return {
          bagId,
          bagIndex: bagIndex + 1,
          plyTypeId: plyType.assetsValue.value,
          materialId: material.value,
          plyIndex: plyIndex + 1,
        }
      }) || [];

      plyTypes = [...plyTypes, ...currentBagPlyTypes];
    });

    return plyTypes;
  }

  onSubmit = (data) => {
    let newData = {
      businessId: data.kitType,
      partType: { id: data.partType },
      project: { id: data.project },
      description: data.description,
      maxDaisyChains: data.maxDaisyChains,
      tokensPerPart: data.tokenValue || null,
      requiredMaterials: this.getRequiredMaterials(data.materials),
      operations: this.getKitTypeOperations(data.operations),
      locations: this.getKitTypeLocations(data.locations),
      additionalFieldsDefinitions: this.props.sData.get('formData').additionalFields,
      additionalFields: data.additionalFields,
      ignoreValidationWarnings:
        this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox ? data.footerValidationCheckbox : false,
      plyTypes: this.convertPlyTypesToServerData(data),
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }

    this.props.actions.submit(newData, this.isEditMode, this.isClone, this.onSubmitSucceeded);
  };

  onSubmitSucceeded = () => {
    this.setState({ confirmedAbandon: true }, () => {
        this.props.history.replace('/administration/matsettings/kits/kitTypes')
    });
  }

  onTabClick = (selectedTab) => {
    const submitFunc = this.props.handleSubmit(() => this.setState({ selectedTab }));

    // If there are any validation errors the handleSubmit callback is NOT invoked.
    const invalidFields = submitFunc();

    // If any of the current tab's fields are invalid prevent moving on to other tabs.
    if (invalidFields && this.isCurrentTabValid(invalidFields)) {
      this.setState({ selectedTab });
    }
  };

  onBagTabClick = (selectedBagTab, event) => {
    event.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.setState({ selectedBagTab });
  };

  isCurrentTabValid = (invalidFields = {}) => {
    const currentTab = this.state.selectedTab;
    const tabsValidationFields = {
      details: ['kitType', 'partType', 'project', 'materials', 'maxDaisyChains'],
      operationsSequence: ['operations'],
      locationsSequence: ['locations'],
    };

    const validationFields = tabsValidationFields[currentTab];
    const isCurrentTabInvalid = Object.keys(invalidFields).some((field) => validationFields.includes(field));

    return !isCurrentTabInvalid;
  };

  /**this method checks if the 'yes' was selected in previous line and enable or disable the current 'Previous' option */
  checkIfPreviousOptionDisabled = (index, option) => {
    const { operationsFieldsValues: items } = this.props;
    return checkToolPreviousOptionDisabled(index, option, items);
  };

  /////////////// Details Tab ///////////////////////////

  getMaterialsOptionsInUse = (itemIndex) => {
    let idsInUse = [...this.state.optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  getRequiredMaterialsOptions = () => {
    return this.getMaterials()?.filter((material) => {
      return this.state.optionsInUse.indexOf(material.value) > -1;
    }) || [];
  };


  getAvailableMaterialsOptions = (itemIndex) => {
    let allOptions = this.getMaterials();
    let optionsInUse = this.getMaterialsOptionsInUse(itemIndex);

    return allOptions.filter((option) => {
      return optionsInUse.indexOf(option.value) < 0;
    });
  };

  getAvailableSubstituteMaterialsOptions = (itemIndex) => {
    let idsInUse = [...this.state.optionsInUse];

    let allOptions = this.getMaterials();
    let itemId = idsInUse[itemIndex];
    if (itemId) {
      return allOptions.filter((option) => {
        return option.value !== itemId;
      });
    }
    return allOptions;
  };

  onMaterialChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (oldValue) {
      //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) {
      //no value when clear the selection
      idsInUse[index] = value.value;
    }

    this.setState({ optionsInUse: idsInUse });
  };

  onMaterialRemoveCallback = (index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (index > -1) {
      idsInUse.splice(index, 1);
    }

    this.setState({ optionsInUse: idsInUse });
  };

  onMaterialAddCallback = () => {
    let idsInUse = [...this.state.optionsInUse];

    idsInUse.push(null); //hold index in optionsInUse for the new added item

    this.setState({ optionsInUse: idsInUse });
  };

  listItemRenderer = (data) => {
    return (
      <KitTypeMaterialListItem
        {...data}
        onChangeCallback={this.onMaterialChangeCallback}
        options={this.getAvailableMaterialsOptions(data.index)}
        substituteOptions={this.getAvailableSubstituteMaterialsOptions(data.index)}
        {...this.props}
      />
    );
  };

  renderLengthOrWeight = () => {
    return PermissionManager.isWeightSupported() ? (
      <label className="column">{`${this.labels.get('weight')} (${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)})`}</label>
    ) : (
      <label className="column">{`${this.labels.get('length')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`}</label>
    );
  };

  renderMaterialsList = () => {
    return (
      <div>
        <List
          name="materials"
          className='required-materials-list'
          itemRenderer={this.listItemRenderer}
          preSelectedItems={this.isEditMode ? this.itemToEdit.kitTypeMaterials : [null]}
          onRemoveCallback={this.onMaterialRemoveCallback}
          onAddCallback={this.onMaterialAddCallback}
          addButtonLabel={this.labels.get('addmaterial')}
        />
      </div>
    );
  };

  renderAdditionalFields = () => {
    let formData = this.props.sData.get('formData');
    if (!formData || !formData.additionalFields) {
      return null;
    }
    return formData.additionalFields.map((field, index) => {
      return (
        <AdditionalField key={index} field={field} index={2} className={'short-textfield'} fieldsInitialValues={this.addtionalFieldsInitialValues} />
      );
    });
  };

  maxLength30 = Validation.maxLength(30);

  getBagTabs = () => {
    return this.state.bags.map(({bagId, bagBusinessId}) => {
      return { eventKey: bagId, title: this.renderBagTitle(bagBusinessId, bagId), pane: this.renderPlyTypesList(bagId) };
    });
  }

  onRemoveBagClick = (bagId, e) => {
    e.stopPropagation();

    if(this.props.bagsFieldsValues[`${bagId}-plyTypes`]?.length){
      this.props.actions.removeBag(() => this.removeBag(bagId));
      return;
    }

    this.removeBag(bagId);
  }

  removeBag = (bagId) => {
    let bags = [...this.state.bags];
    let bagIndex = bags.findIndex((b) => b.bagId === bagId);
    if (bagIndex > -1) {
      bags.splice(bagIndex, 1);
      this.setState({ bags });
      this.props.clearFields(false, false, `bags.${bagId}-plyTypes`);
    }

    if (Number(this.state.selectedBagTab) === bagId) {
      this.setState({ selectedBagTab: bags[0]?.bagId });
    }
  }

  getBagValidationErrors = (bagId) => {
    if (this.props.submitFailed) {
      const bagReduxName = `${bagId}-plyTypes`;
      const bagErrors = this.props.synchronousError?.bags?.[bagReduxName]

      if(!bagErrors){
        return [];
      }

      if(bagErrors?._error){
        return [bagErrors._error];
      }

      return bagErrors?.filter((errorData) => errorData?.material || errorData?.plyType.assetsValue)
    }
  }

  renderBagTitle = (bagBusinessId, bagId) => {
    const errors = this.getBagValidationErrors(bagId);
    return (
      <div className="bag-title">
        <span className="bag-title-text">{bagBusinessId}</span>
        <span className="bag-title-icons">{errors?.length > 0 ? <ErrorIcon /> : null}</span>
        <span className="bag-title-remove-btn" onClick={(e) => this.onRemoveBagClick(bagId, e)}><RemoveTabIcon className='bag-title-remove-icon'/></span>
      </div>
    );
  }

  onBagSelect = (bag) => {
    this.props.clearFields(false, false, 'bag');

    if (bag) {
      let bags = [...this.state.bags];
      let bagIndex = bags.findIndex((b) => b.bagId === bag.value);
      if (bagIndex === -1) {
        bags.push({ bagId: bag.value, bagBusinessId: bag.label });
        this.setState({ bags, selectedBagTab: bag.value });
      }
    }
  }

  renderAddBagButton = () => {
    return (
      <Button id="add-bag-btn" className="add-bag-btn">
        <Popover
          placement="bottom"
          popoverId="add-bag-popover"
          className="add-bag-popover"
          trigger="click"
          popoverComponent={
            <div>
              <EntitiesMultiSelectPortal
                id="bag"
                name="bag"
                classNames={{
                  menuPortal: () => "bag-select-menu-portal",
                }}
                entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('BAG')]}
                fetchConfig={this.bagsFetchConfig}
                change={this.props.change}
                showEntitiesTypes={false}
                isMulti={false}
                onValueChangeCallback={this.onBagSelect}
                idsInUse={this.state.bags.map((b) => b.bagId)}
              />
            </div>
          }
        >
          <span className="pl pl-icon-add" /> <span>{this.labels.get('addBag')}</span>
        </Popover>
      </Button>
    );
  };

  getPlyTypesListItem = (data) => {
    return (
      <KitTypePlyTypeListItem
        {...data}
        {...this.props}
        requiredMaterials={this.getRequiredMaterialsOptions()}
      />
    );
  };

  renderPlyTypesList = (bagId) => {
    const reduxName = bagId ? `bags.${bagId}-plyTypes` : 'plyTypes';
    const isListPopulated = bagId ? this.props.bagsFieldsValues[`${bagId}-plyTypes`]?.length > 0 : this.props.plyTypesFieldsValues?.length > 0;

    return (
      <div className={cn('ply-types-list', { 'empty-list': !isListPopulated })}>
        {isListPopulated && (
          <div className="ply-types-list__headers">
            <div className="ply-type-header">{this.labels.get('plyTypes.plyType')}*</div>
            <div className="material-header">{this.labels.get('plyTypes.material')}*</div>
          </div>
        )}
        <AddRemoveListExt
          {...this.props}
          name={reduxName}
          allowDragSort
          maxHeightToOverflow={400}
          itemRendererComponent={this.getPlyTypesListItem}
          addButtonLabel={this.labels.get('plyTypes.add')}
          showRemoveAllButton={true}
          removeAllButtonLabel={this.labels.get('sequence.remove')}
          showMoveButtons={true}
          hideAddAtButton={true}
          validate={bagId ? Validation.required : null}
        />
      </div>
    );
  };

  renderPlyTypesSummary = () => {
    const expectPlies = this.props.materialsFieldsValues?.reduce((acc, {quantity}) => {
      return quantity ? acc + quantity : acc;
    }, 0) || 0;

    const addedBagsPlies = Object.keys(this.props.bagsFieldsValues).reduce((acc, bagReduxName) => {
      return acc + this.props.bagsFieldsValues[bagReduxName]?.length;
    }, 0) || 0;

    const addedPliesWithoutBags = this.props.plyTypesFieldsValues?.length || 0;

    return (
      <div className="plies-summary">
      <div className="title">{this.labels.get('plyTypes.summary.title')}</div>
      <div className="plies-summary-data">
        <div className='plies-summary-data-column'>
          <div>{this.labels.get('plyTypes.summary.pliesExpected')}</div>
          <div>{expectPlies}</div>
        </div>
        <div className='plies-summary-data-column'>
          <div>{this.labels.get('plyTypes.summary.pliesAdded')}</div>
          <div>{addedBagsPlies || addedPliesWithoutBags}</div>
        </div>
      </div>
    </div>
    )
  }

  renderDetailsTab = () => {
    const noBagsDefined = this.state.bags.length === 0 || this.state.bags[0].bagId === null;
    return (
      <Tabs.Tab className="details-tab" eventKey="details" title={this.labels.get('tabs.details')} animation={false}>
        {this.renderValidationMessages()}
        <div className="details-tab_left">

          <InputSection label={this.labels.get('kittype') + '*'} htmlFor="kitType">
            <TextField id="kitType" name="kitType" validate={[Validation.required, this.maxLength30]} />
          </InputSection>

          <InputSection label={this.labels.get('parttype') + '*'} htmlFor="partType">
            <Combobox
              id="partType"
              name="partType"
              options={this.getParts()}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(this.getParts())}
              validate={Validation.required}
            />
          </InputSection>

          <InputSection label={this.labels.get('project') + '*'} htmlFor="project">
            <Combobox
              id="project"
              name="project"
              options={this.getProjects()}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(this.getProjects())}
              validate={Validation.required}
            />
          </InputSection>

          <InputSection label={this.labels.get('description')} htmlFor="description" className="full-width description-section">
            <TextAreaField id="description" name="description" maxLength="1000" />
          </InputSection>

          <div className="params-section">
            <InputSection label={this.labels.get('tokenValue')} htmlFor="tokenValue" className="">
              <TextField id={'tokenValue'} name={'tokenValue'} normalize={Normalize.number(true, 1, 9999)} />
            </InputSection>

            {PermissionManager.getOrgPreferences().compositeOrgDisplayEnabled && (
              <InputSection label={this.labels.get('maxDaisyChains') + '*'} htmlFor="maxDaisyChains">
                <TextField id={'maxDaisyChains'} name={'maxDaisyChains'} validate={Validation.required} normalize={Normalize.number(true, 1, 999)} />
              </InputSection>
            )}
          </div>

          <div className="additional-fields">{this.renderAdditionalFields()}</div>

          {this.renderMaterialsList()}
        </div>
        <div className="details-tab_right">
          <div className={cn('bags-plies-section', { 'no-bags': noBagsDefined })}>
            <div className="title">PLY DETAILS</div>
            {noBagsDefined ? (
              <>
                {this.renderAddBagButton()}
                {this.renderPlyTypesList()}
              </>
            ) : (
              <CustomTabs
                activeKey={this.state.selectedBagTab}
                onSelect={this.onBagTabClick}
                id="bag-tabs"
                allowOverflow={true}
                tabs={this.getBagTabs()}
                buttons={this.renderAddBagButton()}
              />
            )}
            {this.renderPlyTypesSummary()}
          </div>
        </div>
      </Tabs.Tab>
    );
  };

  /////////////// Operations Tab ///////////////////////////

  hasValidationError = (index) => {
    if (this.props.submitFailed) {
      let operations = this.props.synchronousError?.operations?.[index];
      if (operations) {
        let fields = Object.keys(operations)?.filter((key) => {
          return key !== 'operationName' && key !== 'duration';
        });
        return fields?.length > 0;
      }
    }
    return false;
  };

  getKitTypeOperationListItem = (itemProps) => {
    let curOperations = this.props.operationsFieldsValues;
    let splitEnabled = curOperations[itemProps.index]?.splitEnabled ?? false;
    let maxDistanceEnabled = curOperations[itemProps.index]?.maxDistanceEnabled ?? false;

    return (
      <KitTypeOperationListItem
        key={this.state.operationsDummyKey + '-' + itemProps.index}
        {...itemProps}
        selectedOperation={curOperations[itemProps.index]}
        onToolRequiredChangeCallback={this.onToolRequiredChangeCallback}
        onSplitEnabledChangeCallback={this.onSplitEnabledChangeCallback}
        onMaxDistanceChangeCallback={this.onMaxDistanceChangeCallback}
        allOperations={this.getAllOperations()}
        labels={this.labels}
        splitEnabled={splitEnabled}
        maxDistanceEnabled={maxDistanceEnabled}
        checkToolPreviousOptionDisabled={this.checkIfPreviousOptionDisabled}
        hasValidationError={this.hasValidationError(itemProps.index)}
        itemRendererData={this.props.operationsFieldsValues[itemProps.index]}
      />
    );
  };

  onToolRequiredChangeCallback = () => {
    setTimeout(() => {
      this.handleToolRequiredChange();
    }, 0);
  };

  onSplitEnabledChangeCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  onMaxDistanceChangeCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  onOperationAddAtCallback = () => {
    setTimeout(() => {
      this.handleToolRequiredChange();
    }, 0);
  };

  onOperationRemoveCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  handleToolRequiredChange = () => {
    let newOperations = checkToolRequiredPreviousOptions(this.props.operationsFieldsValues);
    this.props.change('operations', newOperations);
    this.updateDummyKey();
  };

  updateDummyKey = () => {
    let newState = { operationsDummyKey: this.state.operationsDummyKey === 0 ? 1 : 0 };
    this.setState(newState);
  };

  renderOperationsSequenceTab = () => {
    if (!PermissionManager.hasLocationsAndOperationsSequenceTabPermissions()) {
      return null;
    }

    let allOptions = this.getAllOperations();

    return (
      <Tabs.Tab
        className="sequence-tab"
        eventKey="operationsSequence"
        title={this.labels.get('tabs.operationsSequence')}
        animation={false}
        disabled={!this.isOperationsSequenceEnabled}
      >
        {this.renderValidationMessages()}
        <div className='operation-sequence-list'>
          <div
            className={cn('operations-list-header', {
              'scheduler-enabled': PermissionManager.getOrgPreferences().schedulerEnabled,
            })}
          >
            <label className="column">{this.labels.get('operations.order')}</label>
            <label className="column">{this.labels.get('operations.operation') + '*'}</label>
            <label className="column">{this.labels.get('operations.duration') + '*'}</label>
            {PermissionManager.isToolsSchedulingEnabled() && <label className="column tool-type">{this.labels.get('operations.toolRequired')}</label>}
          </div>

          <AddRemoveListExt
            {...this.props}
            updateKey={this.state.operationsDummyKey}
            name={'operations'}
            itemRendererComponent={this.getKitTypeOperationListItem}
            itemRendererOptions={allOptions}
            preSelectedItems={this.itemToEdit ? this.itemToEdit.operations : []}
            preSelectedItemsIds={this.operationsIdsInUse}
            addButtonLabel={this.labels.get('operations.add')}
            showRemoveAllButton={true}
            removeAllButtonLabel={this.labels.get('sequence.remove')}
            onRemoveCallback={this.onOperationRemoveCallback}
            onAddAtCallback={this.onOperationAddAtCallback}
            parseNewAddedItem={parseNewAddedItem}
          />
        </div>
      </Tabs.Tab>
    );
  };

  /////////////// Locations Tab ///////////////////////////

  renderLocationsSequenceTab = () => {
    if (!PermissionManager.hasLocationsAndOperationsSequenceTabPermissions()) {
      return null;
    }

    let allOptions = this.getAllLocations();

    return (
      <Tabs.Tab
        className="sequence-tab"
        eventKey="locationsSequence"
        title={this.labels.get('tabs.locationsSequence')}
        animation={false}
        disabled={!this.isLocationsSequenceEnabled}
      >
        {this.renderValidationMessages()}
        <div className='location-sequence-list'>
          <div className="locations-list-header ">
            <label className="column">{this.labels.get('locations.order')}</label>
            <label className="column">{this.labels.get('locations.locations')}</label>
          </div>

          <AddRemoveListExt
            {...this.props}
            name={'locations'}
            itemRendererComponent={KitTypeLocationListItem}
            itemRendererOptions={allOptions}
            preSelectedItemsIds={this.locationsIdsInUse}
            addButtonLabel={this.labels.get('locations.add')}
            showRemoveAllButton={true}
            removeAllButtonLabel={this.labels.get('sequence.remove')}
            maxItemsToRender={15}
          />
        </div>
      </Tabs.Tab>
    );
  };

  handleAbandon = (nextLocation) => {
      if (!this.state.confirmedAbandon) {
        this.props.actions.abandonDirtyForm(() => {
          this.setState({ confirmedAbandon: true }, () => {
            this.props.history.replace(nextLocation.pathname);
          });
        });

        return false;
      }

      return true;
  };

  getHeaderItems = () => {
    return {
      buttons: [
        {
          id: 'cancel',
          label: headerItemsLabels.CANCEL,
          className: 'no-icon',
          action: () => this.props.history.replace('/administration/matsettings/kits/kitTypes'),
        },
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:
            (this.isEditMode && this.props.pristine) ||
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  };

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  getTitle = () => {
    if(this.isClone) {
      return `${this.labels.get('title.clone')} - ${this.itemToEdit.businessId}`;
    }

    if(this.isEditMode) {
      return  `${this.labels.get('title.edit')} - ${this.itemToEdit.businessId}`;
    }


    return this.labels.get('title.create');
  }

  renderValidationCheckbox = () => {
      if (this.props.sData.get('showIgnoreValidationCheckbox')) {
        return (
          <div className="footer-validation-checkbox">
            <Checkbox name="footerValidationCheckbox" id="validationCheckbox"
                      label={this.dialogLabels.get('ignorewarnings')}
                      onChangeCallback={this.props.actions.toggleIgnoreValidationWarningsCheckbox}
                      // disabled={this.props.disableClick}
            />
          </div>
        );
      }
      return null;
    };

    renderValidationMessages = () => {
      if(this.props.sData.get('validationMessage')) {
        return <ValidationMessagesBox validationMessages={this.props.sData.get('validationMessage')} ref={this.validationMessagesCallbackRef}/>
      }
      return null;
    }

    validationMessagesCallbackRef = (node) => {
      node?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

  render() {
    let selectedTab = this.state.selectedTab;
    const headerItems = this.getHeaderItems();

    return (
      <div className="kit-type-form">
        <div className="title">{this.getTitle()}</div>

          <div className='tabs-wrapper'>
            <div className='header-wrapper'>
              <Header className='form-header' {...headerItems} />
              {this.renderValidationCheckbox()}
            </div>
            <div className='tabs-content'>
              <Tabs activeKey={selectedTab} tabType="form" className={'kit-type-form-tabs'} onSelect={this.onTabClick} id="content-tabs" mountOnEnter={false} unmountOnExit={false}>
                {this.renderDetailsTab()}
                {this.renderOperationsSequenceTab()}
                {this.renderLocationsSequenceTab()}
              </Tabs>
            </div>
          </div>

        <Prompt when={!this.props.pristine} message={this.handleAbandon} />
        {this.renderLoadingOverlay()}
      </div>
    );
  }
}

KitTypeForm.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  operationsFieldsValues: PropTypes.array,
  bagsFieldsValues: PropTypes.object,
  plyTypesFieldsValues: PropTypes.array,
  materialsFieldsValues: PropTypes.array,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  reloadParentComponent: PropTypes.func,
  change: PropTypes.func,
  pristine: PropTypes.bool,
  initialData: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

KitTypeForm.defaultProps = {
  operationsFieldsValues: [],
  bagsFieldsValues: {},
  pristine: false,
  initialize: () => console.error('initialize is missing!'),
  handleSubmit: () => console.error('handleSubmit is missing!'),
  reloadParentComponent: () => console.error('reloadParentComponent is missing!'),
  change: () => console.error('change is missing!'),
};

const selector = formValueSelector('KitTypeForm');
const mapStateToProps = (state) => {
  return {
    operationsFieldsValues: selector(state, 'operations'),
    bagsFieldsValues: selector(state, 'bags'),
    plyTypesFieldsValues: selector(state, 'plyTypes'),
    materialsFieldsValues: selector(state, 'materials'),
    synchronousError: getFormSyncErrors('KitTypeForm')(state)
  };
};
const reduxFormConfig = {
  form: 'KitTypeForm',
  // enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    onFormChangeHandler(values, props, previousValues);
  },
};

export default compose(connect(mapStateToProps), reduxForm(reduxFormConfig), withRouter)(KitTypeForm);
