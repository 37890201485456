import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';
import * as kitsService from '../../services/Kits/kitsService.js';
import * as formActionsHelper from '../Forms/formActionsHelper';
import { formsNames } from '../../enums/formsNames';
import { getResponseValidationDetails } from 'infrastructure/js/components/Form/formHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the formActionsHelper's submit() method
};

export const actionTypes = {
  ...formActionsHelper.getActionTypes(formsNames.UPDATE_KIT_PLIES_FORM),
};

const actions = {
  ...formActionsHelper.getActions(formsNames.UPDATE_KIT_PLIES_FORM),
};

export let api = {
  ...formActionsHelper.getApiActions(actions, config),

  fetchFormData(dispatch, getState) {
    return function (assetId) {
      dispatch(actions.setBusy(true));

      let promise1 = kitsService.getKitDetails(assetId);
      let promise2 = kitsService.fetchKitPlies(assetId);

      return Promise.all([promise1, promise2]).then((allResults) => {
        const invalidResponse = allResults.find(response => {
          return !Network.isResponseValid(response);
        });

        dispatch(actions.setBusy(false));

        if(invalidResponse){
          console.error('Failed to get the Kit plies data', invalidResponse);
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          return {success: false};
        }

        let formData = {
          kitBusinessId: allResults[0]?.data?.businessId,
          kitTypeBusinessId: allResults[0]?.data?.kitType?.businessId,
          bags: _createDataModel(allResults[1].data),
          error: allResults[0]?.data?.isReadyForPliesUpdate ? '' : createLabelHelper('').get('mat.forms.updateKitPliesForm.error.missingCuts'),
          // plyMaterials: _createPlyMaterials(allResults[1].data),

        };

        dispatch(actions.fetchFormDataReady(formData));
        return { success: true };
      });
    };
  },

  submit(dispatch, getState) {
    return function (data, callback) {
      dispatch(actions.setBusy(true));

      kitsService.updateKitPlies(data.kitId, {kittedPlies: data.kittedPlies}).then((response) => {
        dispatch(actions.setBusy(false));

        let validations = getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return { success: false };
        }

        if (!Network.isResponseValid(response)) {
          console.error('Update kit plies failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        api.fetchFormData(dispatch, getState)(data.kitId);

        callback?.();

        return { success: true };
      });
    };
  },
};

export let jsxActions = {
  ...formActionsHelper.getJsxActions(api),

  fetchFormData(assetId) {
    return function (dispatch, getState) {
      return api.fetchFormData(dispatch, getState)(assetId);
    };
  },
  submit(data, callback) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, callback);
    };
  },
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _createDataModel(data) {
  const bags = data?.bagsOrder?.length > 0 ? data.bagsOrder : [{id: null, businessId: null }];

  const model = bags?.map(bag => {
    return {
      bagId: bag.id,
      bagBusinessId: bag.businessId,
      kitted: false,
      plies: data?.plies
        .filter(item => item.bagId === bag.id)
        .sort((itemA,itemB) => itemA.plyIndex < itemB.plyIndex ? -1 : 1)
        ?? [],
    }
  }) ?? [];
  return model;
}

//Note: will be implemented later
// function _createPlyMaterials(data) {
//   const plyMaterials = data.plies.reduce((acc, ply) => {
//     if (!acc[ply.plyTypeBusinessId]) {
//       acc[ply.plyTypeBusinessId] = new Map();
//       acc[ply.plyTypeBusinessId].set(ply.materialId, ply.materialBusinessId);
//     }
//     else {
//       acc[ply.plyTypeBusinessId].set(ply.materialId, ply.materialBusinessId);
//     }
//     return acc;
//   }, {});
//
//   console.log('-----_createPlyMaterials(): ', plyMaterials);
//
//   let res = {}
//
//   for (const ply in plyMaterials) {
//     if (plyMaterials[ply]?.size > 1) {
//       res[ply] = plyMaterials[ply];
//     }
//   }
//   console.log('-----_createPlyMaterials(): MM only', res);
//   return res;
// }
