import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as plyTypesViewActions } from '../../../actions/Administration/MatSettingsPage/plyTypesViewActions.js';
import { jsxActions as headerSettingsActions } from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as plyTypesViewGridActions } from '../../../actions/Administration/MatSettingsPage/plyTypesViewGridActions.js';
import { jsxActions as createPlyTypeDialogActions } from '../../../actions/Administration/MatSettingsPage/Dialogs/createPlyTypeDialogActions.js';
// import { jsxActions as importPlyTypesDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/importPlyTypesDialogActions';
import { jsxActions as matSettingsPageActions } from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames } from '../../../enums/gridsNames';
import PlyTypesView from '../../../components/Administration/MatSettingsPage/PlyTypesView/plyTypesView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(plyTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      plyTypesViewGridActions: bindActionCreators(plyTypesViewGridActions, dispatch),
      createPlyTypeDialogActions: bindActionCreators(createPlyTypeDialogActions, dispatch),
      // importPlyTypesDialogActions : bindActionCreators(importPlyTypesDialogActions, dispatch),
      matSettingsPageActions: bindActionCreators(matSettingsPageActions, dispatch),
    },
  };
}

let PlyTypesViewContainer = connect((state) => {
  let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PLY_TYPES)
    ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PLY_TYPES)
    : null;
  return {
    sGrid: grid,
    sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
    sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
    sCreatePlyTypeDialogData: state.administration.getIn(['matSettingsPage', 'createPlyTypeDialog']),
    // sImportPlyTypesDialogData: state.administration.getIn(['matSettingsPage', 'importPlyTypesDialog']),
    sLoggedInUser: state.login.get('loggedInUser'),
  };
}, mapDispatchToProps)(PlyTypesView);

export default PlyTypesViewContainer;
