import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { useOnClickOutside } from 'infrastructure/js/hooks/useOnClickOutside';
import LogoSection from './LogoSection/logoSection';
import TopSearch from './TopSearch/topSearch';
import UserMenu from './UserMenu/userMenu';
import HelpMenu from './HelpMenu/helpMenu';
import cn from 'classnames';
import NotificationsCenterContainer from '../Notifications/NotificationsCenter/notificationsCenterContainer';
import { getLocationIcon } from './appTopBarHelper';
import UserMenuIcon from '../../../assets/svg/circled-user.svg';
import HelpMenuIcon from '../../../assets/svg/circled-question-mark.svg';
import TriggersIcon from '../../../assets/svg/trigger.svg';
import FullScreenIcon from 'infrastructure/assets/svg/full-screen.svg';
import FullScreenExitIcon from 'infrastructure/assets/svg/full-screen-exit.svg';
import LastTriggersPanel from './LastTriggersPanel/lastTriggersPanel';
import {isSandboxOrg, isTouchApp} from 'infrastructure/js/utils/appHelper';
import {enumTypes, getEnumLabel} from '../../utils/enumHelper';

import './appTopbar.scss';


export default function AppTopbar({ topNavigatorData={},
                                    featureFlags={},
                                    actions={},
                                    sServerVersion='',
                                    sLoggedInUser }) {

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [showTriggersMenu, setShowTriggersMenu] = useState(false);
  const userMenuRef = useRef(null);
  const helpMenuRef = useRef(null);
  const triggersPanelRef = useRef(null);
  const [fullScreenMode, setFullScreenMode] = useState(false);

  useEffect(() => {
    actions.topNavigator.fetchHomePage();
    document.addEventListener('fullscreenchange', fullScreenChangeHandler);

    return () => {
      document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutside(userMenuRef, () => setShowUserMenu(false));
  useOnClickOutside(helpMenuRef, () => setShowHelpMenu(false));
  useOnClickOutside(triggersPanelRef, () => setShowTriggersMenu(false));

  // Handle cases where exit fullscreen mode is initiated by the browser's built in buttons / esc key
  const fullScreenChangeHandler = () => {
    const isExitingFullscreen = !document.fullscreenElement;
    if (isExitingFullscreen && document.exitFullscreen) {
      setFullScreenMode(false);
    }
  };

  const toggleFullScreen = () => (document.fullscreenElement ? exitFullscreen() : enterFullscreen());

  const enterFullscreen = async () => {
    try {
      await document.body?.requestFullscreen();
      setFullScreenMode(true);
    } catch (err) {
      console.log('Failed to request gantt fullscreen mode ', err);
    }
  };

  const exitFullscreen = async () => {
    try {
      await document.exitFullscreen();
      setFullScreenMode(false);
    } catch (err) {
      console.log('Failed to request gantt exitFullscreen mode ', err);
    }
  };

  const renderSearchComponent = () => {
    if (!featureFlags.ALL) {
      return null;
    }
    return <TopSearch actions={actions.topSearch} />;
  };
  const renderFullScreen = () => {
    if (!isTouchApp()) {
      return null;
    }
    const Icon = fullScreenMode ? FullScreenExitIcon : FullScreenIcon;
    return (
      <div className='full-screen-container'>
        <Icon className={cn('top-bar-icon', { 'full-screen-mode': fullScreenMode })}
              onClick={toggleFullScreen} />
      </div>
    );
  };

  const renderNotifications = () => {
    if (!featureFlags.ALL) {
      return null;
    }
    return <NotificationsCenterContainer />;
  };

  const renderSchedulingTriggers = () => {
    if (featureFlags.ALL && PermissionManager.getOrgPreferences().schedulerEnabled) {
      return (
      <div className="scheduling-triggers-container" ref={triggersPanelRef}>
        <TriggersIcon className={cn('top-bar-icon', { active: showTriggersMenu })} onClick={() => setShowTriggersMenu(true)} />
        <LastTriggersPanel open={showTriggersMenu} actions={{ ...actions.helpMenu, ...actions.system }}  />
      </div>
      );
    }
  }

  const renderLocationIcon = () => {
    const locationType = topNavigatorData.getIn(['currentLocation', 'type']) || '';
    const LocationIcon = getLocationIcon(locationType);

    if (!LocationIcon) {
      return null;
    }

    return <LocationIcon className="location-icon" />;
  };

  const locationName = topNavigatorData.getIn(['currentLocation', 'name']);
  const locationSubName = topNavigatorData.getIn(['currentLocation', 'subname']);
  const status = topNavigatorData.getIn(['currentLocation', 'status']);
  const statusLabel = status ? `- ${status} -` : '';

  return (
    <div className={cn('app-topbar', {sandbox: isSandboxOrg()})}>
      <LogoSection sHomePageData={topNavigatorData.get('homePage')}/>
      <div className="left-section">
        <div className="location-section">
          <div className="sandbox-section">{isSandboxOrg() ? 'Sandbox' : ''}</div>
          {renderLocationIcon()}
          <div className="location-title">
            <h1>{locationName}</h1>
            <h2>{locationSubName}</h2>
          </div>
          <div className="location-status">{statusLabel}</div>
        </div>
      </div>

      <div className="middle-section"/>

        <div className="right-section inline-block">
          {renderSearchComponent()}

          {renderSchedulingTriggers()}

          <div className="help-menu-container" ref={helpMenuRef}>
            <HelpMenuIcon className={cn('top-bar-icon', {active: showHelpMenu})} onClick={() => setShowHelpMenu(true)}/>
            <HelpMenu open={showHelpMenu} actions={{...actions.helpMenu, ...actions.system}}
                      sServerVersion={sServerVersion}/>
          </div>

          {renderFullScreen()}

          {renderNotifications()}

          <div className="user-menu-container" ref={userMenuRef}>
            <UserMenuIcon className={cn('top-bar-icon', {active: showUserMenu})} onClick={() => setShowUserMenu(true)}/>
            <UserMenu
              featureFlags={featureFlags}
              open={showUserMenu}
              actions={{...actions.userMenu, ...actions.system}}
              sServerVersion={sServerVersion}
              sData={sLoggedInUser}
            />
          </div>
        </div>
      </div>
      );
      }

      AppTopbar.propTypes = {
      topNavigatorData: PropTypes.object,
      featureFlags: PropTypes.object,
      actions: PropTypes.object,
      sServerVersion: PropTypes.string,
      sLoggedInUser: PropTypes.object.isRequired,
    };
