// @ts-check


import Network from '../../../../infrastructure/js/modules/network';
import * as liveMapService from '../../services/LiveMap/liveMapService.js';

import { createLabelHelper } from '../../../../infrastructure/js/utils/labelHelper';
import DateTimeHelper from '../../../../infrastructure/js/utils/dateTimeHelper';

import { navigationStates } from '../../enums/navigationStates';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';

// import LiveSearchUtils from './LiveSearchUtils.js';

import LiveSearchUtils from '../../components/LiveMap/Utils/LiveSearchUtils.js';

// import { store } from './../../index.js';



/**
 * @typedef {import("../../types/PL_ReduxTypes").ReduxDispatch} ReduxDispatch
 * @typedef {import("../../types/PL_ReduxTypes").ReduxGetState} ReduxGetState
 * 
 * @typedef {import("../../types/PL_ReduxTypes").PL_FullState} PL_FullState
 * 
 * @typedef {import("./../../types/ServerTypes/Models/LiveMap").LivemapLocationDetailsResponseModel} LivemapLocationDetailsResponseModel
 * 
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchRequestModel} LiveSearchRequestModel
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * 
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultSelection} LiveSearchResultSelection
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultBlock} LiveSearchResultBlock
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * 
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").BlockGroup} BlockGroup
 * 
 * 
 */





/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {

  LIVEMAP_PAGE__RESET_MAP_DATA: 'LIVEMAP_PAGE__RESET_MAP_DATA',
  
  LIVEMAP_PAGE__FETCHSETTINGS_STARTED: 'LIVEMAP_PAGE__FETCHSETTINGS_STARTED',
  LIVEMAP_PAGE__FETCHSETTINGS_FINISHED: 'LIVEMAP_PAGE__FETCHSETTINGS_FINISHED',

  LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_STARTED: 'LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_STARTED',
  LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_FINISHED: 'LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_FINISHED',
  LIVEMAP_PAGE__TOOLTIP_LOCATION_HIDE: 'LIVEMAP_PAGE__TOOLTIP_LOCATION_HIDE',

  LIVEMAP_PAGE__FULLSTATUS_FETCHDATA_FINISHED: 'LIVEMAP_PAGE__FULLSTATUS_FETCHDATA_FINISHED',
  
  LIVEMAP_PAGE__ZOOMLEVEL_CHANGED: 'LIVEMAP_PAGE__ZOOMLEVEL_CHANGED',
  
  LIVEMAP_PAGE__SET_FLOOR: 'LIVEMAP_PAGE__SET_FLOOR',


  LIVEMAP_PAGE__SEARCH_FETCHDATA_STARTED: 'LIVEMAP_PAGE__SEARCH_FETCHDATA_STARTED',
  LIVEMAP_PAGE__SEARCH_FETCHDATA_FINISHED: 'LIVEMAP_PAGE__SEARCH_FETCHDATA_FINISHED',
  // LIVEMAP_PAGE__SEARCHRESULTS_HIDE: 'LIVEMAP_PAGE__SEARCHRESULTS_HIDE',
  LIVEMAP_PAGE__SEARCH_CLEAR: 'LIVEMAP_PAGE__SEARCH_CLEAR',
  // LIVEMAP_PAGE__SEARCHRESULTS_CLEAR: 'LIVEMAP_PAGE__SEARCHRESULTS_CLEAR',
  
  LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_STARTED: 'LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_STARTED',
  LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_FINISHED: 'LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_FINISHED',


  LIVEMAP_PAGE__SEARCH_FILTER_CHANGED: 'LIVEMAP_PAGE__SEARCH_FILTER_CHANGED',
  
  LIVEMAP_PAGE__SEARCH_RESULT_SELECTION_CHANGED: 'LIVEMAP_PAGE__SEARCH_RESULT_SELECTION_CHANGED',
  


};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {


  resetMapData: function () {
    return { type: actionTypes.LIVEMAP_PAGE__RESET_MAP_DATA };
  },

  /** @param {any} payload */
  zoomLevelChanged: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__ZOOMLEVEL_CHANGED, payload: payload };
  },

  /** @param {string} payload */
  setFloor: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__SET_FLOOR, payload: payload };
  },

  fetchMapSettingsStarted: function () {
    return { type: actionTypes.LIVEMAP_PAGE__FETCHSETTINGS_STARTED };
  },


  /** @param {any} payload */
  fetchMapSettingsFinished: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__FETCHSETTINGS_FINISHED, payload: payload };
  },


  tooltipLocationHide: function () {
    return { type: actionTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_HIDE };
  },
  fetchLocationDetailsStarted: function () {
    return { type: actionTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_STARTED };
  },

  /** @param {any} payload */
  fetchLocationDetailsFinished: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_FINISHED, payload: payload };
  },


  /** @param {any} payload */
  fetchFullStatusFinished: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__FULLSTATUS_FETCHDATA_FINISHED, payload: payload };
  },

  /** @param {LiveSearchRequestModel} requestData */
  mapSearchStarted: function (requestData) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_FETCHDATA_STARTED, payload: requestData };
  },

  /** @param {LiveSearchResultResponseModel} payload */
  mapSearchFinished: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_FETCHDATA_FINISHED, payload: payload };
  },
  // hideMapSearchResults: function () {
  //   return { type: actionTypes.LIVEMAP_PAGE__SEARCHRESULTS_HIDE };
  // },

  
  /** @param {LiveSearchRequestModel} requestData */
  mapSearchMoreStarted: function (requestData) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_STARTED, payload: requestData };
  },
  
  /** @param {LiveSearchResultResponseModel} payload */
  mapSearchMoreFinished: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_FINISHED, payload: payload };
  },

  

  clearSearch: function () {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_CLEAR };
  },

  // clearSearchResults: function () {
  //   return { type: actionTypes.LIVEMAP_PAGE__SEARCHRESULTS_CLEAR };
  // },


  /** @param {any} payload */
  setActiveFilter: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_FILTER_CHANGED, payload: payload };
  },

  /** @param {LiveSearchResultSelection} payload */
  setResultSelection: function (payload) {
    return { type: actionTypes.LIVEMAP_PAGE__SEARCH_RESULT_SELECTION_CHANGED, payload: payload };
  },


};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

/** @param {any} data */
jsxActions.resetMapData = function (data) {

  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.resetMapData(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.zoomLevelChanged = function (data) {

  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.zoomLevelChanged(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.setFloor = function (data) {

  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.setFloor(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.fetchMapSettings = function (data) {

  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.fetchMapSettings(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.fetchLocationDetails = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.fetchLocationDetails(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.fetchFullStatus = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.fetchFullStatus(dispatch, getState)(data);
  }
};

/** @param {any} data */
jsxActions.hideLocationTooltip = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.hideLocationTooltip(dispatch, getState)(data);
  }
};

/** @param {LiveSearchRequestModel} data */
jsxActions.searchMap = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.searchMap(dispatch, getState)(data);
  }
};

/** @param {number} locationId */
jsxActions.searchMap_LoadMoreForLocation = function (locationId) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.searchMap_LoadMoreForLocation(dispatch, getState)(locationId);
  }
};

// /** @param {any} data */
// jsxActions.hideMapSearchResults = function (data) {
//   return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
//     api.hideMapSearchResults(dispatch, getState)(data);
//   }
// };

/** @param {any} data */
jsxActions.clearSearch = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.clearSearch(dispatch, getState)(data);
  }
};

// /** @param {any} data */
// jsxActions.clearSearchResults = function (data) {
//   return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
//     api.clearSearchResults(dispatch, getState)(data);
//   }
// };


/** @param {any} data */
jsxActions.filterClicked = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    api.filterClicked(dispatch, getState)(data);
  }
};


/** @param {BlockGroup} blockGroup */
jsxActions.resultGroupTitleClicked = function (blockGroup) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    // console.log('resultGroupTitleClicked ===> ', blockGroup);
    
    let state = getState();
    let currentSelection = state.liveMapPage.search.resultSelection;
    
    let newFloorName = blockGroup.groupName;
    
    
    /** @type {LiveSearchResultSelection} */
    let selection = {
      floor: newFloorName,
      category: null,
      locationName: null,
      item: null,
    };
    
    
    api.setFloor(dispatch, getState)(newFloorName);
    
    dispatch(actions.setResultSelection(selection));
    
    
  }
};

/** @param {LiveSearchResultBlock} data */
jsxActions.resultBlockTitleClicked = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    // console.log('resultBlockTitleClicked ===> ', data.type);
    
    let state = getState();
    let currentSelection = state.liveMapPage.search.resultSelection;
    
    /** @type {LiveSearchResultSelection} */
    let selection = {
      floor: currentSelection.floor,
      category: data.type,
      locationName: null,
      item: null,
    };
    
    dispatch(actions.setResultSelection(selection));
  }
};

/** @param {LiveSearchResultBlock} data */
jsxActions.resultBlockLocationTitleClicked = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    // console.log('resultBlockTitleClicked ===> ', data.type);
    
    let state = getState();
    let currentSelection = state.liveMapPage.search.resultSelection;
    
    /** @type {LiveSearchResultSelection} */
    let selection = {
      floor: currentSelection.floor,
      category: null,
      // locationName: data.items[0].location.locationName,
      locationName: data.totalPerLocationCounts[0].locationName,
      item: null,
    };
    
    dispatch(actions.setResultSelection(selection));
  }
};

/** @param {LiveSearchResultItem} data */
jsxActions.resultBlockItemClicked = function (data) {
  return function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
    // console.log('resultBlockItemClicked ===> ', data.type);
    
    let state = getState();
    let currentSelection = state.liveMapPage.search.resultSelection;
    
    /** @type {LiveSearchResultSelection} */
    let selection = {
      floor: currentSelection.floor,
      category: null,
      locationName: null,
      item: data,
    };
    
    dispatch(actions.setResultSelection(selection));
  }
};



/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};



api.resetMapData = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /**
   * @param {string | number} mapId
   */
  return function (mapId) {
    // console.log(`actions.resetMapData ===> `);
    dispatch(actions.resetMapData());
  }
  
};

api.zoomLevelChanged = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /**
   * @param {string} newZoomLevel
   */
  return function (newZoomLevel) {
    // console.log(`actions.zoomLevelChanged ===> `);
    dispatch(actions.zoomLevelChanged(newZoomLevel));
  }
  
};


api.setFloor = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /**
   * @param {string} newFloorName
   */
  return function (newFloorName) {
    // console.log(`actions.zoomLevelChanged ===> `);

    let state = getState();
    let mapState = state.liveMapPage;
      
    if (mapState.floorName === newFloorName) {
      return;
    }
    
    dispatch(actions.setFloor(newFloorName));
  }
  
};


api.fetchMapSettings = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /**
   * @param {string | number} mapId
   */
  return function (mapId) {

    // console.log(`fetchMapSettings API START ===> `);

    dispatch(actions.fetchMapSettingsStarted());

    // @ts-ignore
    return liveMapService.fetchMapSetting(mapId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Map Settings Failed');
        // messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      
      // console.log('response ===> ', response);
      // console.log('response.dataList ===> ', response.dataList);


      // let activeMaps = response.dataList.filter((item) => {
      //   return item.enabled;
      // });
      // // console.log('activeMaps ===> ', activeMaps);
      // let activeMapInfo = activeMaps[0];

      let activeMapInfo = response.data;
      let mapName = activeMapInfo.name;


      //////////////////////////////
      // Set page header data.
      // 
      let topNavigatorData = {
        // name: createLabelHelper('mat.sideNavigator.').get('managerView'),
        name: mapName,
        // type: navigationStates.MANAGER_REPORT,
        type: navigationStates.LIVE_MAP,
        id: -1,
        components: []
      };

      navigatorApi.setData(dispatch, getState)(topNavigatorData);



      dispatch(actions.fetchMapSettingsFinished(activeMapInfo));
    });


  }
};


api.fetchLocationDetails = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /**
   * @param {{ externalId: string; }} requestData
   */
  return function (requestData) {

    // let externalId = requestData.externalId;
    // if (!externalId) {
    //   return;
    // }

    // console.log(`api.fetchLocationDetails ===> `, requestData);


    //////////////////////////////////////////////////
    // Try finding locally in current fullStatus.
    //

    let state = getState();
    let foundItem = findItemInFullStatus(state, requestData);
    // console.log(`XXXXXXXXXXXXXXXXXXX foundItem ===> `, foundItem);

    if (foundItem) {
      augmentLocationDetailsServerItem(state, foundItem);
      // console.log('XXXXXXXXXXXXXXXXXXX foundItem AUGMENTED ===> ', foundItem);
      dispatch(actions.fetchLocationDetailsFinished(foundItem));
      return;
    }


    ////////////////////////////////////////////////////
    // Not found locally, don't fetch from server.
    //
    dispatch(actions.fetchLocationDetailsStarted()); // Needed for proper state change to trigger render on consecutive null ("no data") responses.
    dispatch(actions.fetchLocationDetailsFinished(null));


    // //////////////////////////////////////////////////
    // // Fallback:
    // // Fetch from server instead.
    // //

    // dispatch(actions.fetchLocationDetailsStarted());

    // if (!liveMapService) {
    //   return;
    // }



    // let networkPromise = liveMapService.fetchLocationDetails(requestData);
    // if (!networkPromise) {
    //   return;
    // }

    // return networkPromise.then((response) => {
    //   if (!Network.isResponseValid(response)) {
    //     console.debug('Fetch Location Details Failed');
    //     // messageDialogApi.responseError(dispatch, getState)(response);
    //     dispatch(actions.fetchLocationDetailsFinished(null));
    //     return { success: false };
    //   }


    //   let item = response.data;
    //   augmentLocationDetailsServerItem(getState(), item);

    //   dispatch(actions.fetchLocationDetailsFinished(response.data));
    // });

  }
};


api.fetchFullStatus = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /** @param {any} requestData */
  return function (requestData) {

    // console.log(`fetchFullStatus ===> `, requestData);

    // dispatch(actions.fetchLocationDetailsStarted());

    // @ts-ignore
    return liveMapService.fetchFullStatus(requestData).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Full Status Failed');
        // messageDialogApi.responseError(dispatch, getState)(response);
        dispatch(actions.fetchFullStatusFinished(null));
        return { success: false };
      }
      
      dispatch(actions.fetchFullStatusFinished(response.data));



      /////////////////////////////////////////
      // Call tooltip data API if opened.
      //

      let state = getState();
      let liveMapState = state.liveMapPage;

      if (liveMapState.normal.locationTooltipState === 'VISIBLE') {

        let tooltipLocation = liveMapState.normal.locationDetails;
        if (tooltipLocation) {
          api.fetchLocationDetails(dispatch, getState)({ externalId: tooltipLocation.name });
        }

      }


    });


  }
};


api.hideLocationTooltip = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /** @param {any} requestData */
  return function (requestData) {
    
    let state = getState();
    let s = state.liveMapPage;
    if (s.normal.locationTooltipState === 'HIDDEN') {
      return;
    }
    
    
    
    // console.log(`hideLocationTooltip ===> `,  requestData);
    dispatch(actions.tooltipLocationHide());
  }
};


api.searchMap = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /** @param {LiveSearchRequestModel} requestData */
  return function (requestData) {

    // console.log(`fetchMapSettings API START ===> `);

    dispatch(actions.mapSearchStarted(requestData));

    return liveMapService.searchMap(requestData).then((response) => {

      if (!Network.isResponseValid(response)) {
        console.error('Search Map Failed');
        // messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      // console.log(`searchMap ok ===> `,  response);
      // console.log(`searchMap ok ===> `,  response.data);
      
      api.hideLocationTooltip(dispatch, getState)({});
      dispatch(actions.mapSearchFinished(response.data));
    });


  }
};


api.searchMap_LoadMoreForLocation = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /** @param {number} locationId */
  return function (locationId) {

    // console.log(`searchMap_LoadMoreForLocation API START ===> `, locationId);
    
    let state = getState();
    let mapState = state.liveMapPage;
    
    let currentRequestModel = mapState.search.searchRequestModel;
    if (!currentRequestModel) {
      return;
    }
    
    
    let newRequestModel = LiveSearchUtils.createSearchRequestModelForLocation_More(currentRequestModel, locationId);
    
    dispatch(actions.mapSearchMoreStarted(newRequestModel));

    return liveMapService.searchMap(newRequestModel).then((response) => {

      if (!Network.isResponseValid(response)) {
        console.error('Search Map more Failed');
        // messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      // console.log(`searchMap more ok ===> `,  response);
      // console.log(`searchMap more ok ===> `,  response.data);
      
      dispatch(actions.mapSearchMoreFinished(response.data));
    });


  }
};


// api.hideMapSearchResults = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

//   /** @param {any} requestData */
//   return function (requestData) {
//     // console.log(`hideMapSearchResults ===> `,  requestData);
//     dispatch(actions.hideMapSearchResults());
//   }

// };


api.clearSearch = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

  /** @param {any} requestData */
  return function (requestData) {
    // console.log(`hideMapSearchResults ===> `,  requestData);
    dispatch(actions.clearSearch());
  }

};

// api.clearSearchResults = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {

//   /** @param {any} requestData */
//   return function (requestData) {
//     // console.log(`hideMapSearchResults ===> `,  requestData);
//     dispatch(actions.clearSearchResults());
//   }

// };



api.filterClicked = function (/** @type {ReduxDispatch} */ dispatch, /** @type {ReduxGetState} */ getState) {
  /** @param {any} requestData */
  return function (requestData) {
    
    let newFilterType = requestData;

    let liveMapPageState = getState()?.liveMapPage;
    if (!liveMapPageState) {
      return;
    }
    // D.log('API ACTION state ===> ', liveMapPageState);
    // D.log('API ACTION searchRequestModel ===> ', liveMapPageState.searchRequestModel);

    let currentFilter = liveMapPageState.search.searchFilter;
    if (currentFilter === newFilterType) {
      return;
    }

    // api.clearSearchResults(dispatch, getState)({});
    dispatch(actions.setActiveFilter(newFilterType));
    
    
    let currentRequestModel = liveMapPageState.search.searchRequestModel;
    if (!currentRequestModel) {
      return;
    }
    
    let newRequestModel = LiveSearchUtils.modifySearchRequestModelByFilter(currentRequestModel, newFilterType);
    
    
    api.searchMap(dispatch, getState)(newRequestModel);
    
  }
};





//////////////////////////////////////////////////////////////////


/**
 * @param {PL_FullState} state
 * @param {{ externalId: string; }} requestData
 */
function findItemInFullStatus(state, requestData) {
  let externalId = requestData.externalId;
  if (!externalId) {
    return null;
  }


  let mapState = state?.liveMapPage;
  if (!mapState) {
    return null;
  }

  let fullStatus = mapState.plant.fullStatus;
  if (!fullStatus) {
    return null;
  }


  // @ts-ignore
  let foundSection = fullStatus.sections.find((item) => {
    return item.name === externalId;
  });
  if (foundSection) {
    return foundSection;
  }

  // @ts-ignore
  let foundStation = fullStatus.stations.find((item) => {
    return item.name === externalId;
  });
  if (foundStation) {
    return foundStation;
  }

  return null;
}



/**
 * @param {PL_FullState} state
 * @param {LivemapLocationDetailsResponseModel} item
 */
function augmentLocationDetailsServerItem(state, item) {

  // let labels = createLabelHelper('mat.reports.managerView.');

  let serverTimeSeconds = state.system.get('serverDatetime');
  item.m_ActivityTime = DateTimeHelper.FormatDateObjectToRelativeTime(item.activityTime, serverTimeSeconds);
  item.m_name = item.name;

}


// /** 
//  * @param {LiveSearchRequestModel} requestModel
//  * @param {string} filterType 
//  * 
//  * @returns {LiveSearchRequestModel}
//  * 
//  * */
// function modifySearchRequestModelByFilter(requestModel, filterType) {
  
//   if (filterType === 'ALL') {
//     requestModel.types = ["TOOL", "MATERIAL", "LOCATION", "WO"];
//     requestModel.max = 3;
//     return requestModel;
//   } 
  
//   if (filterType === 'TOOLS') {
//     requestModel.types = ["TOOL"];
//     requestModel.max = 20;
//     return requestModel;
//   }
  
//   if (filterType === 'MATERIALS') {
//     requestModel.types = ["MATERIAL"];
//     requestModel.max = 20;
//     return requestModel;
//   }
  
//   if (filterType === 'LOCATIONS') {
//     requestModel.types = ["LOCATION"];
//     requestModel.max = 20;
//     return requestModel;
//   }
  
//   if (filterType === 'WO') {
//     requestModel.types = ["WO"];
//     requestModel.max = 20;
//     return requestModel;
//   }

//   throw new Error('Unknown filter type');
// }




