import { useLayoutEffect, useRef, useCallback, useEffect } from 'react';


/**
 * Custom hook for detecting an element's size changes.
 *
 * @param {function} onResizeCallback - The callback to be called when the element's size changes.
 * @returns {function} - The function to be called on the element to be observed.
 */

export default function useResizeObserver(onResizeCallback) {
  const observer = useRef();

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return useCallback((element) => {
    if (!element) {
      return;
    }

    if (!observer.current) {
      observer.current = new ResizeObserver((entries) => {
        onResizeCallback(entries[0]);
      });
    }

    observer.current.observe(element);
  } , [onResizeCallback]);
}
