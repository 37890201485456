import { Map } from 'immutable';

import {default as updateKitPliesFormReducer} from './updateKitPliesFormReducer';

let defaultState = {

};

export default function(state = Map(defaultState), action) {

  state = state.set('updateKitPliesForm', updateKitPliesFormReducer(state.get('updateKitPliesForm'), action));

  return state;

}






