import { List, Map } from 'immutable';

import { actionTypes as schedulerGanttPageActionTypes } from '../../../actions/SchedulerPage/schedulerGanttPageActions';
import { actionTypes as schedulerTasksPageActionTypes } from '../../../actions/SchedulerPage/schedulerTasksPageActions';
import { actionTypes as commonSchedulerActionTypes } from '../../../actions/SchedulerPage/commonSchedulerActions';
import { default as scheduleTaskDialogReducer } from './scheduleTaskDialogReducer';
import { default as createTaskDialogReducer } from './createTaskDialogReducer';
import { default as autoSchedulerDialogReducer } from './autoSchedulerDialogReducer';

let defaultState = {
  loading: false,
  schedulerData: null,
  data: {},
  counters: {},
  backtrackList: {
    undoItems: [],
    redoItems: [],
    lastMovedItemDate: null,
  },
  asyncScheduling: Map({
    status: null,
    summary: null,
    scheduledBy: null,
    statusPollingInterval: null,
    isAborting: false,
    activityLogId: null
  }),
  schedulerTasksGenerated: false,
};

export default function (state = Map(defaultState), action) {
  state = state.set('scheduleTaskDialog', scheduleTaskDialogReducer(state.get('scheduleTaskDialog'), action));
  state = state.set('createTaskDialog', createTaskDialogReducer(state.get('createTaskDialog'), action));
  state = state.set('autoSchedulerDialog', autoSchedulerDialogReducer(state.get('autoSchedulerDialog'), action));

  switch (action.type) {
    case schedulerGanttPageActionTypes.SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED:
      state = state.set('schedulerData', action.payload);
      return state;

    case schedulerGanttPageActionTypes.SCHEDULER_GANTT_ZOOM_LEVEL:
      {
        let jsData = state.get('schedulerData');
        jsData.zoomLevel = action.payload;
        state = state.set('schedulerData', { ...jsData });
      }
      return state;

    case schedulerGanttPageActionTypes.SCHEDULER_GANTT_SCROLL_POSITION:
      {
        let jsData = state.get('schedulerData');
        jsData.scrollPosition = action.payload;
        state = state.set('schedulerData', { ...jsData });
      }
      return state;

    case commonSchedulerActionTypes.SCHEDULER_PAGE_RESET_DATA:
      return Map(defaultState);
    case commonSchedulerActionTypes.SCHEDULER_SET_LOADING:
      return state.set('loading', action.payload);
    case schedulerTasksPageActionTypes.SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED:
      return state.set('counters', action.payload);
    case schedulerTasksPageActionTypes.SCHEDULER_TASKS_GENERATED:
      return state.set('schedulerTasksGenerated', true);
    case schedulerGanttPageActionTypes.SCHEDULER_ADD_BACKTRACK_LIST_ITEM:
      {
        const backtrackList = state.get('backtrackList');
        const { payload } = action;
        backtrackList.undoItems.push({ ...payload, actionId: Math.random() });
        state = state.set('backtrackList', backtrackList);
      }
      return state;
    case schedulerGanttPageActionTypes.SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM:
      {
        const backtrackList = state.get('backtrackList');
        const {
          payload: { type },
        } = action;
        if (type === 'undo' && !!backtrackList.undoItems.length) {
          backtrackList.redoItems.push(backtrackList.undoItems.pop());
        }
        if (type === 'redo' && !!backtrackList.redoItems.length) {
          backtrackList.undoItems.push(backtrackList.redoItems.pop());
        }
        state = state.set('backtrackList', backtrackList);
      }
      return state;
    case schedulerGanttPageActionTypes.SCHEDULER_CLEAR_BACKTRACK_LIST:
      return state.set('backtrackList', {
        undoItems: [],
        redoItems: [],
      });
    case schedulerGanttPageActionTypes.SCHEDULER_CLEAR_BACKTRACK_REDO_LIST:
      {
        const backtrackList = state.get('backtrackList');
        state = state.set('backtrackList', {
          undoItems: backtrackList.undoItems,
          redoItems: [],
        });
      }
      return state;
    case schedulerGanttPageActionTypes.SCHEDULER_STORE_MOVING_START_DATE:
      {
        const backtrackList = state.get('backtrackList');
        const { payload } = action;
        backtrackList.lastMovedItemDate = payload;
        state = state.set('backtrackList', backtrackList);
      }
      return state;
    case commonSchedulerActionTypes.SCHEDULER_ASYNC_STATUS:
      {
        const { payload: {status, summary, statusPollingInterval, activityLogId, scheduledBy} } = action;
        state = state.setIn(['asyncScheduling', 'status'], status);
        state = state.setIn(['asyncScheduling', 'summary'], summary);
        state = state.setIn(['asyncScheduling', 'activityLogId'], activityLogId);
        state = state.setIn(['asyncScheduling', 'scheduledBy'], scheduledBy);
        state = state.setIn(['asyncScheduling', 'statusPollingInterval'], statusPollingInterval);
      }
      return state;
    case commonSchedulerActionTypes.SCHEDULER_ASYNC_ABORTING:
      {
        const { payload } = action;
        state = state.setIn(['asyncScheduling', 'isAborting'], payload)
        return state;
      }
    default:
      return state;
  }
}
