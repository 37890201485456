import Network from 'infrastructure/js/modules/network';

export function fetchProjects(query) {
  return Network.post('projects/get-projects', query);
}

export function fetchProjectsByParts(id) {
  return Network.get('projects/byPartType', {partTypeId: id});
}

export function fetchAllProjects() {
  return Network.get('projects');
}

export function createProject(data) {
  return Network.post('projects', data);
}

export function editProject (data) {
  return Network.post('projects/update', {projects: [data]});
}

export function removeProjects(ids) {
  return Network.delete('projects/', {projectIds: ids.ids});
}
