import cn from 'classnames';
import Label from 'infrastructure/js/components/Label/label.js';
import KittedPlyIcon from '../../../../assets/svg/ply-kitted.svg';
import UnkittedPlyIcon from '../../../../assets/svg/ply-unkitted.svg';

import './kitPly.scss';
import PropTypes from 'prop-types';


function KitPly (props) {

  const {plyIndex, plyTypeBusinessId, kitted=false, onClickCallback, bagId, bagBusinessId} = props;

  const toggleKitted = () => {
    onClickCallback?.(
      {
        kitted: !kitted,
        plyTypeBusinessId: plyTypeBusinessId,
        plyIndex: plyIndex,
        bagId: bagId,
        bagBusinessId: bagBusinessId,
      }
    )
  }

  return (
    <div id={`${bagId ?? 'ply'}-${plyIndex}`} className={cn('kit-ply', {'kitted': kitted })} onClick={toggleKitted}>
      <div className="kit-ply_index">{'#' + plyIndex}</div>
      <Label className="kit-ply_name" text={plyTypeBusinessId}></Label>
      {kitted ? <KittedPlyIcon className="kit-ply_icon"/> : <UnkittedPlyIcon className="kit-ply_icon"/>}
    </div>
  )
}

KitPly.propTypes = {
  plyTypeBusinessId: PropTypes.string.isRequired,
  plyIndex: PropTypes.number.isRequired,
  bagId: PropTypes.string,
  bagBusinessId: PropTypes.string,
  kitted: PropTypes.bool,
  onClickCallback: PropTypes.func,
}

export default KitPly;
