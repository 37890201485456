import Network from 'infrastructure/js/modules/network';

export function fetchMaterials(query) {
  return Network.post('materials/get-materials', query);
}

export function fetchAllMaterials() {
  return Network.get('materials');
}

export function createMaterial(data) {
  return Network.post('materials', data);
}

export function editMaterial (data) {
  return Network.post('materials/update', {materials: [data]});
}

export function removeMaterials(ids) {
  return Network.delete('materials/', {materialIds: ids.ids});
}

export function fetchInventory(query) {
  return Network.post('inventory/items', query);
}
export function fetchInventoryByWeight(query) {
  return Network.post('inventory/items-by-weight', query);
}
