import useLabels from 'infrastructure/js/hooks/useLabels';
import Button from 'infrastructure/js/components/controls/Button/button';
import { autoSchedulingStatuses } from '../../../../../../mat/js/enums/autoSchedulingStatuses';
import CloseIcon from 'infrastructure/assets/svg/clear.svg';

import './schedulingIndicatorPanel.scss';

export default function SchedulingIndicatorPanel({ actions, statusData }) {
  const labels = useLabels('mat.scheduler.gantt.header.progressIndicator.panel.');
  const isInProgress = statusData.get('status') === autoSchedulingStatuses.BUSY;
  const summary = statusData.get('summary');
  const numOfSuccesses = summary?.scheduledWorkOrdersCount || 0;
  const numOfFailures = summary?.unassignedWorkOrdersCount || 0;
  const { hide, abort, showMoreDetails } = actions;

  const onShowMoreDetails = () => {
    hide();
    showMoreDetails();
  };

  const renderSummary = () => {
    if (!summary) {
      return null;
    }

    return (
      <>
        <div className="summary-row">
          <span className="success">{numOfSuccesses}</span> {labels.get('assigned', undefined, { numOfSuccesses })}
        </div>
        <div className="summary-row">
          <span className="failure">{numOfFailures}</span> {labels.get('unassigned', undefined, { numOfFailures })}
        </div>
        <div className="summary-row">
          {labels.get('scheduledBy')}: {statusData.get('scheduledBy')}
        </div>
      </>
    );
  };

  const title = isInProgress ? labels.get('inProgress') : labels.get('completed');

  return (
    <div>
      <div className="indicator-panel-header">
        <span className="indicator-panel-title">{title}</span>
        <button className="close-btn" onClick={hide}>
          <CloseIcon />
        </button>
      </div>

      <div className="indicator-panel-summary">{isInProgress ? labels.get('notify') : renderSummary()}</div>

      <div className="indicator-panel-footer">
        {isInProgress ? (
          <>
            <button onClick={abort} className="btn btn-default border-btn no-icon">
              {labels.get('btn.abort')}
            </button>
            <button onClick={hide} className="btn btn-primary">
              {labels.get('btn.ok')}
            </button>
          </>
        ) : numOfFailures > 0 ? (
          <Button bsStyle="link" className="view-details-link" onClick={onShowMoreDetails}>
            {labels.get('btn.viewDetails')}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
