import Network from 'infrastructure/js/modules/network';

export function createBag(data) {
  return Network.post('bags', data);
}

export function updateBags(data) {
  return Network.put('bags', {bags: [data]});
}

export function deleteBags({ids}) {
  return Network.delete('bags', {bagIds: ids});
}

export function fetchBags(query = {}) {
  return Network.post('bags/items', query);
}

export function searchBags(query = {}) {
  return Network.post('bags/search/items', query);
}