import Network from 'infrastructure/js/modules/network';
import * as locationsService from '../../services/Locations/locationsService';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {navigationStates} from '../../enums/navigationStates';
import {api as navigatorApi} from '../AppTopbar/navigatorActions';
import * as managerViewService from '../../services/Reports/managerViewService';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actionTypes = {
  MANAGER_VIEW_FETCH_TREE_DATA_FINISHED:      'MANAGER_VIEW_FETCH_TREE_DATA_FINISHED',
  MANAGER_VIEW_FETCH_LOCATIONS_DATA_FINISHED: 'MANAGER_VIEW_FETCH_LOCATIONS_DATA_FINISHED',
  MANAGER_VIEW_SET_LOADING:                   'MANAGER_VIEW_SET_LOADING',
  MANAGER_VIEW_UNMOUNT:                       'MANAGER_VIEW_UNMOUNT',
};

const actions = {
  setLoading: function (payload) {
    return {type: actionTypes.MANAGER_VIEW_SET_LOADING, payload: payload};
  },
  fetchPlantDataFinished: function (payload) {
    return {type: actionTypes.MANAGER_VIEW_FETCH_TREE_DATA_FINISHED, payload: payload};
  },
  fetchLocationsDataFinished: function (payload) {
    return {type: actionTypes.MANAGER_VIEW_FETCH_LOCATIONS_DATA_FINISHED, payload: payload};
  },
  unmount: function () {
    return { type: actionTypes.MANAGER_VIEW_UNMOUNT };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    return api.init(dispatch, getState)();
  }
};

jsxActions.reload = function(sectionId) {
  return function(dispatch, getState) {
    api.fetchLocations(dispatch, getState)(sectionId);
  }
};

jsxActions.unmount = function () {
  return function (dispatch) {
    dispatch(actions.unmount());
  };
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    navigatorApi.setLoading(true)(dispatch, getState);

    return api.fetchPlant(dispatch, getState)().then((response) => {

      navigatorApi.setLoading(false)(dispatch, getState);

      if (!Network.isResponseValid(response)) {
        console.error('Fetch manager View tree data failed', response);
        return {success: false};
      }

      let topNavigatorData = {
        name: createLabelHelper('mat.sideNavigator.').get('managerView'),
        type: navigationStates.MANAGER_REPORT,
        id: -1,
        components: []
      };

      navigatorApi.setData(dispatch, getState)(topNavigatorData);

      return {success: true, data: response.data};
    })
  }
};

api.fetchPlant = function(dispatch, getState) {
  return function() {
    dispatch(actions.setLoading(true));

    return locationsService.fetchPlant().then((response) => {

      dispatch(actions.setLoading(false));
      if (!Network.isResponseValid(response)) {
        console.error('Get Manager View initial data failed.', response);

        messageDialogApi.responseError(dispatch, getState)(response);
        return null;
      }

      let plant = response.data?.root || {};
      _normalize(plant);

      dispatch(actions.fetchPlantDataFinished([plant]));
      return response;
    });
  }
};

api.fetchLocations = function(dispatch, getState) {
  return function (sectionId) {

    dispatch(actions.setLoading(true));

    let promise1 = managerViewService.fetchItemsBySectionId(sectionId);
    let promise2 = managerViewService.fetchWoCountBySectionId(sectionId);
    let promise3 = managerViewService.fetchAlertsBySectionId(sectionId);
    let promise4 = managerViewService.fetchKPIsBySectionId(sectionId);

    return Promise.all([promise1, promise2, promise3, promise4]).then((allResults) => {
      let isResultValid = allResults.every((result) => {
        return Network.isResponseValid(result);
      });

      dispatch(actions.setLoading(false));

      if (!isResultValid) {
        console.error('\'Fetch the locations data failed.', allResults);
        return {success: false};
      }

      let labels = createLabelHelper('mat.reports.managerView.');

      let serverTimeSeconds = getState().system.get('serverDatetime');

      let items = allResults[0].data?.childrenItems?.map((item, index) => {
        item.m_ActivityTime = DateTimeHelper.FormatDateObjectToRelativeTime(item.activityTime, serverTimeSeconds);
        return item;
      });
      let wos = allResults[1].data?.childrenItems || [];
      let alerts = allResults[2].data?.childrenItems || [];
      let kpis = {
        errors: {
          label: labels.get('errors'),
          value: allResults[3].data?.errors || 0,
        },
        warnings: {
          label: labels.get('warnings'),
          value: allResults[3].data?.warnings || 0,
        }
      }
      dispatch(actions.fetchLocationsDataFinished({items, wos, alerts, kpis}));
      return {success: true};
    });
  }
}


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

//removing children: [] from the leafs (stations, locations)
function _normalize(item) {
  if (item.children?.length) {
    item.children.forEach((child) => {
      _normalize(child);
    });
  }

  if (!item?.isSection) {
    delete item.children;
  }
}
