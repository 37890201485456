import Network from 'infrastructure/js/modules/network';
import {Map} from 'immutable';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService'
import {updateSingleTaskProgress} from '../../../services/Tasks/tasksService'
import {getToolsByKitType} from '../../../services/Tools/toolsService'
import {gridsNames} from '../../../enums/gridsNames';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';
import {api as locationPageApi} from '../locationPageActions.js';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import {api as workOrderDialogApi} from '../WorkOrderView/workOrderDialogActions';
import {api as reportCutDialogApi}  from '../CuttingView/reportCutDialogActions';
import {api as createKitWizardApi} from '../KittingView/createKitWizardActions';
import { api as fetchEntitiesApi } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import {isToolRequired} from '../../../utils/toolRequiredHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_TASKS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    const viewDate = getState().locationPage.get('tasksViewData').get('viewDate');
    const viewEndDate = getState().locationPage.get('tasksViewData').get('viewEndDate');
    return workOrdersService.fetchWorkOrdersTasksByStationId(locationId, { ...query, viewDate, viewEndDate });
  }
}

function _convertToRowData(rows) {

  let items = rows.map((obj) => {
    //Note: obj.id must be used. Inner obj.task.id is NOT in use.
    let {actualTool, actualStartTime, actualEndTime, plannedTool, plannedStartTime, plannedEndTime, plannedDuration,
      validationMessages, locked, split, resource, valid, operationStatus, id, executionProgressPercents, estimatedEndTime,
      startTime, endTime, timeLeft, tokenValue, splitAssignments, vacuumPort, productionLine,
      plannedSetupTimeMinutes, plannedSetupTimePercent} = obj;

    let data = Object.assign({}, obj.task,
      {actualTool, actualStartTime, actualEndTime, plannedTool, plannedStartTime, plannedEndTime, plannedDuration,
        validationMessages, locked, split, resource, valid, operationStatus, id, executionProgressPercents, estimatedEndTime,
        startTime, endTime, timeLeft, tokenValue, splitAssignments, vacuumPort, productionLine,
        plannedSetupTimeMinutes, plannedSetupTimePercent
      }) ;

    data.isRowMaster = true;

    data.extendedDetails = {
      duration: data?.duration ? DateTimeHelper.ConvertMinutesToHoursMinutes(data?.duration) : '',
      kitTypeDescription: data.kitTypeDescription || ' ',
      splitAssignments: data?.splitAssignments
    }

    return data;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

jsxActions.initDetailRow = function(rowIndex, rowData){
  return function (dispatch, getState){
    return api.initDetailRow(dispatch, getState)(rowIndex, rowData)
  }
}

jsxActions.updateSingleTaskEndTime = function(newRowData){
  return function (dispatch, getState){
    return api.updateSingleTaskEndTime(dispatch, getState)(newRowData)
  }
}

jsxActions.updateRowData = function(newRowData){
  return function (dispatch, getState){
    return api.updateRow(dispatch, getState)(newRowData)
  }
}

jsxActions.onOpenDialogClick = function(data){
  return function(dispatch, getState) {

    // prepare data for CUT operation
    if(data.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('CUT')){
      const operationData = {
        preselectedAssets: [{
          affectedWos: [{
            ...data.workOrder,
            objectType: getEnumValue(enumTypes.OBJECT_TYPE)('WO')
          }],
          kitTypeId: data?.kitTypeId
        }],
        allowRollSelection: true
      }

      return reportCutDialogApi.show(dispatch, getState)(operationData)
    }

    // prepare data for KIT operation
    if(data.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('KIT')){
      const reportedCuts = getState().cutKitStation.get('kittingViewData').get('reportedCuts');
      const activeTabOnSubmit = 'tasks';
      data.workOrder = {
        value: data.workOrder.id,
        label: data.workOrder.name,
        data: data.workOrder
      }
      return createKitWizardApi.openWizard(dispatch, getState)({...data, reportedCuts, activeTabOnSubmit}, true);
    }

    // prepare data for any other operation
    workOrderDialogApi.show(dispatch, getState)(data, [{...data.workOrder, objectType: getEnumValue(enumTypes.OBJECT_TYPE)('WO')}]);
  }
}

jsxActions.fetchEntities = function (fetchConfig, callback) {
  return function (dispatch, getState) {

    let prefetchHandler = function(data) {
      if (data) {
        data.locationId = locationPageApi.getLocationId(dispatch, getState)();
        data.viewDate = getState().locationPage.get('tasksViewData').get('viewDate');
      }
    }

    fetchEntitiesApi.fetchEntities(dispatch, getState)(fetchConfig, callback, true, prefetchHandler );
  };
};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

api.updateRow = function (dispatch, getState){
  return function (newRowData){
    const rowsData = getState().grid.get(gridsNames.LOCATION_TASKS).get('rows').toArray();
    const selectedRowsData = getState().grid.get(gridsNames.LOCATION_TASKS).get('selectedRowsData').toArray();

    const rowIndex = rowsData?.findIndex(row => row.id === newRowData.id);
    const selectedRowIndex = selectedRowsData?.findIndex(row => row.id === newRowData.id);

    if(rowIndex >= 0){
      rowsData[rowIndex] = newRowData
      api.updateGridRowData(rowsData, gridsNames.LOCATION_TASKS)(dispatch, getState);
    }

    if (selectedRowIndex >= 0) {
      selectedRowsData[selectedRowIndex] = newRowData;
      jsxActions.onSelectedRowsChanged(gridsNames.LOCATION_TASKS, selectedRowsData)(dispatch, getState);
    }
  }
}

api.initDetailRow = function (dispatch, getState){
  return function (rowData){
    if( isToolRequired(rowData)){
      let payload = {
        kitTypeId : rowData?.kitType?.id,
        taskAssignmentId : rowData?.id,
      }

        getToolsByKitType(payload).then(response => {
        if (!Network.isResponseValid(response)) {
          console.error('Tasks fetch tools failed');
          return;
        }

        const toolSelectOptions = response.dataList?.map(toolData => ({
          value: toolData.id,
          label: toolData.businessId,
          data: toolData
        }))

        const oldRows = getState().grid.get(gridsNames.LOCATION_TASKS).get('rows');
        const rowIndex = oldRows.findIndex(row => row.id === rowData.id);
        const newRows = oldRows.update(rowIndex, row => {
          return Map(row).set('tools', toolSelectOptions).toObject()
        })

        gridActions.apiActions.updateGridRowData(newRows.toArray(), gridsNames.LOCATION_TASKS)(dispatch, getState)
      })
    }
  }
}

api.updateSingleTaskEndTime = function (dispatch, getState){
  return function (newRowData){
    const rowsData = getState().grid.get(gridsNames.LOCATION_TASKS).get('rows').toArray();
    const rowIndex = rowsData?.findIndex(row => row.id === newRowData.id);

    rowsData[rowIndex].loading = true;
    api.updateRow(dispatch, getState)(rowsData[rowIndex]);

    const queryData = {
      estimatedLeftDuration: newRowData?.timeLeftDuration,
    }

    updateSingleTaskProgress(newRowData?.id,queryData).then(response => {

      if (!Network.isResponseValid(response)) {
        console.error('Update Task Progress Failed.');
        rowsData[rowIndex].loading = false;
        api.updateRow(dispatch, getState)(rowsData[rowIndex]);
        return;
      }

      api.updateRow(dispatch, getState)({
        ...newRowData,
        loading: false,
        timeLeft: response?.data?.timeLeft,
        executionProgressPercents: response?.data?.executionProgress
      })
    })
    .catch((err) => {
      console.error('Update Task Progress Failed.', err);
      messageDialogApi.responseError(dispatch, getState)();
    });
  }
}
