import React, {Component} from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';

import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../enums/gridsNames.js';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import ComponentWithErrorCell from '../../../Common/CustomGridCells/ComponentWithErrorCell/componentWithErrorCell.js';
import LabelCell from '../../Common/Cells/LabelCell/labelCell.js';
import editableViewCell from '../../Common/Cells/InputCell/editableViewCell'
import Label from 'infrastructure/js/components/Label/label';
import {List} from 'immutable';
import MultiRowCell from '../../Common/Cells/MultiRowCell/multiRowCell';

require('./SelectNestsStep.scss');

export class SelectNestsStep extends React.PureComponent {

  constructor(props) {
    super(props);

    this.gridLabels = createLabelHelper(`mat.wizards.import.files.reportedCutsAndKitsMultiRolls.grid.selectesnests.`);
    this.labels = createLabelHelper(`mat.wizards.import.files.reportedCutsAndKitsMultiRolls.`);
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig =  [
      {
        fieldName: 'nestBusinessId',
        title: this.gridLabels.get('columns.nest.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelCell,
          valueGetter: (params) => {
            return {errorMessage: params.data.errorPlan ,value: params.data.nestBusinessId, showOnlyError: false};
          },
        }
      },
      {
        fieldName: 'lengthCut',
        title: this.gridLabels.get('columns.lengthcut.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ComponentWithErrorCell,
          valueGetter: (params) => {
            return {errorMessage: params.data.errorLengthCut,value: params.data.lengthCut, params: {enabled: !params.data.isRowDisabled ,value: params.data.lengthCut, rowIndex: params.node.rowIndex}, componentType: editableViewCell, onRowsChanged: this.props.actions.onRowsChanged};
          },
          editable: (params) => {
            return !params.data.isRowDisabled
          },
          onCellValueChanged: function (params) {
            params.newValue.onRowsChanged()
          },
          cellEditor:'editableCell',
          cellEditorParams: {
            inputType:'numeric',
            maxLength: 999
          },

          sortable: false,
        }
      },
      {
        fieldName: 'rollBusinessId',
        width:200,
        title: this.gridLabels.get('columns.rollId.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.multiValueGetter(params, "rollBusinessId", ["rollBusinessIdError"], true, "materialName");
          },
          sortable: false,
        }
      },
      {
        fieldName: 'kitTypeBusinessId',
        title: this.gridLabels.get('columns.kitType.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.multiValueGetter(params, "kitTypeBusinessId", ["kitTypeBusinessIdError"], true);
          },
          sortable: false,
        }
      },
     {
        fieldName: 'kitBusinessId',
        title: this.gridLabels.get('columns.kitId.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.multiValueGetter(params, "kitBusinessId", ["lengthCutStringError", "tagKeyError"], false);
          },
          sortable: false,
        }
      },
      {
        fieldName: 'woBusinessId',
        title: this.gridLabels.get('columns.wo.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.multiValueGetter(params, "woBusinessId", ["woBusinessIdError"], true);
          },
          sortable: false,
        }
      },
      {
        fieldName: 'quantityPlies',
        title: this.gridLabels.get('columns.expectedplies.title'),
        filterType: filterTypes.NONE,
        width:120,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.multiValueGetter(params, "quantityPlies");
          },
          sortable: false,
        }
      },
      {
        fieldName: 'quantityKittedPlies',
        title: this.gridLabels.get('columns.actualplies.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.actualPliesValueGetter(params);
          },
          editable: (params) => {
            return !params.data.isRowDisabled && !(params.data.cutsAndKitsItems?.every(item => item.isKitTypeHasPlies) ?? false)
          },
          cellEditor: 'editableMultiCell',
          cellEditorParams: {
            inputType: 'numeric',
            maxLength: 9999,
            isMulti:true,
            isInt: true,
            shouldStopEditingOnBlur: false
          },
          onCellValueChanged: function (params) {
            params.newValue.dataList[0].onRowsChanged()
            params.data.cutsAndKitsItems.map((item, index) => {
              item.quantityKittedPlies = params.newValue.dataList[index].value;
            })
          },
          sortable: false,
        }
      },
    ];

    return columnsConfig;
  };

  multiValueGetter = (entity, field, errorFields = List(), showOnlyError = false, additionalField = null) => {
    return {
      dataList: entity.data.cutsAndKitsItems.map((item)=> {
        let errorMessage = "";
        let errorCount = 0;
        errorFields.map((errorField) => {
          if (item[errorField]) {
            if (errorCount > 0) {
              errorMessage = errorMessage.concat(", ")
            }

            errorMessage = errorMessage.concat(item[errorField]);
            errorCount++;
          }
        });

        return {
          value: item[field],
          additionalField: additionalField ? item[additionalField] : null,
          errorMessage,
          showOnlyError,
        }
      }),
      componentType: MultiRowCell
    };
  }

  actualPliesValueGetter = (entity) => {
    return {
      dataList: entity.data.quantityKittedPlies.map((item, index) => {
        const isKitTypeHasPlies = entity.data.cutsAndKitsItems[index]?.isKitTypeHasPlies ?? false;
        return {
          enabled:  !entity.data.isRowDisabled && !isKitTypeHasPlies,
          value: item,
          isMulti:true,
          onRowsChanged: this.props.actions.onRowsChanged}
      }), componentType: editableViewCell
    };
  }

  goOn = () => {
    var selectedNests = this.props.nestsItems.filter((nest) => {
      let isSelected = this.props.selectedNests.findIndex((selectedNest) => selectedNest.nestBusinessId === nest.nestBusinessId) != -1;
      nest.selected = isSelected;
      return isSelected;
    });

    this.props.actions.validateFile(this.props.sSelectedFile ,this.props.rollIds ,selectedNests)
  };

  goBack = () => {
    let wizardActions = this.props.wizardActions;
    wizardActions.previous();
  };

  onHide = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  getDialogButtons() {
    let buttonOK = {
      id:'submit',
      text: this.labels.get('footer.selectnestsok'),
      bsStyle: 'primary',
      disabled: (this.props.sSelectedFile && !this.props.sNestsErrors && this.props.selectedNests.size > 0 && !this.props.selectedNests.find((nest) => nest.hasErrorProperties)) ? this.props.loading : true,
      loading: this.props.loading,
      action: this.props.handleSubmit(this.goOn)
    };

    let buttonCancel = {
      id:'cancel',
      text: this.labels.get('footer.back'),
      action: this.goBack
    };

    return { left: [buttonCancel], right: [buttonOK] };
  }

  renderErrors = () => {
    if(this.props.sNestsErrors){
       return this.renderErrorSelection(this.props.sNestsErrors);
    }
    return null;
  }

  renderErrorSelection = (data) => {
     let icon = 'pl pl-warning-icon';
     let errorType = data.statusType;
      return (
        <div className="error-selection">
          <div className='error-item'>
              <span className={`error-icon ${icon} ${errorType}`}></span>
              <label className='error-msg'>{data.message}</label>
          </div>
        </div>
      );
  };

  getRowHeight = (params) => {
    let rowHeight = 60 + 34;
    let rowCount = params.data.cutsAndKitsItems ? params.data.cutsAndKitsItems.length : 0;
    if (rowCount > 1) {
      rowHeight = (rowCount * 60) + 34;
    }

    return rowHeight;
  };

  renderSelectedItemsCounter = () => {
    let selectedItemsNum = this.props.selectedNests ? this.props.selectedNests.size : 0;
    let count = this.props.nestsItems ? this.props.nestsItems.size : 0;
    return (
      <div className="select-nests-counter">{this.labels.get('title.counter', '', {selectedItemsNum, count})}</div>
    )
  };

  render() {
    let animation = this.props.isFirstLaunch;
    let plan = this.props.plan ? this.props.plan : '';
    return (
      <Dialog
        id="select-nests-dialog"
        className="select-nests-dialog"
        animation={animation}
        show={true}
        onHide={this.onHide}
        titleText= {this.labels.get('selectnests.title')}
        moreInfoTextHeader={`${this.labels.get('filename.title')}: ${this.props.sSelectedFile ? this.props.sSelectedFile.name : ''}`}
        footerButtons={this.getDialogButtons()}
        handleHashChange={false}
      >

        <div>
          <Label text={`${this.labels.get('plan.title')}: ${plan ? plan : ''}`} tooltip={plan ? plan : ''}/>
        </div>
        {this.renderSelectedItemsCounter()}

        <div className="select-nests-grid multi-rolls">
          <Grid gridName={gridsNames.IMPORT_NESTS_MULTI_ROLLS_SELECT_NESTS}
                columnsConfig={this.columnsConfig}
                rows={this.props.nestsItems}
                isStatic = {true}
                gridProps={
                  {
                    getRowHeight: this.getRowHeight,
                    sideBar: false,
                  }
                }
          >
          </Grid>
        </div>

        {this.renderErrors()}

      </Dialog>
    );
  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false
  }
)(SelectNestsStep);

SelectNestsStep.propTypes = {
};


